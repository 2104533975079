import { styled } from '@mui/material'
import type { ReactElement } from 'react'

import { theme } from '@/styles'
import { getColorWithAlpha } from '@local/components'

type Props = {
  Icon: ReactElement
  className?: string
  menuOpen?: boolean
  testid?: string
  active?: boolean
}

const StyledButton = styled('div', {
  name: 'StyledButton',
  shouldForwardProp: prop => prop !== 'active' && prop !== 'menuOpen',
})<{
  menuOpen?: boolean
  active?: boolean
}>(({ menuOpen, active }) => ({
  border: 0,
  shrink: 0,
  height: 36,
  display: 'flex',
  padding: '0 8px',
  borderRadius: 4,
  position: 'relative',
  alignItems: 'center',
  color: theme.colors.$pureWhite,
  transition: 'background 0.3s ease',
  backgroundColor: getColorWithAlpha('$pureWhite', menuOpen ? 0.2 : 0),

  '&:hover': {
    backgroundColor: getColorWithAlpha('$pureWhite', 0.2),
  },

  '&:after': {
    left: 0,
    bottom: -9,
    content: '" "',
    width: '100%',
    height: '5px',
    display: 'block',
    position: 'absolute',
    transformOrigin: 'left center',
    transition: 'transform 0.3s ease',
    backgroundColor: theme.colors.$pureWhite,
    transform: active ? 'scale(1, 1)' : 'scale(0, 1)',
  },
}))

export function MenuIconTrigger(props: Props) {
  const { className, Icon, testid, menuOpen, active = false } = props

  return (
    <StyledButton
      active={active}
      menuOpen={menuOpen}
      className={className}
      data-testid={testid}
      data-trackid={testid}
    >
      {Icon}
    </StyledButton>
  )
}
