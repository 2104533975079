import { type SchedulerProFeaturesConfigType } from '@bryntum/schedulerpro'

import { tooltipTemplate } from './tooltipTemplate'
import { validatorFn } from './validatorFn'

export const eventDrag: SchedulerProFeaturesConfigType['eventDrag'] = {
  tooltipTemplate,
  showTooltip: true,
  validatorFn,
}
