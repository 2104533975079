import { formatNumber } from '@/hooks'

import { SingleColumnContainer } from '../../../Scheduler/components/SingleColumnContainer'
import { Typography } from '../../../Scheduler/components/Typography'
import { LinearProgress } from '../../../Scheduler/components/LinearProgress'
import { getPercentageColor } from '../../../../styles/colors'

interface Props {
  language: uui.domain.Language
  value?: uui.domain.client.rm.SchedulerResource['loads'][string]['current']
  showAbsoluteValues: boolean
}

export function DynamicLoadCellContent(props: Props) {
  const { value, language, showAbsoluteValues } = props

  if (!value) {
    if (showAbsoluteValues) {
      return (
        <SingleColumnContainer>
          <Typography fontSize={12}>-</Typography>
        </SingleColumnContainer>
      )
    } else {
      return (
        <SingleColumnContainer>
          <LinearProgress color={getPercentageColor(0)} value={0} />
          <Typography fontSize={12}>-</Typography>
        </SingleColumnContainer>
      )
    }
  }

  if (showAbsoluteValues && value.absolute === 0) {
    return (
      <SingleColumnContainer>
        <Typography fontSize={12}>-</Typography>
      </SingleColumnContainer>
    )
  }

  if (!showAbsoluteValues && isNaN(value.percentage)) {
    return (
      <SingleColumnContainer>
        <LinearProgress color={getPercentageColor(9999)} value={100} />
        <Typography fontSize={12}>
          {!value.percentage || value.percentage <= 0
            ? '-'
            : `${formatNumber(Math.round(value.percentage * 100) / 100, language)}%`}
        </Typography>
      </SingleColumnContainer>
    )
  }

  const normalizedValue =
    value.percentage > 100
      ? 100
      : value.percentage <= 0 || !value.percentage
        ? 0
        : (value.percentage * 100) / 100

  const formattedValue = formatNumber(Math.round(value.percentage * 100) / 100, language)

  return (
    <SingleColumnContainer>
      {showAbsoluteValues ? (
        <Typography fontSize={12}>
          {value.absolute && value.absolute > 0 ? formatNumber(value.absolute, language) : '-'}
        </Typography>
      ) : (
        <>
          <LinearProgress color={getPercentageColor(value.percentage)} value={normalizedValue} />
          <Typography fontSize={12}>
            {!value.percentage || value.percentage <= 0 ? '-' : `${formattedValue}%`}
          </Typography>
        </>
      )}
    </SingleColumnContainer>
  )
}
