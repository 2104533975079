import { useSelector } from 'react-redux'

import { Stack, Typography } from '@mui/material'
import { HistoryToggleOff, AccessTime, ArrowRightAlt } from '@mui/icons-material'

import { selectUserAccountPreferences, selectUserConfiguration } from '@/features/domain/user'
import { selectTerritory } from '@/features/domain/territory'
import { timeUtils } from '@/server-data'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'

import { useTexts } from './useTexts'

interface Props {
  plannedEndTime: number
  trackedEndTime?: number
  plannedStartTime: number
  trackedStartTime?: number
  endsAfterWorkingDayEnd: boolean
  startsBeforeWorkingDayStart: boolean
}

export function RouteDetailsSchedule(props: Props) {
  const {
    plannedEndTime,
    trackedEndTime,
    plannedStartTime,
    trackedStartTime,
    endsAfterWorkingDayEnd,
    startsBeforeWorkingDayStart,
  } = props
  const { mobileAppsTrackingType } = useSelector(selectTerritory)
  const { userType } = useSelector(selectUserConfiguration)
  const accountPreferences = useSelector(selectUserAccountPreferences)
  const texts = useTexts()

  const plannedStartTimeAsString = timeUtils.formatSecondsFromMidnight(
    plannedStartTime,
    accountPreferences.timeFormat,
  )
  const plannedEndTimeAsString = timeUtils.formatSecondsFromMidnight(
    plannedEndTime,
    accountPreferences.timeFormat,
  )

  const trackedStartTimeAsString =
    !!trackedStartTime && trackedStartTime !== -1
      ? timeUtils.formatSecondsFromMidnight(trackedStartTime, accountPreferences.timeFormat)
      : '-'

  const trackedEndTimeAsString =
    !!trackedEndTime && trackedEndTime !== -1
      ? timeUtils.formatSecondsFromMidnight(trackedEndTime, accountPreferences.timeFormat)
      : '-'

  const trackedStartRouteColor = startsBeforeWorkingDayStart
    ? theme.colors.$outrageousRed
    : undefined

  const trackedEndRouteColor = endsAfterWorkingDayEnd ? theme.colors.$outrageousRed : undefined
  const trackedTimeTooltip = trackedStartTime
    ? texts.trackedTimeTooltip
    : userType === 'rmOnly'
      ? texts.trackedTimeTooltipRmOnly
      : mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
        ? texts.trackedTimeTooltipWontBeCollected
        : texts.trackedTimeTooltipNotYetCollected

  return (
    <Stack spacing={1} alignItems="flex-start">
      <Tooltip placement="top" title={texts.plannedTimeTooltip}>
        <Stack direction="row" spacing={1} alignItems="center" data-testid="routePlannedTime">
          <HistoryToggleOff fontSize="small" />
          <Typography fontSize={13} data-testid="routePlannedStartTime">
            {plannedStartTimeAsString}
          </Typography>
          <ArrowRightAlt fontSize="small" />
          <Typography fontSize={13} data-testid="routePlannedEndTime">
            {plannedEndTimeAsString}
          </Typography>
        </Stack>
      </Tooltip>

      <Tooltip title={trackedTimeTooltip} placement="bottom">
        <Stack direction="row" spacing={1} alignItems="flex-start" data-testid="routeTrackedTime">
          <AccessTime fontSize="small" color={trackedStartTime ? 'inherit' : 'disabled'} />
          {trackedStartTime ? (
            <>
              <Typography
                color={trackedStartRouteColor}
                fontSize={13}
                data-testid="routeTrackedStartTime"
              >
                {trackedStartTimeAsString}
              </Typography>
              <ArrowRightAlt fontSize="small" />
              <Typography
                color={trackedEndRouteColor}
                fontSize={13}
                data-testid="routeTrackedEndTime"
              >
                {trackedEndTimeAsString}
              </Typography>
            </>
          ) : (
            <Typography fontSize={13} color="grey.600">
              -
            </Typography>
          )}
        </Stack>
      </Tooltip>
    </Stack>
  )
}
