import { type EventModel, type ResourceModel, type SchedulerPro } from '@bryntum/schedulerpro'
import { addHours, max, min } from 'date-fns/esm'

import { getMainSelection, subscribeMainSelection } from '@/atoms'
import { selectOrderSteps } from '@/features/domain/order'
import { selectOrderStepIdSchedulerMapping } from '@/features/domain/scheduler'
import { store } from '@/store'

export function setInitialSelection(scheduler: SchedulerPro) {
  const state = store.getState()
  const orderSteps = selectOrderSteps(state)
  const mainSelection = getMainSelection()
  const routeSelection = mainSelection.routes
  const orderStepSelection = mainSelection.orderSteps
  const orderStepIdSchedulerMapping = selectOrderStepIdSchedulerMapping(state)

  // Compute initial orderStep selection
  const initialOrderStepSelection = [
    ...orderStepSelection.reduce<Set<EventModel>>((acc, id) => {
      const orderStep = orderSteps[id]

      if (orderStep && !orderStep.isUnassigned) {
        const item = scheduler.eventStore.getById(orderStepIdSchedulerMapping[id])
        if (item) acc.add(item as EventModel)
      }

      // If an approved version exists, add it to the selection
      const approvedEvent = scheduler.eventStore.getById(
        orderStepIdSchedulerMapping[`${id}-approved`],
      )

      if (approvedEvent) {
        acc.add(approvedEvent as EventModel)
      }

      return acc
    }, new Set<EventModel>()),
  ]

  // Compute initial route selection
  const initialRoutesSelection = routeSelection.reduce<ResourceModel[]>((acc, id) => {
    const item = scheduler.resourceStore.getById(id)
    if (item) acc.push(item as ResourceModel)
    return acc
  }, [])

  // Set the initial selection
  scheduler.selectEvents(initialOrderStepSelection)
  scheduler.selectRows(initialRoutesSelection)
}

export function startSelectionSync(scheduler: SchedulerPro) {
  // Set the initial selection
  setInitialSelection(scheduler)

  const unsubscribeOrderStepSelection = subscribeMainSelection('orderSteps', orderStepSelection => {
    const state = store.getState()
    const orderStepIdSchedulerMapping = selectOrderStepIdSchedulerMapping(state)

    const sets = orderStepSelection.reduce<{
      selection: Set<EventModel>
      selectionWithApproved: Set<EventModel>
    }>(
      (acc, id) => {
        const item = scheduler.eventStore.getById(orderStepIdSchedulerMapping[id])

        if (item) {
          acc.selection.add(item as EventModel)
          acc.selectionWithApproved.add(item as EventModel)
        }

        // If an approved version exists, add it to the selection
        const approvedEvent = scheduler.eventStore.getById(
          orderStepIdSchedulerMapping[`${id}-approved`],
        )
        if (approvedEvent) acc.selectionWithApproved.add(approvedEvent as EventModel)

        return acc
      },
      {
        selection: new Set<EventModel>(),
        selectionWithApproved: new Set<EventModel>(),
      },
    )

    scheduler.selectEvents(Array.from(sets.selectionWithApproved))

    fitSchedulerOnSelection(scheduler, Array.from(sets.selection))
    scheduler.refreshRows()
  })

  const unsubscribeRouteSelection = subscribeMainSelection('routes', routeSelection => {
    if (routeSelection.length === 0) {
      scheduler.deselectRows(scheduler.selectedRows)
      return
    }

    const newSelection = routeSelection.reduce<ResourceModel[]>((acc, id) => {
      const item = scheduler.resourceStore.getById(id)
      if (item) acc.push(item as ResourceModel)
      return acc
    }, [])

    scheduler.selectRows(newSelection)
    scheduler.refreshRows()
  })

  return () => {
    unsubscribeRouteSelection()
    unsubscribeOrderStepSelection()
  }
}

function fitSchedulerOnSelection(scheduler: SchedulerPro, newSelection: EventModel[]) {
  if (newSelection.length === 0) return

  // Single event selection
  if (newSelection.length === 1) {
    scheduler.scrollEventIntoView(newSelection[0])
    return
  }

  // Multiple event selection
  const routeIds: string[] = []
  const startDates: Date[] = []
  const endDates: Date[] = []

  for (const event of newSelection) {
    // If the selected events belong to different routes, just return
    if (routeIds.length === 1 && routeIds[0] !== event.resourceId) return
    if (routeIds.length === 0) {
      routeIds.push(event.resourceId as string)
    }

    // this has to be done since the execution events can happen anytime during the day
    // so order is not preserved
    startDates.push(event.startDate as Date)
    endDates.push(event.endDate as Date)
  }

  // Compute the new date range
  const minStartDate = min(startDates)
  const maxEndDate = max(endDates)

  // Calculate if we need to add or remove some padding to the range
  const newStartDate =
    minStartDate <= addHours(scheduler.startDate, 1) ? minStartDate : addHours(minStartDate, -1)

  const newEndDate =
    maxEndDate >= addHours(scheduler.endDate, -1) ? maxEndDate : addHours(maxEndDate, 1)

  // Scroll to the first resource
  const resource = scheduler.resourceStore.getById(routeIds[0]) as ResourceModel

  if (!resource) return
  scheduler.scrollResourceIntoView(resource)

  // If the new date range is already visible, don't zoom
  if (
    (scheduler.visibleDateRange.startDate as Date) < newStartDate &&
    (scheduler.visibleDateRange.endDate as Date) > newEndDate
  )
    return

  // Zoom to the new date range
  scheduler.zoomTo({
    startDate: newStartDate,
    endDate: newEndDate,
  })
}
