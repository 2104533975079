import type { Coordinate } from 'ol/coordinate'
import type VectorSource from 'ol/source/Vector'
import type { SegmentType } from './utils/color'

import Geometry from 'ol/geom/Geometry'
import Draw, { DrawEvent } from 'ol/interaction/Draw'

import LineString from 'ol/geom/LineString'
import { ObjectEvent } from 'ol/Object'
import Feature from 'ol/Feature'

import { gis } from '@/server-data'

import { computePositions, computePosition } from './utils/computePositions'
import { getFeatureCoordinates } from './utils/getFeatureCoordinates'
import { drawFeatureMetadata } from './utils/drawFeatureMetadata'
import { createStyle } from './Style/createStyle'

import { MAX_LENGTH_IN_METERS } from './constants'

type GeometryStatus =
  // The roadSegment is without any point
  | 'empty'
  // The roadSegment is valid and can be saved/closed
  | 'valid'
  // The roadSegment is invalid and cannot be saved/closed
  | 'invalid'

// roadSegment Created unsubscribe function
type Unsubscriber = () => void

// roadSegment Created subscribe function
type OnRoadSegmentCreateSubscriber = (
  editableSegment: { start: uui.domain.LatLng; end: uui.domain.LatLng },
  feature: Feature<Geometry>,
) => void

interface Options {
  type: SegmentType
  source: VectorSource
}

/**
 * TODO: Main description
 */
export class DrawEditableRoadSegmentInteraction extends Draw {
  constructor({ type, source }: Options) {
    super({
      // support only LineString
      type: 'LineString',
      style: createStyle(type),
      freehand: false,
      maxPoints: 2,
      minPoints: 2,
      source,
    })

    // the parent interaction doesn't expose a pointer to the linked Vector Source
    this.source = source

    // set the drawing layer main color
    this.updateColor(type)

    // listen to change toi the Active state
    this.on('change:active', this.handleChangeActiveState)
  }

  // ---------------------------------------------------------------
  // ADDITIONAL API
  // ---------------------------------------------------------------

  /**
   * Change the color of the roadSegment.
   *
   * @param color Hexadecimal or RGB string of the color the Shape must have.
   */
  public updateColor(type: SegmentType) {
    this.getOverlay().setStyle(createStyle(type))
  }

  /**
   * Subscribe to the creation of an roadSegment.
   *
   * @param subscriber The function that will be invoked when an roadSegment is created
   * @returns The function to unsubscribe
   */
  public onRoadSegmentCreate(subscriber: OnRoadSegmentCreateSubscriber): Unsubscriber {
    this.onRoadSegmentCreateSubscribers.add(subscriber)

    return () => {
      this.onRoadSegmentCreateSubscribers.delete(subscriber)
    }
  }

  // ---------------------------------------------------------------
  // Internal Properties
  // ---------------------------------------------------------------

  protected source: VectorSource

  /**
   * Reference to the Feature actively used by the Draw Interaction
   */
  protected activeFeature: Feature<Geometry> | undefined

  /**
   * Array of Open Layers Coordinates for the active Feature.
   * This is a convenience helper to avoid accessing the coordinates through the Feature instance API.
   * @see getFeatureCoordinates
   */
  protected activeCoords: Coordinate[] = []

  /**
   * Array of LatLng points matching the active Feature coordinates.
   * @see activeCoords
   */
  protected activeGeometryPoints: uui.domain.Point[] = []

  /**
   * Validation status for the Geometry being drawn
   */
  protected activeGeometryStatus: GeometryStatus = 'empty'

  /**
   * Collection of subscribers for the `roadSegment Create` Event.
   */
  protected onRoadSegmentCreateSubscribers = new Set<OnRoadSegmentCreateSubscriber>()

  // ---------------------------------------------------------------
  // INTERNAL EVENT HANDLERS
  // ---------------------------------------------------------------

  /**
   * Event handler reacting to the `active` status of the Interaction.
   */
  protected handleChangeActiveState = () => {
    if (this.getActive()) {
      this.on('drawstart', this.handleDrawStart)
      this.on('drawend', this.handleDrawEnd)
      this.on('drawabort', this.handleDrawAbort)
    } else {
      this.un('drawstart', this.handleDrawStart)
      this.un('drawend', this.handleDrawEnd)
      this.un('drawabort', this.handleDrawAbort)
    }

    this.updateCursor()
  }

  /**
   * Event handler reacting to the creation of a new valid roadSegment.
   * It's responsible to prepare the created Geometry and its target Feature before notifying
   * the subscribers to the creation of a new roadSegment.
   */
  protected handleOnRoadSegmentCreate(event: DrawEvent, lonLats: Coordinate[]) {
    // convert Open Layers coordinates into RM LatLng
    const segment = {
      start: gis.fromCoordinateToLatLng(lonLats[0]),
      end: gis.fromCoordinateToLatLng(lonLats[1]),
    }

    const feature = event.feature
    // Add a custom field to the Feature to easily identify its origin.
    drawFeatureMetadata.setAsEditableRoadSegment(feature)
    // remove the `valid` custom property from the Drawn Feature.
    drawFeatureMetadata.removeValid(feature)

    // ATTENTION: the event is delayed to allow OpenLayers to move the feature to the target layer.
    this.source.once('addfeature', () => {
      // notify subscribers the feature is drawn and ready
      this.onRoadSegmentCreateSubscribers.forEach(sub => sub(segment, feature))
    })
  }

  /**
   * Event handler reacting to the start of a drawing session.
   */
  protected handleDrawStart = (event: DrawEvent) => {
    this.activeGeometryStatus = 'empty'
    this.updateCursor()

    // add a custom property to the Drawn Feature to track it Geometry valid status
    this.activeFeature = event.feature
    drawFeatureMetadata.setValid(this.activeFeature, true)

    // prepare the Interaction for the upcoming creation of an roadSegment.
    this.initGeometry(event.feature)

    // listen for changes in the Geometry (points added or removed)
    this.activeFeature?.on('change', this.handleOnChangeGeometry)
  }

  /**
   * Event handler reacting to the end of a drawing session.
   */
  protected handleDrawEnd = (event: DrawEvent) => {
    const { feature } = event
    const geometry = feature.getGeometry()

    if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
      const line = geometry as LineString
      const length = line.getLength()

      if (length > MAX_LENGTH_IN_METERS) {
        this.activeCoords = [
          line.getCoordinateAt(0),
          line.getCoordinateAt(MAX_LENGTH_IN_METERS / length),
        ]

        line.setCoordinates(this.activeCoords)
      }

      this.handleOnRoadSegmentCreate(event, this.activeCoords)
      this.resetInteraction()
    }
  }

  /**
   * Event handler reacting to an aborted drawing session.
   */
  protected handleDrawAbort = (_event: DrawEvent) => {
    this.resetInteraction()
  }

  /**
   * Event handler reacting to any change in the Geometry being drawn.
   */
  protected handleOnChangeGeometry = (event: ObjectEvent) => {
    // update the parsed information linked to the Geometry
    this.refreshGeometry(event.target as Feature<Geometry>)

    // validate the Geometry
    this.activeGeometryStatus = this.validateGeometry(this.activeGeometryPoints)
    this.updateCursor()

    // store the validity as a custom field of the Feature being drawn.
    drawFeatureMetadata.setValid(this.activeFeature, this.activeGeometryStatus !== 'invalid')
  }

  // ---------------------------------------------------------------
  // GEOMETRY MAINTENANCE
  // ---------------------------------------------------------------

  /**
   * Reset the internal state of the interaction
   */
  protected resetInteraction = () => {
    this.activeGeometryPoints = []
    this.activeCoords = []
    this.activeFeature = undefined
    this.activeGeometryStatus = 'empty'

    this.updateCursor()
  }

  /**
   * Accepts a Feature and parse its Geometry to prepare the Interaction for the incoming drawing.
   *
   * @param feature The Feature being drawn.
   */
  protected initGeometry = (feature: Feature<Geometry>) => {
    const coordinates = getFeatureCoordinates(feature)
    const map = this.getMap()

    if (!map) {
      throw new Error('Cannot find a map instance')
    }

    const geometry = feature.getGeometry()
    if (geometry) {
      map.getView().fit(geometry.getExtent(), { padding: [80, 80, 80, 80], maxZoom: 17 })
    }

    // Since the Interaction is limited to draw a `LineString` but the parent Interaction support many
    // Geometry types an helper function is used to hide the boilerplate required to extract the
    // LineString's coordinates.

    if (coordinates.length === 0) return
    this.activeCoords = [coordinates[0]]

    // transform Open Layers coordinates into RM LatLng points
    this.activeGeometryPoints = computePositions(map, this.activeCoords)
    // validate the initial Geometry
    this.activeGeometryStatus = this.validateGeometry(this.activeGeometryPoints)
    this.updateCursor()
  }

  /**
   * Accepts a Feature and parse its Geometry to update the current drawing and its meta-information.
   *
   * @param feature The Feature being drawn.
   */
  protected refreshGeometry = (feature: Feature<Geometry>) => {
    // access the Feature's coordinates
    const coords = getFeatureCoordinates(feature)

    // if no points exist in the drawn Feature or in the stored meta-information stop here
    if (coords.length === 0 && this.activeCoords.length === 0) return

    const map = this.getMap()
    if (!map) return

    // When the Geometry updates three situations are possible:
    // - a point has been moved
    // - a point has been added
    // - a point has been removed
    const geometryAction =
      coords.length === this.activeCoords.length
        ? 'move'
        : coords.length > this.activeCoords.length
          ? 'add'
          : coords.length < this.activeCoords.length
            ? 'remove'
            : 'none'

    // ATTENTION: The point being moved or added is always the one before the last in the list of coordinates
    const sketchIndex = -2

    switch (geometryAction) {
      case 'move':
        const sketchPoint = coords.at(sketchIndex)

        if (sketchPoint) {
          // parse and update the stored RM point for the Sketch Open Layers coordinate
          this.activeGeometryPoints[this.activeGeometryPoints.length - 1] = computePosition(
            map,
            sketchPoint,
          )
        }
        break

      case 'add':
        const pointAdded = coords.at(sketchIndex)

        if (pointAdded) {
          // parse and add the stored RM point for the Sketch Open Layers coordinate
          if (this.activeGeometryPoints.length < 2) {
            this.activeGeometryPoints.splice(-1, 0, computePosition(map, pointAdded))
          } else {
            this.activeGeometryPoints[1] = computePosition(map, pointAdded)
          }
        }
        break

      case 'remove':
        // remove the stored RM point for the Sketch Open Layers coordinate
        this.activeGeometryPoints.splice(sketchIndex, 1)
        break
    }

    // store the new Geometry coordinates
    this.activeCoords = coords
  }

  /**
   * Validate the provided points.
   *
   * @param points An array of RM LatLng points
   * @returns The validity of the points.
   */
  protected validateGeometry = (points: uui.domain.Point[]): GeometryStatus => {
    // A Geometry with less than 2 points must be considered `empty`
    if (points.length !== 2) {
      return 'empty'
    }

    // A Geometry with 2 points must be considered `valid`. Is not possible to have an invalid triangle.
    return 'valid'
  }

  // ---------------------------------------------------------------
  // Active Map Cursor
  // ---------------------------------------------------------------

  /**
   * Add and remove classes to Open Layers Canvas HTML Element to render the correct mouse cursor.
   */
  protected updateCursor() {
    const view = this.getMap()?.getViewport()
    if (!view) return

    // always reset
    view.classList.remove('ol-pointer-draw-valid')

    if (!this.getActive()) return

    switch (this.activeGeometryStatus) {
      case 'empty':
      case 'valid':
        view.classList.add('ol-pointer-draw-valid')
        break
    }
  }

  // ---------------------------------------------------------------
  // OVERRIDDEN API
  // ---------------------------------------------------------------
  /**
   * Clear all the internal subscription and pointers when the Interaction is disposed.
   */
  override dispose() {
    const view = this.getMap()?.getViewport()
    view?.classList.remove('ol-pointer-draw-valid')

    this.un('change:active', this.handleChangeActiveState)
    this.un('drawstart', this.handleDrawStart)
    this.un('drawend', this.handleDrawEnd)
    this.un('drawabort', this.handleDrawAbort)

    this.onRoadSegmentCreateSubscribers.clear()

    this.resetInteraction()

    super.dispose()
  }
}
