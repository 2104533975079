import { type CSSProperties } from 'react'
import { type ResourceModel } from '@bryntum/schedulerpro'

import { getRouteLoadingState } from '../../../atoms/routeLoadingState'

import { SingleColumnContainer } from '../../Scheduler/components/SingleColumnContainer'
import { UnstyledButton } from '../../Scheduler/components/UnstyledButton'
import { Spinner } from '../../Scheduler/components/Spinner'

import { VisibilityTwoTone } from '../icons/VisibilityTwoTone'
import { VisibilityOff } from '../icons/VisibilityOff'
import { Visibility } from '../icons/Visibility'

interface Props {
  record: ResourceModel
  field: string
  value: uui.domain.client.rm.SchedulerResource['routeVisible']
}

const iconStyle = {
  '--font-size': '14px',
} as CSSProperties

const buttonContainerStyle = {
  marginTop: 4,
  marginLeft: 1,
}

export function VisibilityCell(props: Props) {
  const { value, record } = props
  const id = record.getData('id') as uui.domain.client.rm.SchedulerResource['id']

  const { visibility } = getRouteLoadingState()

  const showLoader = visibility[id] === true || visibility['ALL'] === true
  const disabled = value === 'empty'

  const Icon = disabled
    ? Visibility
    : value === 'on'
      ? Visibility
      : value === 'off'
        ? VisibilityOff
        : value === 'mixed'
          ? VisibilityTwoTone
          : value === 'empty'
            ? Visibility
            : null

  return (
    <SingleColumnContainer>
      {showLoader ? (
        <Spinner size={14} />
      ) : (
        <div style={buttonContainerStyle}>
          <UnstyledButton disabled={disabled}>
            {Icon && <Icon className="o-scheduler-svg-icon--color-black" style={iconStyle} />}
          </UnstyledButton>
        </div>
      )}
    </SingleColumnContainer>
  )
}
