import { type SchedulerProConfig, type EventModel } from '@bryntum/schedulerpro'

export const onEventPartialResize: SchedulerProConfig['onEventPartialResize'] = params => {
  const { eventRecord, element, source } = params

  const id = (eventRecord.id as string).replace('-handles', '')
  const originalEvent = source.eventStore.getById(id) as EventModel

  if (!originalEvent) return

  //originalEvent.setStartEndDate(eventRecord.startDate as Date, eventRecord.endDate as Date)
  const originalElement = document.querySelector(
    // @ts-expect-error
    `[data-event-id="${eventRecord.domId.replace('-handles', '')}"]`,
  )

  if (!originalElement) return
  // @ts-expect-error
  originalElement.style.insetInlineStart = element.style.insetInlineStart
  // @ts-expect-error
  originalElement.style.width = element.style.width
}
