import { Scrollbar } from '@workwave-tidal/tidal/components'

import { VerticalLayout } from '@/components/layout'

import { Entry20210121 } from './entries/Entry20210121'
import { Entry20210129 } from './entries/Entry20210129'
import { Entry20210205 } from './entries/Entry20210205'
import { Entry20210212 } from './entries/Entry20210212'
import { Entry20210222 } from './entries/Entry20210222'
import { Entry20210226 } from './entries/Entry20210226'
import { Entry20210305 } from './entries/Entry20210305'
import { Entry20210309 } from './entries/Entry20210309'
import { Entry20210312 } from './entries/Entry20210312'
import { Entry20210319 } from './entries/Entry20210319'
import { Entry20210326 } from './entries/Entry20210326'
import { Entry20210330 } from './entries/Entry20210330'
import { Entry20210401 } from './entries/Entry20210401'
import { Entry20210409 } from './entries/Entry20210409'
import { Entry20210420 } from './entries/Entry20210420'
import { Entry20210422 } from './entries/Entry20210422'
import { Entry20210505 } from './entries/Entry20210505'
import { Entry20210531 } from './entries/Entry20210531'
import { Entry20210616 } from './entries/Entry20210616'
import { Entry20210922 } from './entries/Entry20210922'
import { Entry20210930 } from './entries/Entry20210930'
import { Entry20211012 } from './entries/Entry20211012'
import { Entry20211110 } from './entries/Entry20211110'
import { Entry20211216 } from './entries/Entry20211216'
import { Entry20220112 } from './entries/Entry20220112'
import { Entry20220127 } from './entries/Entry20220127'
import { Entry20220128 } from './entries/Entry20220128'
import { Entry20220209 } from './entries/Entry20220209'
import { Entry20220223 } from './entries/Entry20220223'
import { Entry20220311 } from './entries/Entry20220311'
import { Entry20220331 } from './entries/Entry20220331'
import { Entry20220414 } from './entries/Entry20220414'
import { Entry20220415 } from './entries/Entry20220415'
import { Entry20220427 } from './entries/Entry20220427'
import { Entry20220428 } from './entries/Entry20220428'
import { Entry20220502 } from './entries/Entry20220502'
import { Entry20220502_2 } from './entries/Entry20220502_2'
import { Entry20220503 } from './entries/Entry20220503'
import { Entry20220505 } from './entries/Entry20220505'
import { Entry20220510 } from './entries/Entry20220510'
import { Entry20220517 } from './entries/Entry20220517'
import { Entry20220518 } from './entries/Entry20220518'
import { Entry20220519 } from './entries/Entry20220519'
import { Entry20220524 } from './entries/Entry20220524'
import { Entry20220608 } from './entries/Entry20220608'
import { Entry20220622 } from './entries/Entry20220622'
import { Entry20220629 } from './entries/Entry20220629'
import { Entry20220712 } from './entries/Entry20220712'
import { Entry20220714 } from './entries/Entry20220714'
import { Entry20220727 } from './entries/Entry20220727'
import { Entry20220728 } from './entries/Entry20220728'
import { Entry20220802 } from './entries/Entry20220802'
import { Entry20220804 } from './entries/Entry20220804'
import { Entry20220824 } from './entries/Entry20220824'
import { Entry20220826 } from './entries/Entry20220826'
import { Entry20220830 } from './entries/Entry20220830'
import { Entry20220901 } from './entries/Entry20220901'
import { Entry20220914 } from './entries/Entry20220914'
import { Entry20221004 } from './entries/Entry20221004'
import { Entry20221004_2 } from './entries/Entry20221004_2'
import { Entry20221012 } from './entries/Entry20221012'
import { Entry20221026 } from './entries/Entry20221026'
import { Entry20221109 } from './entries/Entry20221109'
import { Entry20221130 } from './entries/Entry20221130'
import { Entry20221202 } from './entries/Entry20221202'
import { Entry20221214 } from './entries/Entry20221214'
import { Entry20221222 } from './entries/Entry20221222'
import { Entry20230125 } from './entries/Entry20230125'
import { Entry20230126 } from './entries/Entry20230126'
import { Entry20230215 } from './entries/Entry20230215'
import { Entry20230219 } from './entries/Entry20230219'
import { Entry20230221 } from './entries/Entry20230221'
import { Entry20230302 } from './entries/Entry20230302'
import { Entry20230322 } from './entries/Entry20230322'
import { Entry20230405 } from './entries/Entry20230405'
import { Entry20230411 } from './entries/Entry20230411'
import { Entry20230503 } from './entries/Entry20230503'
import { Entry20230524 } from './entries/Entry20230524'
import { Entry20230525 } from './entries/Entry20230525'
import { Entry20230526 } from './entries/Entry20230526'
import { Entry20230614 } from './entries/Entry20230614'
import { Entry20230629 } from './entries/Entry20230629'
import { Entry20230717 } from './entries/Entry20230717'
import { Entry20230719 } from './entries/Entry20230719'
import { Entry20230724 } from './entries/Entry20230724'
import { Entry20230728 } from './entries/Entry20230728'
import { Entry20230803 } from './entries/Entry20230803'
import { Entry20230809 } from './entries/Entry20230809'
import { Entry20230927 } from './entries/Entry20230927'
import { Entry20231011 } from './entries/Entry20231011'
import { Entry20231017 } from './entries/Entry20231017'
import { Entry20231025 } from './entries/Entry20231025'
import { Entry20231102 } from './entries/Entry20231102'
import { Entry20231115 } from './entries/Entry20231115'
import { Entry20231116 } from './entries/Entry20231116'
import { Entry20231129 } from './entries/Entry20231129'
import { Entry20231207 } from './entries/Entry20231207'
import { Entry20231219 } from './entries/Entry20231219'
import { Entry20240103 } from './entries/Entry20240103'
import { Entry20240117 } from './entries/Entry20240117'
import { Entry20240131 } from './entries/Entry20240131'
import { Entry20240201 } from './entries/Entry20240201'
import { Entry20240208 } from './entries/Entry20240208'
import { Entry20240221 } from './entries/Entry20240221'
import { Entry20240403 } from './entries/Entry20240403'
import { Entry20240417 } from './entries/Entry20240417'
import { Entry20240418 } from './entries/Entry20240418'
import { Entry20240508 } from './entries/Entry20240508'
import { Entry20240508_2 } from './entries/Entry20240508_2'
import { Entry20240514 } from './entries/Entry20240514'
import { Entry20240522 } from './entries/Entry20240522'
import { Entry20240605 } from './entries/Entry20240605'
import { Entry20240617 } from './entries/Entry20240617'
import { Entry20240619 } from './entries/Entry20240619'
import { Entry20240703 } from './entries/Entry20240703'
import { Entry20240711 } from './entries/Entry20240711'
import { Entry20240731 } from './entries/Entry20240731'
import { Entry20240805 } from './entries/Entry20240805'
import { Entry20240821 } from './entries/Entry20240821'
import { Entry20240827 } from './entries/Entry20240827'
import { Entry20240902 } from './entries/Entry20240902'
import { Entry20240911 } from './entries/Entry20240911'
import { Entry20240925 } from './entries/Entry20240925'
import { Entry20241009 } from './entries/Entry20241009'
import { Entry20241023 } from './entries/Entry20241023'
import { Entry20241029 } from './entries/Entry20241029'
import { Entry20241126 } from './entries/Entry20241126'
import { Entry20241211 } from './entries/Entry20241211'
import { Entry20241211_2 } from './entries/Entry20241211_2'

export function Body() {
  return (
    <VerticalLayout marginTop={32}>
      <div style={{ width: '100%', height: 400 }}>
        <Scrollbar>
          <Entry20241211_2 />
          <Entry20241211 />
          <Entry20241126 />
          <Entry20241029 />
          <Entry20241023 />
          <Entry20241009 />
          <Entry20240925 />
          <Entry20240911 />
          <Entry20240902 />
          <Entry20240827 />
          <Entry20240821 />
          <Entry20240805 />
          <Entry20240731 />
          <Entry20240711 />
          <Entry20240703 />
          <Entry20240619 />
          <Entry20240617 />
          <Entry20240605 />
          <Entry20240522 />
          <Entry20240514 />
          <Entry20240508_2 />
          <Entry20240508 />
          <Entry20240418 />
          <Entry20240417 />
          <Entry20240403 />
          <Entry20240221 />
          <Entry20240208 />
          <Entry20240201 />
          <Entry20240131 />
          <Entry20240117 />
          <Entry20240103 />
          <Entry20231219 />
          <Entry20231207 />
          <Entry20231129 />
          <Entry20231116 />
          <Entry20231115 />
          <Entry20231102 />
          <Entry20231025 />
          <Entry20231017 />
          <Entry20231011 />
          <Entry20230927 />
          <Entry20230809 />
          <Entry20230803 />
          <Entry20230728 />
          <Entry20230724 />
          <Entry20230719 />
          <Entry20230717 />
          <Entry20230629 />
          <Entry20230614 />
          <Entry20230526 />
          <Entry20230525 />
          <Entry20230524 />
          <Entry20230503 />
          <Entry20230411 />
          <Entry20230405 />
          <Entry20230322 />
          <Entry20230302 />
          <Entry20230221 />
          <Entry20230219 />
          <Entry20230215 />
          <Entry20230126 />
          <Entry20230125 />
          <Entry20221222 />
          <Entry20221214 />
          <Entry20221202 />
          <Entry20221130 />
          <Entry20221109 />
          <Entry20221026 />
          <Entry20221012 />
          <Entry20221004_2 />
          <Entry20221004 />
          <Entry20220914 />
          <Entry20220901 />
          <Entry20220830 />
          <Entry20220826 />
          <Entry20220824 />
          <Entry20220804 />
          <Entry20220802 />
          <Entry20220728 />
          <Entry20220727 />
          <Entry20220714 />
          <Entry20220712 />
          <Entry20220629 />
          <Entry20220622 />
          <Entry20220608 />
          <Entry20220524 />
          <Entry20220519 />
          <Entry20220518 />
          <Entry20220517 />
          <Entry20220510 />
          <Entry20220505 />
          <Entry20220503 />
          <Entry20220502_2 />
          <Entry20220502 />
          <Entry20220428 />
          <Entry20220427 />
          <Entry20220415 />
          <Entry20220414 />
          <Entry20220331 />
          <Entry20220311 />
          <Entry20220223 />
          <Entry20220209 />
          <Entry20220128 />
          <Entry20220127 />
          <Entry20220112 />
          <Entry20211216 />
          <Entry20211110 />
          <Entry20211012 />
          <Entry20210930 />
          <Entry20210922 />
          <Entry20210616 />
          <Entry20210531 />
          <Entry20210505 />
          <Entry20210422 />
          <Entry20210420 />
          <Entry20210409 />
          <Entry20210401 />
          <Entry20210330 />
          <Entry20210326 />
          <Entry20210319 />
          <Entry20210312 />
          <Entry20210309 />
          <Entry20210305 />
          <Entry20210226 />
          <Entry20210222 />
          <Entry20210212 />
          <Entry20210205 />
          <Entry20210129 />
          <Entry20210121 />
        </Scrollbar>
      </div>
    </VerticalLayout>
  )
}
