import type { NavigoCategory } from '@/atoms'
import { useNavigoSelection } from '@/atoms'

const emptyIds = []
export const useNavigoCategorySelection = () => {
  const { selectedCategories, selection, resetSelection } = useNavigoSelection()

  const category: NavigoCategory =
    selectedCategories.length === 1 ? selectedCategories[0] : 'multiCategory'
  const ids =
    !!category && category !== 'multiCategory' ? (selection[category] ?? emptyIds) : emptyIds

  return {
    ids,
    category,
    resetSelection,
  }
}
