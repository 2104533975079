import { produce } from 'immer'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderStepsListOptions } from '@/features/domain/lists'
import { setListOptions, setOrdersFilter } from '@/features/domain/lists/actions'
import { isDeepEqual } from '@/server-data'
import { useAppDispatch } from '@/store'

type OrderFilterStatus = {
  type: 'all' | 'assigned' | 'unassigned'
  state: 'all' | uui.domain.client.rm.ExtendedOrderStepStatus
  pod: boolean
  expiring: boolean
  violated: boolean
}

const filterValuesByOrdersType = {
  all: [false, true],
  assigned: [false],
  unassigned: [true],
}

const filterValuesByOrdersState = {
  all: ['completed', 'notCompleted', 'toDo', 'unassigned', 'undeclared'],
  toDo: ['toDo'],
  completed: ['completed'],
  undeclared: ['undeclared'],
  notCompleted: ['notCompleted'],
}

export function useFilterList() {
  const dispatch = useAppDispatch()
  const listOptions = useSelector(selectOrderStepsListOptions)

  const setFilter = useCallback(
    async (filter: {
      type: 'all' | 'assigned' | 'unassigned'
      state: 'all' | uui.domain.client.rm.ExtendedOrderStepStatus
      pod: boolean
      violated: boolean
      expiring: boolean
    }) => {
      const nextOptions = produce(listOptions, draft => {
        draft.filter = [
          {
            field: 'isUnassigned',
            values: filterValuesByOrdersType[filter.type],
            operator: 'or',
          },
          {
            field: 'status',
            values: filterValuesByOrdersState[filter.state],
            operator: 'or',
          },
          {
            field: 'pod',
            values: filter.pod ? [true] : [false, true],
            operator: 'or',
          },
          {
            field: 'violated',
            values: filter.violated ? [true] : [false, true],
            operator: 'or',
          },
          {
            field: 'expiring',
            values: filter.expiring ? [true] : [false, true],
            operator: 'or',
          },
        ]
      })

      // avoid triggering any calculations in case the group didn't change
      if (nextOptions === listOptions) return

      const request = await dispatch(setOrdersFilter(nextOptions))
      return setListOptions.fulfilled.match(request)
    },
    [dispatch, listOptions],
  )

  return {
    filter: listOptions.filter.reduce((acc, { values }, index) => {
      switch (index) {
        case 0:
          acc.type = getOrderStepsType(values)
          break

        case 1:
          acc.state = getOrderStepsStatus(values)
          break

        case 2:
          acc.pod = values.length === 2 ? false : values[0] === true
          break

        case 3:
          acc.violated = values.length === 2 ? false : values[0] === true
          break

        case 4:
          acc.expiring = values.length === 2 ? false : values[0] === true
          break
      }

      return acc
    }, {} as OrderFilterStatus),
    setFilter,
  }
}

function getOrderStepsType(
  filterValues: string[] | number[] | boolean[],
): OrderFilterStatus['type'] {
  const key = Object.keys(filterValuesByOrdersType).find(ordersType => {
    const values = filterValuesByOrdersType[ordersType]

    /*
      filterValues could [true,false] or [false,true], in both cases we need to match it with the
      values of filterValuesByOrdersType
    */
    return isDeepEqual([...filterValues].sort(), values)
  })
  if (!key && process.env.NODE_ENV === 'development') {
    throw new Error(`No orders type for ${filterValues}`)
  }

  return (key ?? '') as OrderFilterStatus['type']
}

function getOrderStepsStatus(filterValues: string[] | number[] | boolean[]) {
  const key = Object.keys(filterValuesByOrdersState).find(orderState => {
    const values = filterValuesByOrdersState[orderState]

    /*
      filterValues could be in different sorting order, in both cases we need to match it with the
      values of filterValuesByOrdersState
    */
    return isDeepEqual([...filterValues].sort(), values)
  })
  if (!key && process.env.NODE_ENV === 'development') {
    throw new Error(`No orders type for ${filterValues}`)
  }

  return (key ?? '') as OrderFilterStatus['state']
}
