import { type Calculation } from 'final-form-calculate'
import { computeDeviceData, createEmptyDeviceData } from '../../../hooks/useDeviceData'

export function createWwgpsDecorators(
  availableDevices: string[],
  metric: boolean,
  mutableDeviceId: React.MutableRefObject<number | undefined>,
  devicesAggregateInfo: Record<string, uui.domain.client.gps.wwgps.DeviceAggregateInfo>,
  allGpsTags: Record<string, uui.domain.client.gps.wwgps.Tag>,
  userConfig: uui.domain.UserConfiguration,
  updateInitialValues: (values: uui.domain.ui.forms.Rm360VehicleFormValues) => void,
  initialValues: uui.domain.ui.forms.Rm360VehicleFormValues,
): Calculation[] {
  return [
    {
      field: 'trackingOption',
      updates: (
        option: uui.domain.ui.forms.Rm360VehicleFormValues['trackingOption'],
        _,
        allValues: uui.domain.ui.forms.Rm360VehicleFormValues,
        prevValues: uui.domain.ui.forms.Rm360VehicleFormValues,
      ) => {
        if (!prevValues.trackingOption || prevValues.trackingOption === allValues.trackingOption) {
          return {}
        }

        const deviceId = option === 'mobileApp' ? null : (availableDevices[0] ?? null)
        const deviceData = option === 'mobileApp' ? createEmptyDeviceData(metric) : allValues.device

        return {
          rm: { ...allValues.rm, deviceId },
          device: deviceData,
        }
      },
    },
    {
      field: 'rm.deviceId',
      updates: (deviceId: number, _: string, __: uui.domain.ui.forms.Rm360VehicleFormValues) => {
        if (mutableDeviceId.current === deviceId) {
          return {}
        }
        mutableDeviceId.current = deviceId

        const deviceAggregateInfo = devicesAggregateInfo[deviceId]
        if (!deviceAggregateInfo) return {}

        const {
          device,
          deviceConfiguration,
          driverBehaviors,
          gpsTags,
          idleStartThreshold,
          speedMonitor,
        } = deviceAggregateInfo

        const deviceData = computeDeviceData({
          allGpsTags,
          device,
          userConfig,
          deviceConfiguration,
          driverBehaviors,
          gpsTags,
          idleStartThreshold,
          speedMonitor,
        })

        updateInitialValues({
          ...initialValues,
          device: deviceData,
        })

        return {
          device: deviceData,
        }
      },
    },
  ]
}
