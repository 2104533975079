import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'
import type { ValidateOn } from '@/formUi'
import type { BulkFieldValue } from '../../../types'

import { Stack, FormHelperText } from '@mui/material'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

import { EligibilityTypePicker } from './components/EligibilityTypePicker'
import { Calendar } from './components/Calendar'
import { useActions } from './hooks/useActions'

type RequiredFormField = BulkFieldValue<uui.domain.client.rm.Eligibility>

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  name: FIELD_NAME
  helperText?: string
  testId?: string
  validateOn?: ValidateOn
  disabled?: boolean
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkEligibility<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const { label, name, helperText, disabled, validateOn, testId, ...options } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const { field, errors, formApi } = useFormField<FIELD_NAME, PartialForm, FormError<FIELD_NAME>>(
    name,
    options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>,
  )

  const actions = useActions<FIELD_NAME, FIELDS>(name, validateOn)

  const { visible, value: fieldValue, status } = field

  const typePickerValue = fieldValue.status === 'mixed' ? 'mixed' : fieldValue.value.type

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  const hasMessageToDisplay = !!errorText || helperText

  if (!visible) return null

  return (
    <Stack spacing={2} data-testid={testId} data-trackid={testId}>
      <EligibilityTypePicker
        label={label}
        value={typePickerValue}
        onChange={actions.onChangeEligibilityType}
        disabled={fieldDisabled}
        error={fieldHasError}
      />
      {fieldValue.status === 'exact' && (
        <Calendar eligibility={fieldValue.value} onChange={actions.onChangeDate} />
      )}
      {hasMessageToDisplay && (
        <FormHelperText error={fieldHasError}>
          {fieldHasError ? errorText : helperText}
        </FormHelperText>
      )}
    </Stack>
  )
}
