type Nullable<T> = T | null | undefined

const stringHash = (str: string = ''): number =>
  Array(str.length)
    .fill(null)
    .reduce((acc: number, _, idx: number): number => acc * 31 + str.charCodeAt(idx), 0)

// export const additionalColors = ['FFFFFF', '979797', '303030']

export const publicColorPalette: Readonly<string[]> = [
  '269AFD',
  '0088D3',
  'A8D7FA',
  '87D1EE',
  '5EBAD9',
  '5E79BC',
  '1E538F',
  '091B65',
  '32CFBC',
  '0FB1B4',
  '94C6D1',
  '94CAB3',
  '32BC97',
  '638A8D',
  '117C8E',
  '0D4D59',
  '80E220',
  '50B86B',
  'A2E384',
  'B1CB5D',
  'BBAE5F',
  'A3B67E',
  '8F9A4B',
  '536B3B',
  'FF9D14',
  'F0C366',
  'FDD8A3',
  'FFDA3A',
  'FECB4D',
  'D8C9AF',
  'EFBC8D',
  '89411B',
  'ED4A11',
  'DD2A0C',
  'DE7B66',
  'C54C1F',
  'D57074',
  'C56A3D',
  '9B151C',
  '623A2E',
  '8A38F5',
  '745EC5',
  'A990DD',
  'E580C9',
  'FEA5A9',
  'D38DAB',
  'A5758B',
  '474E95',
]

export const colorPalette: Readonly<string[]> = [
  ...publicColorPalette,
  // additionalColors
  'FFFFFF',
  '979797',
  '303030',
]

export const getRandomColor = (hash?: string) => {
  if (hash) {
    const index = stringHash(hash) % colorPalette.length
    const color = colorPalette[index]
    return color
  }

  return colorPalette[Math.floor(Math.random() * colorPalette.length)]
}

export const normalizeServerColorString = (color: string): string => {
  return color.startsWith('#') || color.startsWith('rgb') ? color : `#${color}`
}

// UUI server send color without starting #
export const colorToPastel = input => {
  const color = shadeBlendConvert(0.7, `#${input}`)
  return color.slice(1)
}

// see: http://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors
export type IColor = {
  r: number
  g: number
  b: number
  a: number
}

const explodeColorToRGB = (d: string): IColor | undefined => {
  let color = d
  const l: number = color.length
  const rgb: IColor = { r: 0, g: 0, b: 0, a: 1 }

  if (l > 9) {
    const da: string[] = color.split(',')
    if (da.length < 3 || da.length > 4) {
      return undefined
    }
    rgb[0] = parseInt(da[0].slice(4), 10)
    rgb[1] = parseInt(da[1], 10)
    rgb[2] = parseInt(da[2], 10)
    rgb[3] = da[3] ? parseFloat(da[3]) : -1
  } else {
    if (l === 8 || l === 6 || l < 4) {
      return undefined
    }

    // 3 digit
    if (l < 6) {
      const r = color[1]
      const g = color[2]
      const b = color[3]
      const a = l > 4 ? `${color[4]}${color[4]}` : ''
      color = `#${r}${r}${g}${g}${b}${b}${a}`
    }
    const dn: number = parseInt(color.slice(1), 16)
    // rgb[0] = (dn >> 16) & 255
    // rgb[1] = (dn >> 8) & 255
    // rgb[2] = dn & 255

    let alpha: number = -1
    if (l === 9 || l === 5) {
      alpha = Math.round((((dn >> 24) & 255) / 255) * 10000) / 10000
    }
    // rgb[3] = alpha

    return {
      r: (dn >> 16) & 255,
      g: (dn >> 8) & 255,
      b: dn & 255,
      a: alpha,
    }
  }

  return rgb
}

const shadeBlendConvert = (originalBlend: number, input: string, originalTarget?: string) => {
  if (
    typeof originalBlend !== 'number' ||
    originalBlend < -1 ||
    originalBlend > 1 ||
    typeof input !== 'string' ||
    (input[0] !== 'r' && input[0] !== '#') ||
    (typeof originalTarget !== 'string' && originalTarget !== undefined)
  ) {
    return undefined
  }

  let outputAsRGB = input.length > 9
  if (typeof originalTarget === 'string') {
    outputAsRGB = originalTarget.length > 9 ? true : originalTarget === 'c' ? !outputAsRGB : false
  }

  const negativeBlend = originalBlend < 0
  const blend = negativeBlend ? originalBlend * -1 : originalBlend

  const target =
    originalTarget && originalTarget !== 'c'
      ? originalTarget
      : negativeBlend
        ? '#000000'
        : '#FFFFFF'

  const inputColor = explodeColorToRGB(input)
  const targetColor = explodeColorToRGB(target)

  if (!inputColor || !targetColor) {
    return undefined
  }

  const { r: inputRed, g: inputGreen, b: inputBlue, a: inputAlpha } = inputColor
  const { r: targetRed, g: targetGreen, b: targetBlue, a: targetAlpha } = targetColor

  let output
  if (outputAsRGB) {
    const red = Math.round((targetRed - inputRed) * blend + inputRed)
    const green = Math.round((targetGreen - inputGreen) * blend + inputGreen)
    const blue = Math.round((targetBlue - inputBlue) * blend + inputBlue)

    let alpha
    if (inputAlpha > -1 || targetAlpha > -1) {
      if (inputAlpha > -1 && targetAlpha > -1) {
        alpha = Math.round(((targetAlpha - inputAlpha) * blend + inputAlpha) * 10000) / 1000
      } else {
        alpha = targetAlpha < 0 ? inputAlpha : targetAlpha
      }
    }
    output = `rgb(${red},${green},${blue})`

    if (alpha) {
      output = `${output},${alpha}`
    }
  } else {
    const red = Math.round((targetRed - inputRed) * blend + inputRed) * 0x10000
    const green = Math.round((targetGreen - inputGreen) * blend + inputGreen) * 0x100
    const blue = Math.round((targetBlue - inputBlue) * blend + inputBlue)

    let alpha
    if (inputAlpha > -1 && targetAlpha > -1) {
      alpha = Math.round(((targetAlpha - inputAlpha) * blend + inputAlpha) * 255)
    } else if (targetAlpha > -1) {
      alpha = Math.round(targetAlpha * 255)
    } else {
      alpha = inputAlpha > -1 ? Math.round(inputAlpha * 255) : 255
    }
    alpha = 0x100000000 + alpha * 0x1000000

    const compositeColor = (alpha + red + green + blue)
      .toString(16)
      .slice(inputAlpha > -1 || targetAlpha > -1 ? 1 : 3)

    output = `#${compositeColor}`
  }
  return output
}

const isHex = (hex: string): boolean => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex)

export const getValidRGBColor = (color: string): Nullable<IColor> => {
  if ((color.startsWith('#') && isHex(color)) || color.startsWith('rgb')) {
    return explodeColorToRGB(color)
  }

  if (color.length === 6 || color.length === 3) {
    const hexColor: string = `#${color}`
    if (isHex(hexColor)) {
      return explodeColorToRGB(hexColor)
    }
  }
}

const getColorBrightness = (color: string): Nullable<number> => {
  const rgb = getValidRGBColor(color)
  if (!rgb) return null

  const { r, g, b } = rgb
  return (r * 299 + g * 587 + b * 114) / 1000 // https://www.w3.org/TR/AERT/#color-contrast
}

export const isDarkColor = (color: string): Nullable<boolean> => {
  const brightness: Nullable<number> = getColorBrightness(color)

  // 0 being the darkest and 255 the brightest value
  return typeof brightness === 'number' ? brightness <= 126 : null
}
