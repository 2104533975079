import styled from 'styled-components'

type Props = {
  disabled?: boolean
  checked?: boolean
}

export const RadioIcon = styled.span<Props>`
  display: block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: ${p => (p.checked ? 4 : 1)}px solid
    ${p =>
      p.disabled
        ? p.theme.colors.$alabaster
        : p.checked
          ? p.theme.colors.$scienceBlue
          : p.theme.colors.$whiteSmoke};
  background: ${p => p.theme.colors.$alabaster};
  transition: border 0.1s ease-out;
`
RadioIcon.displayName = 'RadioIcon'
