import { produce } from 'immer'
import { extractOrderStepsFromOrder } from '@/server-data'

/**
 * List all the errors you could encounter. Most of them cannot happen at all but they are not
 * blocking for the user.
 *
 * @private
 */
export class OrdersError extends Error {
  constructor(
    public readonly message: string,
    public readonly data: OrdersErrorData,
  ) {
    super(message) // 'Error' breaks prototype chain here
    Object.setPrototypeOf(this, new.target.prototype) // restore prototype chain

    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor)
    } else {
      this.stack = new Error(message).stack
    }

    this.name = 'OrdersError'
  }
}

/**
 * Extracts all the Extended Order Step ids from the serialization-optimized extended order. It's
 * helpful to determine which Extended Order Step are related to an Extended Order.
 *
 * @private
 */
export const extractExtendedOrderStepIds = (order: uui.domain.client.rm.ExtendedOrder) => {
  return {
    pickup: order.type === 'p' || order.type === 'pd' ? order.extendedPickup.id : undefined,
    delivery: order.type === 'd' || order.type === 'pd' ? order.extendedDelivery.id : undefined,
  }
}

/**
 * Generate a new Extended Order Steps collection, updating some or all the Extended Order Steps.
 *
 * @private
 */
export const generateNewExtendedOrderSteps = (
  prevPublicData: uui.domain.PublicData,
  nextPublicData: uui.domain.PublicData,
  prevExtendedOrderSteps: Record<string, uui.domain.client.rm.ExtendedOrderStep>,
  changedOrders: uui.domain.api.Changelog,
) => {
  const { add, update, remove } = changedOrders

  if (!add.size && !update.size && !remove.size) {
    throw new OrdersError('No chan ged orders', {
      type: 'noOrdersUpdated',
    })
  }

  const extendedOrderSteps = produce(prevExtendedOrderSteps, draft => {
    for (const orderId of remove) {
      const order = prevPublicData.domain.rm.orders[orderId]
      if (!order) {
        throw new OrdersError(
          `The removed order does not exist in current publicData.domain.rm.orders (order ${orderId})`,
          {
            type: 'removedOrderDoesNotExist',
            orderId,
          },
        )
      }

      const extendedOrderStepIds = extractExtendedOrderStepIds(order)
      if (extendedOrderStepIds.delivery) {
        // console.log(`Removing ${extendedOrderStepIds.delivery}`)
        delete draft[extendedOrderStepIds.delivery]
      }
      if (extendedOrderStepIds.pickup) {
        // console.log(`Removing ${extendedOrderStepIds.pickup}`)
        delete draft[extendedOrderStepIds.pickup]
      }
    }

    for (const orderId of add) {
      const order = nextPublicData.domain.rm.orders[orderId]
      if (!order) {
        throw new OrdersError(
          `The added order does not exist in current publicData.domain.rm.orders (order ${orderId})`,
          {
            type: 'addedOrderDoesNotExist',
            orderId,
          },
        )
      }

      const extendedOrderSteps = extractOrderStepsFromOrder(order)
      // console.log(`Adding ${orderId}`)
      for (const id in extendedOrderSteps) {
        draft[id] = extendedOrderSteps[id]
      }
    }

    for (const orderId of update) {
      const order = nextPublicData.domain.rm.orders[orderId]
      if (!order) {
        throw new OrdersError(
          `The updated order does not exist in current publicData.domain.rm.orders (order ${orderId})`,
          {
            type: 'updatedOrderDoesNotExist',
            orderId,
          },
        )
      }

      /**
       * Let's remove both orderSteps before regeneration.
       *
       * Otherwise, a deleted extendedOrderStep will continue to exist
       * in the store (ex. an Order updated from `PD` to `P` means
       * that an extendedOrderStep must be removed).
       **/

      delete draft[`${order.id}-p`]
      delete draft[`${order.id}-d`]

      const extendedOrderSteps = extractOrderStepsFromOrder(order)
      // console.log(`Updating ${orderId}`)
      for (const id in extendedOrderSteps) {
        draft[id] = extendedOrderSteps[id]
      }
    }
  })

  return extendedOrderSteps
}

type OrdersErrorData =
  | { type: 'noOrdersUpdated' }
  | {
      type: 'missingPickup'
      orderId: string
      orderType: uui.domain.client.rm.OrderIdentifier
    }
  | {
      type: 'missingDelivery'
      orderId: string
      orderType: uui.domain.client.rm.OrderIdentifier
    }
  | {
      type: 'removedOrderDoesNotExist'
      orderId: string
    }
  | {
      type: 'addedOrderDoesNotExist'
      orderId: string
    }
  | {
      type: 'updatedOrderDoesNotExist'
      orderId: string
    }
