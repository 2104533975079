import styled from 'styled-components'

type Props = {
  selected?: boolean
  disabled?: boolean
}

const ItemRendererIcon = styled.div<Props>`
  flex: 0 0 auto;
  color: ${p =>
    p.selected
      ? p.theme.colors.$pureWhite
      : p.disabled
        ? p.theme.colors.$shadyLady
        : p.theme.colors.$silver};
  width: 8px;
  user-select: none;
  pointer-events: none;
`

ItemRendererIcon.displayName = 'ItemRendererIcon'
export default ItemRendererIcon
