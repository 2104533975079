import type { Notifications } from '../../../../types'
import type {
  FormError,
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'

import { InfoOutlined } from '@mui/icons-material'
import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { Link, TextField, FormControl, Box, FormHelperText, Divider, Stack } from '@mui/material'

import { Tooltip } from '@/components/primitives/Tooltip'
import { useDebounceText } from '@/formUi'
import { useComputeSegmentsCount } from '@/hooks'

import { NotificationLimits } from './types'
import { HotwordsMenu } from './components/HotwordsMenu/HotwordsMenu'

import { useMessageLength } from './hooks/useMessageLength'
import { useValidation } from './hooks/useValidation'
import { useHotwords } from './hooks/useHotwords'
import { useActions } from './hooks/useActions'
import { useTexts } from './hooks/useTexts'
import { useRows } from './hooks/useRows'

type RequiredFormField = string

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
> = {
  label: string
  testId?: string
  name: FIELD_NAME
  helperText?: string
  notificationType: Notifications
  notificationLimits: NotificationLimits
  includeUnsubscriptionInstruction?: boolean
  includeTrackingLink?: boolean
} & UseFormFieldOptions<FIELDS, ERROR>

export function HotwordsField<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends FormError<keyof FIELDS> = FormError<keyof FIELDS>,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const {
    label,
    name,
    helperText,
    notificationType,
    notificationLimits,
    includeUnsubscriptionInstruction,
    includeTrackingLink,
    testId,
    ...options
  } = props

  // internal type used to resolve the connected Form Field to a `string` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const { field, errors, fieldApi, formApi } = useFormField<
    FIELD_NAME,
    PartialForm,
    FormError<FIELD_NAME>
  >(name, options as UseFormFieldOptions<PartialForm, FormError<FIELD_NAME>>)

  const { required, status, disabled, visible } = field
  const { hotwords, addHotword } = useHotwords(notificationType, field.value, fieldApi.change)
  const messageLength = useMessageLength(
    notificationLimits,
    field.value,
    includeUnsubscriptionInstruction,
    includeTrackingLink,
  )

  const { notificationWay } = formApi.getValues()

  useValidation(notificationType, name, fieldApi)

  const texts = useTexts()

  const segmentsCount = useComputeSegmentsCount(
    notificationLimits,
    field.value,
    includeUnsubscriptionInstruction,
    includeTrackingLink,
  )

  const { change, onBlur, onFocus } = useActions<FIELD_NAME, FIELDS>(fieldApi)
  const [value, onChange] = useDebounceText(field.value, change, 200)
  const rows = useRows()

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)
  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined

  // Disable the field also while the form is submitting
  const fieldDisabled = disabled || formApi.getMeta().submitting || formApi.getMeta().disabled

  if (!visible) return null

  const info =
    notificationWay === 'PHONE' ? (
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center">
          {texts.segmentsCount(segmentsCount)}

          <Box ml={0.5} mt={0.5}>
            <Tooltip placement="bottom" title={texts.segmentsTooltip}>
              <InfoOutlined sx={{ fontSize: 18 }} />
            </Tooltip>
          </Box>
        </Stack>

        <Link
          href="https://workwave.my.site.com/routemanager/s/article/Enhanced-Communication-Plan-and-Usage"
          target="_blank"
          rel="noopener noreferrer"
          variant="body1"
        >
          {texts.learnMore}
        </Link>
      </Stack>
    ) : (
      `${messageLength}/2500`
    )

  return (
    <Stack data-testid={testId} data-trackid={testId}>
      <FormControl error={fieldHasError}>
        <Box marginBottom={2}>
          <TextField
            name={name}
            error={fieldHasError}
            required={required}
            label={label}
            multiline
            rows={rows}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            disabled={fieldDisabled}
          />
          <FormHelperText sx={{ marginX: 0 }} error={fieldHasError}>
            {fieldHasError ? errorText : info}
          </FormHelperText>
        </Box>
      </FormControl>
      <HotwordsMenu addHotword={addHotword} hotwords={hotwords} disabled={fieldDisabled} />
      {notificationWay === 'PHONE' && <FormHelperText>{texts.info}</FormHelperText>}

      <Divider sx={{ mt: 2 }} />
    </Stack>
  )
}
