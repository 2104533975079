import { styled } from '@mui/material'
import { EyeCrossed } from '@/icons'
import { theme } from '@/styles'

interface Props {
  selected?: boolean
  enabled?: boolean
}

const ItemRendererVisibilityToggleComponent = styled('div')(({ selected, enabled }: Props) => ({
  width: 40,
  height: 40,
  display: 'flex',
  flex: '0 0 auto',
  userSelect: 'none',
  alignItems: 'center',
  justifyContent: 'flex-end',
  transition: 'opacity 0.3s ease',
  opacity: enabled ? 0.4 : 0,
  color: selected
    ? enabled
      ? theme.colors.$pureWhite
      : theme.colors.$scienceBlue
    : enabled
      ? theme.colors.$nightRider
      : theme.colors.$scienceBlue,

  '*:hover > * > &': {
    opacity: 1,
    color: selected
      ? enabled
        ? theme.colors.$pureWhite
        : theme.colors.$nightRider
      : enabled
        ? theme.colors.$nightRider
        : theme.colors.$scienceBlue,
  },
}))

export function ItemRendererVisibilityToggle(props: Props) {
  return (
    <ItemRendererVisibilityToggleComponent {...props}>
      <EyeCrossed size={14} />
    </ItemRendererVisibilityToggleComponent>
  )
}
