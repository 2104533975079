export function getSchedulerColor(color: keyof typeof colors) {
  return window.getComputedStyle(document.body).getPropertyValue(color)
}

export function getViolationsColor() {
  return getSchedulerColor('--scheduler-error')
}

export function getPercentageColor(percentage: number) {
  const color =
    percentage <= 0
      ? '--scheduler-gray10'
      : percentage <= 90
        ? '--scheduler-valid'
        : percentage <= 100
          ? '--scheduler-warning'
          : '--scheduler-error'

  return window.getComputedStyle(document.body).getPropertyValue(color)
}

const colors = {
  '--scheduler-gray': true,
  '--scheduler-blue': true,
  '--scheduler-lock': true,
  '--scheduler-error': true,
  '--scheduler-black': true,
  '--scheduler-white': true,
  '--scheduler-gray5': true,
  '--scheduler-valid': true,
  '--scheduler-gray10': true,
  '--scheduler-border': true,
  '--scheduler-warning': true,
  '--scheduler-selection': true,
  '--scheduler-dirtyWhite': true,
  '--scheduler-hoverSelection': true,
}
