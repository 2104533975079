import type { PopoverOrigin } from '@mui/material'
import type { TimeFormat } from './types'
import type { UseValidateOn } from '../../hooks/useValidateOn'

import { useRef, useEffect } from 'react'
import {
  Box,
  Paper,
  Popover,
  MenuList,
  MenuItem,
  ClickAwayListener,
  TextField as MuiTextField,
} from '@mui/material'

import { FieldMessage } from '../../../formFields/FieldMessage'
import { useTimePicker } from './hooks/useTimePicker'
import { useOptions } from './hooks/useOptions'
import { useStyles } from './hooks/useStyles'

import { convertTimeInput, findIndexOfNearestOption } from './utils'

import { Adornment } from './components/Adornment'

type Props = {
  // Field label
  label: string

  // Helper text
  helperText?: string

  // Time format
  format: TimeFormat

  // List of options to show in the dropdown (seconds from midnight)
  optionValues: number[]

  errorLabels: { emptyField: string; invalidFormat: string }

  // If allowUnlistedValues is TRUE, the TimePicker will accept also unlisted values, otherwise it will treat them as invalid
  allowUnlistedValues?: boolean

  value: number
  required?: boolean
  disabled?: boolean
  visible?: boolean
  validations: UseValidateOn
  hasError?: boolean
  errorMessage?: string

  testId?: string

  onChange: (value: number) => void
}

const anchorOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

const transformOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'center',
}

export function TimePickerBody(props: Props) {
  const {
    label,
    format,
    helperText,
    optionValues,
    errorLabels,
    allowUnlistedValues = false,
    value,
    required,
    hasError,
    disabled,
    visible,
    errorMessage,
    validations,
    onChange,
    testId,
  } = props

  // Retrieve TimePicker values and actions
  const {
    actions,
    componentState: { open, inputValue, invalidInput, selectValue, error: componentError },
  } = useTimePicker(format, optionValues, validations, value, onChange, allowUnlistedValues)

  // Convert options to a valid array of dropdown options
  const dropdownOptions = useOptions(optionValues, format)

  // Styles
  const styles = useStyles()

  // Refs
  const rootRef = useRef<HTMLDivElement | null>(null)
  const menuRef = useRef<HTMLDivElement | null>(null)

  const optionsRef = useRef(optionValues)
  useEffect(() => {
    optionsRef.current = optionValues
  }, [optionValues])

  // That effect let the dropdown scroll to the selected element
  useEffect(() => {
    if (!open) return

    const refOptionValues = optionsRef.current

    // The setTimeout is required to let the dropdown appear before trying to scroll it
    setTimeout(() => {
      const selectedElements = menuRef.current?.querySelectorAll('[data-selected="true"]')

      // If there's at least one selected element, scroll to it and return
      if (selectedElements && selectedElements.length > 0) {
        selectedElements.item(0).scrollIntoView()
        return
      }

      // If there's no selected elements let's scroll to the nearest one

      // If the input is not valid let's return
      const convertedInputValue = convertTimeInput(inputValue, format)
      if (convertedInputValue === 'invalid') return

      // If there are no dropdown elements, let's return
      const elements = menuRef.current?.getElementsByTagName('li')
      if (!elements) return

      // Let's find the nearest option
      const indexWithMinInterval = findIndexOfNearestOption(convertedInputValue, refOptionValues)

      if (!indexWithMinInterval) return

      // Scroll down to the nearest option
      elements.item(indexWithMinInterval)?.scrollIntoView()
    }, 0)
  }, [format, selectValue, inputValue, open])

  // Since the Dropdown is in a portal we should provide him the correct width
  const paperProps = {
    style: { width: rootRef.current?.offsetWidth },
  }

  const { mouseActions, focusActions, keyboardActions, changeActions } = actions

  // Resolve the field meta states to improve code readability
  const fieldHasError = !!componentError || !!hasError

  const errorText = componentError
    ? errorLabels[componentError]
    : fieldHasError
      ? (errorMessage ?? 'Unknown Error')
      : undefined

  const gotErrors = invalidInput || fieldHasError

  if (!visible) return null

  return (
    <ClickAwayListener onClickAway={mouseActions.onClickAway}>
      <Box
        display="flex"
        position="relative"
        flexDirection="column"
        justifyContent="center"
        data-testid={testId}
      >
        <MuiTextField
          label={label}
          ref={rootRef}
          error={gotErrors}
          value={inputValue}
          required={required}
          disabled={disabled}
          onBlur={focusActions.onBlur}
          onFocus={focusActions.onFocus}
          onClick={mouseActions.onClick}
          onKeyDown={keyboardActions.onKeyDown}
          onChange={changeActions.onInputChange}
          InputProps={{
            endAdornment: <Adornment onClick={mouseActions.onClickAdornment(rootRef.current)} />,
          }}
        />
        <FieldMessage error={fieldHasError} helperText={helperText} errorText={errorText} />

        <Popover
          open={open}
          hideBackdrop
          ref={menuRef}
          disableAutoFocus
          disableScrollLock
          disableRestoreFocus
          disableEnforceFocus
          PaperProps={paperProps}
          className={styles.popover}
          anchorEl={rootRef.current}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
        >
          <Paper elevation={4}>
            <MenuList disableListWrap className={styles.menu}>
              {dropdownOptions.map(option => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  onClick={changeActions.onSelectChange}
                  selected={selectValue === option.value}
                  data-selected={selectValue === option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </MenuList>
          </Paper>
        </Popover>
      </Box>
    </ClickAwayListener>
  )
}
