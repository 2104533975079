import { type SchedulerPro } from '@bryntum/schedulerpro'
import { addSeconds } from 'date-fns/esm'

export async function setDefaultTimeSpanZoom(
  scheduler: SchedulerPro,
  calendarRangeInterval: { start: Date; end: Date },
  dayRangeStartSec: number,
  dayRangeEndSec: number,
) {
  const { start } = calendarRangeInterval

  // Compute new visible time span
  const zoomStartDate = addSeconds(start, dayRangeStartSec)
  const zoomEndDate = addSeconds(start, dayRangeEndSec)

  // Since zoomTo does not work well when the zoom level is 0, we need to zoom in first
  scheduler.zoomInFull()

  // Zoom to the new time span
  scheduler.zoomTo({
    rightMargin: 0,
    leftMargin: 0,
    startDate: zoomStartDate,
    endDate: zoomEndDate,
  })

  //console.log('default time span', { zoomStartDate, zoomEndDate })

  // Reset scroll position
  await scheduler.scrollVerticallyTo(0, { animate: false })
}
