import { forwardRef } from 'react'

import { FlexBox, H3 } from '@/local/components'

import { DropdownHeadContainer } from './DropdownHeadContainer'
import { IconContainer } from './IconContainer'
import { Props } from './typings'

export const DropdownHead = forwardRef<HTMLButtonElement, Props>(
  function DropdownHeadComp(props, ref) {
    const { onClick, children = '', Icon } = props

    return (
      <DropdownHeadContainer {...props} onClick={onClick} title={children} ref={ref}>
        <FlexBox vAlignContent="center" hAlignContent="left" h={28}>
          {!!Icon && <IconContainer>{Icon}</IconContainer>}
          <H3 ellipsis>{children}</H3>
        </FlexBox>
      </DropdownHeadContainer>
    )
  },
)
