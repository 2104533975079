import { type ReactNode } from 'react'
import { Avatar, useTheme } from '@mui/material'

import { isDark, isValidColor } from '@/styles'

export type Props = {
  testId?: string
  children: ReactNode
  color: string // color refers to the background, the actual color is computed
  textColor?: string
}

export function ListItemAvatarRounded(props: Props) {
  const { children, color, testId = 'ListItemAvatarRounded' } = props
  const theme = useTheme()

  let bgcolor = color

  if (!isValidColor(color)) {
    console.warn(`Invalid color: ${color}`)
    bgcolor = '#fff'
  }

  const textColor = props.textColor
    ? props.textColor
    : isDark(bgcolor)
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary

  return (
    <Avatar data-testid={testId} variant="circular" sx={{ bgcolor, color: textColor }}>
      {children}
    </Avatar>
  )
}
