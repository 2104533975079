import { geo } from '@/server-data'
import { saveKmlFile } from './file'

// async is intentional to be future proof for
// features like run into a requestAnimationFrame
export async function exportRegionToKML(regions: uui.domain.client.rm.Region[]) {
  try {
    const placeMarks = regions.map(region => placemarkTemplate(convertToServerEntity(region)))
    const kml = kmlTemplate(placeMarks)

    saveKmlFile(kml, 'regions.kml')
  } catch (error) {
    console.log('Creation of KML file failed')
  }
}

function convertToServerEntity(region: uui.domain.client.rm.Region): uui.domain.server.rm.Region {
  return {
    id: region.id,
    name: region.name,
    color: normalizeColor(region.color),
    enterCost: region.enterCost,
    enterTimeSec: region.enterTimeSec,
    poly: region.poly,
  }
}

// remove the # from a color
// eg: #aabbcc to aabbcc
function normalizeColor(color: string) {
  return color.startsWith('#') ? color.slice(1) : color
}

function placemarkTemplate(region: uui.domain.server.rm.Region) {
  const { name, enterCost, enterTimeSec, color, poly } = region

  return `
    <Placemark>
      <name>${name}</name>
      <enterCost>${enterCost}</enterCost>
      <enterTimeSec>${enterTimeSec}</enterTimeSec>

      <Style >
        <PolyStyle>
          <color>${hexToKmlColor(color)}</color>
            <fill>1</fill>
            <outline>1</outline>
          </PolyStyle>
          <LineStyle>
            <color>${hexToKmlColor(color)}</color>
            <width>4</width>
          </LineStyle>
      </Style>

      <Polygon>
        <outerBoundaryIs>
          <LinearRing>
            <coordinates>
              ${poly
                .map(([lat, lng]) => `${geo.decodeFloat(lng)},${geo.decodeFloat(lat)},0`)
                .join(' ')}
            </coordinates>
          </LinearRing>
        </outerBoundaryIs>
      </Polygon>
    </Placemark>
`
}

function kmlTemplate(placemarks: string[]) {
  return `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
    <Document>
      ${placemarks.join('\n')}
    </Document>
  </kml>
`
}

// rrggbbaa -> aabbggrr
function hexToKmlColor(hex: string, alpha?: number) {
  const source = hex.startsWith('#') ? hex.slice(1) : hex
  const r = `${source[0]}${source[1]}`
  const g = `${source[2]}${source[3]}`
  const b = `${source[4]}${source[5]}`
  const a =
    typeof alpha === 'number' && !Number.isNaN(alpha)
      ? alpha.toString(16)
      : hex.length === 8
        ? `${source[6]}${source[7]}`
        : 'ff'

  return `${a}${b}${g}${r}`.toLowerCase()
}

const EMPTY_ARRAY = []

export function KMLToRegions(kmlAsString: string): uui.domain.server.rm.Region[] {
  const kml = parseXml(kmlAsString)
  if (!kml) return EMPTY_ARRAY

  if (kml.querySelectorAll('Document').length > 0) {
    const placemarks: NodeListOf<Element> = kml.querySelectorAll('Document Placemark')

    return [...placemarks].map(KMLToRegion).filter(Boolean) as uui.domain.server.rm.Region[]
  } else {
    const errorElement: Element | null = kml.querySelector('parsererror')
    if (errorElement) {
      const error: string = errorElement.textContent || 'Unknown Error Parsing the KML file'
      throw new Error(error)
    }
  }

  return EMPTY_ARRAY
}

function KMLToRegion(node: Element): uui.domain.server.rm.Region | undefined {
  const { tagName } = node

  const nameText = extractPlainName(node) || getUniqueRegionName()

  const costElement = node.querySelector(tagName + '>enterCost')
  const costText = costElement?.textContent ? costElement.textContent : '0'
  const cost = Number.isNaN(parseInt(costText)) ? 0 : parseInt(costText)

  const timeSecElement = node.querySelector(tagName + '>enterTimeSec')
  const timeSecText = timeSecElement?.textContent ? timeSecElement.textContent : '0'
  const timeSec = Number.isNaN(parseInt(timeSecText)) ? 0 : parseInt(timeSecText)

  const lineColorElement = node.querySelector('Style>PolyStyle>color')
  const lineColorText = lineColorElement?.textContent
    ? KmlToHexColor(lineColorElement.textContent)
    : '999999'

  const coordElement = node.querySelector('outerBoundaryIs>LinearRing>coordinates')
  const coordText = coordElement?.textContent ? coordElement.textContent : '0'

  const poly: uui.domain.server.LatLngTuple[] = coordText
    .split(/\s+/)
    .reduce<uui.domain.server.LatLngTuple[]>((res, coordTuple) => {
      const [srcLng, srcLat] = coordTuple.split(',')

      if (srcLat && srcLng) {
        const lat: number = parseFloat(srcLat)
        const lng: number = parseFloat(srcLng)

        if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
          res.push([geo.encodeFloat(lat), geo.encodeFloat(lng)])
        }
      }

      return res
    }, [])

  if (poly.length > 2) {
    return {
      id: '',
      color: lineColorText,
      enterCost: cost,
      enterTimeSec: timeSec,
      name: nameText,
      poly,
    }
  }
}

function parseXml(xml: string): Document | null {
  if (window.DOMParser) {
    const parser: DOMParser = new DOMParser()
    return parser.parseFromString(xml, 'text/xml')
  } else if (
    typeof window.ActiveXObject !== 'undefined' &&
    new window.ActiveXObject('Microsoft.XMLDOM')
  ) {
    const xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM')
    xmlDoc.async = 'false'
    xmlDoc.loadXML(xml)
    return xmlDoc
  }

  return null
}

const NAME_SEPARATOR: string = '/'

function nameSanitizer(str: string) {
  return str.replace(/[^\w\d\s_]/g, '')
}

function extractPlainName(sourceNode: Element) {
  let node: Element | null = sourceNode
  let name: string = ''

  while (node) {
    const { tagName } = node
    if (tagName === 'Folder' || tagName === 'Placemark') {
      const nameNode: Element | null = node.querySelector(tagName + '>name')

      if (nameNode) {
        const { textContent } = nameNode
        const containerName: string | null =
          typeof textContent === 'string' ? nameSanitizer(textContent) : null

        if (containerName) {
          if (name.length) name += NAME_SEPARATOR
          name += containerName
        }
      }
    }
    node = node.parentElement
  }

  return name
}

// aabbggrr -> rrggbbaa
function KmlToHexColor(source: string, alpha?: boolean) {
  const a: string = `${source[0]}${source[1]}`
  const b: string = `${source[2]}${source[3]}`
  const g: string = `${source[4]}${source[5]}`
  const r: string = source.length === 8 ? `${source[6]}${source[7]}` : 'ff'

  return alpha ? `${r}${g}${b}${a}`.toLowerCase() : `${r}${g}${b}`.toLowerCase()
}

const uniqueRegionName = 'Unnamed region'
let uniqueRegionNameCounter = 0
function getUniqueRegionName() {
  return `${uniqueRegionName} ${uniqueRegionNameCounter++}`
}
