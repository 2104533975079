import { geo } from '@/server-data'

import { saveKmlFile } from './file'

// async is intentional to be future proof for
// features like run into a requestAnimationFrame
export async function exportRoadSegmentsToKML(
  roadSegments: uui.domain.client.rm.SegmentException[],
) {
  try {
    const placeMarks = roadSegments.map(segment =>
      placemarkTemplate(toServerSegmentException(segment)),
    )
    const kml = kmlTemplate(placeMarks)

    saveKmlFile(kml, 'normal-day-routing-segments.kml')
  } catch (error) {
    console.log('Creation of KML file failed')
  }
}

function toServerSegmentException(
  segment: uui.domain.client.rm.SegmentException,
): uui.domain.server.rm.SegmentException {
  const { name, color, malus, additionalDrivingTimeSec, start, end, startPoint, endPoint } = segment
  return {
    name,
    color,
    malus,
    additionalDrivingTimeSec,
    start: startPoint ? [geo.encodeFloat(startPoint.lat), geo.encodeFloat(startPoint.lng)] : start,
    end: endPoint ? [geo.encodeFloat(endPoint.lat), geo.encodeFloat(endPoint.lng)] : end,
  }
}

function placemarkTemplate(roadSegment: uui.domain.server.rm.SegmentException) {
  const {
    name,
    color,
    malus,
    additionalDrivingTimeSec,
    start: [startLat, startLng],
    end: [endLat, endLng],
  } = roadSegment

  const start = `${geo.decodeFloat(startLng)},${geo.decodeFloat(startLat)},0`
  const end = `${geo.decodeFloat(endLng)},${geo.decodeFloat(endLat)},0`

  return `
    <Placemark>
      <name>${name}</name>
      <malus>${malus}</malus>
      <additionalDrivingTimeSec>${additionalDrivingTimeSec}</additionalDrivingTimeSec>

      <Style >
        <LineStyle>
          <color>${hexToKmlColor(color)}</color>
          <width>4</width>
        </LineStyle>
      </Style>

      <LineString>
        <extrude>0</extrude>
        <tessellate>0</tessellate>
        <coordinates>${start} ${end}</coordinates>
      </LineString>
    </Placemark>
`
}

export function KMLToSegments(kmlAsString: string): uui.domain.server.rm.SegmentException[] {
  const kml = parseXml(kmlAsString)
  if (kml && kml.querySelectorAll('Document').length > 0) {
    const placemarks = kml.querySelectorAll('Document>Placemark')

    return [...placemarks]
      .map(KMLToSegment)
      .filter(Boolean) as uui.domain.server.rm.SegmentException[]
  }

  return []
}

type Poly = [number, number]
type SegmentPolys = [Poly?, Poly?]

function KMLToSegment(node: Element): uui.domain.server.rm.SegmentException | undefined {
  const { tagName } = node

  const malusElement = node.querySelector(tagName + '>malus')
  const malusText = malusElement?.textContent ? malusElement.textContent : '0'

  const additionalDrivingTimeSecElement = node.querySelector(tagName + '>additionalDrivingTimeSec')
  const additionalDrivingTimeSecText = additionalDrivingTimeSecElement?.textContent
    ? additionalDrivingTimeSecElement.textContent
    : '0'

  const lineColorElement = node.querySelector('Style>LineStyle>color')
  const lineColorText = lineColorElement?.textContent
    ? KmlToHexColor(lineColorElement.textContent)
    : '999999'

  const coordElement = node.querySelector('LineString>coordinates')
  const coordText = coordElement?.textContent ? coordElement.textContent : '0'

  const [start = 0, end = 0] = coordText
    .split(/\s+/)
    .reduce((res: SegmentPolys, coordTuple: string): SegmentPolys => {
      const [srcLng, srcLat] = coordTuple.split(',')

      if (srcLat && srcLng) {
        const lat: number = parseFloat(srcLat)
        const lng: number = parseFloat(srcLng)

        if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
          res.push([geo.encodeFloat(lat), geo.encodeFloat(lng)])
        }
      }
      return res
    }, [])

  if (start && end) {
    return {
      end,
      start,
      color: lineColorText,
      name: extractPlainName(node),
      malus: parseInt(malusText),
      additionalDrivingTimeSec: parseInt(additionalDrivingTimeSecText),
    }
  }

  return
}

function parseXml(xml: string): Document | null {
  if (window.DOMParser) {
    const parser: DOMParser = new DOMParser()
    return parser.parseFromString(xml, 'text/xml')
  }

  if (typeof window.ActiveXObject !== 'undefined' && new window.ActiveXObject('Microsoft.XMLDOM')) {
    const xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM')
    xmlDoc.async = 'false'
    xmlDoc.loadXML(xml)
    return xmlDoc
  }

  return null
}

// aabbggrr -> rrggbbaa
function KmlToHexColor(source: string, alpha?: boolean) {
  const a: string = `${source[0]}${source[1]}`
  const b: string = `${source[2]}${source[3]}`
  const g: string = `${source[4]}${source[5]}`
  const r: string = source.length === 8 ? `${source[6]}${source[7]}` : 'ff'

  return alpha ? `${r}${g}${b}${a}`.toLowerCase() : `${r}${g}${b}`.toLowerCase()
}

// rrggbbaa -> aabbggrr
function hexToKmlColor(hex: string, alpha?: number) {
  const source: string = hex.startsWith('#') ? hex.slice(1) : hex
  const r: string = `${source[0]}${source[1]}`
  const g: string = `${source[2]}${source[3]}`
  const b: string = `${source[4]}${source[5]}`
  const a: string =
    typeof alpha === 'number' && !Number.isNaN(alpha)
      ? alpha.toString(16)
      : hex.length === 8
        ? `${source[6]}${source[7]}`
        : 'ff'

  return `${a}${b}${g}${r}`.toLowerCase()
}

const NAME_SEPARATOR = '/'
const nameSanitizer = (str: string) => str.replace(/[^\w\d\s_]/g, '')

const extractPlainName = (sourceNode: Element): string => {
  let node: Element | null = sourceNode
  let name = ''

  while (node) {
    const { tagName } = node
    if (tagName === 'Folder' || tagName === 'Placemark') {
      const nameNode: Element | null = node.querySelector(tagName + '>name')

      if (nameNode) {
        const { textContent } = nameNode
        const containerName: string | null =
          typeof textContent === 'string' ? nameSanitizer(textContent) : null

        if (containerName) {
          if (name.length) name += NAME_SEPARATOR
          name += containerName
        }
      }
    }
    node = node.parentElement
  }

  return name
}

function kmlTemplate(placemarks: string[]) {
  return `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
  <Document>
    ${placemarks.join('\n')}
  </Document>
</kml>
`
}
