const timeWindowUtils = 24 * 60 * 60

const applyMidnightCrossToTimeWindow = (
  timeWindow: uui.domain.rm.TimeWindow,
  workingDayStartSec: number,
): uui.domain.rm.TimeWindow => {
  const { startSec: start, endSec: end } = timeWindow
  const endOfTheDay: number = timeWindowUtils + workingDayStartSec

  const startSec: number = start >= timeWindowUtils ? start - timeWindowUtils : start

  const endSec: number =
    end === endOfTheDay
      ? workingDayStartSec > 0
        ? end - timeWindowUtils - 1
        : endOfTheDay - 1
      : end > timeWindowUtils
        ? end - timeWindowUtils
        : end

  return {
    startSec,
    endSec,
  }
}

export const normalizeTimeWindows = (
  tw: uui.domain.rm.TimeWindow[],
  workingDayStartSec: number,
): uui.domain.rm.TimeWindow[] =>
  tw.map((tw: uui.domain.rm.TimeWindow) => applyMidnightCrossToTimeWindow(tw, workingDayStartSec))
