import styled from 'styled-components'

type Props = {
  error?: boolean
  warning?: boolean
}

const FieldMessage = styled.p.attrs<Props>(() => ({
  'data-testid': 'form-field-message',
}))<Props>`
  position: relative;
  width: 100%;
  margin: 5px 0 0 0;
  color: ${p =>
    p.error
      ? p.theme.colors.$outrageousRed
      : p.warning
        ? p.theme.colors.$darkOrange
        : p.theme.colors.$nightRider};

  font-size: ${p => p.theme.fonts.$s};
  font-style: italic;
  padding-left: 4px;
`

FieldMessage.displayName = 'FieldMessage'

export default FieldMessage
