import {
  SecondaryColumnContentHorizontalRuler,
  ReadonlyBlock,
  ReadonlyLabel,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'
import * as OrderTexts from '../../../intl'

import { Props, FieldMode } from '../typings'

import * as Texts from '../intl'

export function Company(props: Props) {
  const { company } = props

  const text =
    company === null
      ? OrderTexts.getGlobalNoneText()
      : company === FieldMode.mixed
        ? OrderTexts.getMixedText()
        : company

  return (
    <>
      <ReadonlyBlock>
        <ReadonlyLabel primary>{Texts.getCompanyText()}</ReadonlyLabel>

        <ReadonlyField>
          <ReadonlyFieldRow>
            <ReadonlyFieldLabel>{text}</ReadonlyFieldLabel>
          </ReadonlyFieldRow>
        </ReadonlyField>
      </ReadonlyBlock>
      <SecondaryColumnContentHorizontalRuler bottomSpace />
    </>
  )
}
