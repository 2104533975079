import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { gpsValidTimezones } from '@/local/server-data/utils/smartUtils/timezone'
import { isGpsOnly, selectUserProfile } from '@/features/domain/user'
import { selectPhysicalDevicesList } from '@/features/domain/device'
import { selectTerritory } from '@/features/domain/territory'
import { selectGeofences } from '@/features/domain/geofence'
import { getMapInfo, useInspectPin } from '@/map'
import { locationUtils } from '@/utils'
import { timezoneUtils } from '@/server-data'

export function useNewGeofenceValue() {
  const devices = useSelector(selectPhysicalDevicesList)
  const { timeZoneCode } = useSelector(selectTerritory)
  const geofences = useSelector(selectGeofences)
  const profile = useSelector(selectUserProfile)
  const gpsOnly = useSelector(isGpsOnly)
  const [inspectPin] = useInspectPin()
  const { center } = getMapInfo()

  const initialLatLngFromMapPin =
    inspectPin?.mode === 'converted' && inspectPin.target === 'geofence'
      ? inspectPin.latLng
      : undefined

  const { gpsUser } = profile
  const defaultEmail = gpsOnly ? profile.gpsUser?.email : profile.account.details.email

  return useMemo<uui.domain.client.gps.wwgps.Geofence>(() => {
    const name = computeUniqueName(Object.values(geofences), 'New geofence')
    const location = locationUtils.createEmptyLocation({
      latLng: initialLatLngFromMapPin ?? center,
    })

    return {
      deviceIds: devices.map(({ deviceId }) => deviceId),
      notificationAddresses: [`mailto:${defaultEmail}`],
      longitude: location.latLng.lng,
      latitude: location.latLng.lat,
      repeat: 'continuous',
      organizationId: -1,
      $ISOStart: false,
      type: 'arr_dep',
      latLng: center,
      $ISOEnd: false,
      maxSpeed: null,
      startTime: '',
      endTime: '',
      radius: 100,
      userId: -1,
      location,
      id: '',
      name,

      timezone:
        gpsOnly && gpsUser
          ? gpsUser.timezone
          : gpsValidTimezones.includes(timeZoneCode as timezoneUtils.GpsTimezone)
            ? timeZoneCode
            : 'UTC',
    }
  }, [
    initialLatLngFromMapPin,
    defaultEmail,
    timeZoneCode,
    geofences,
    gpsOnly,
    gpsUser,
    devices,
    center,
  ])
}

// create a map where keys are the segment names
// the values are the number of the occurrences
function createMapOfNames(
  geofences: uui.domain.client.gps.wwgps.Geofence[],
): Record<string, number> {
  const map: Record<string, number> = {}
  for (const { name } of geofences) {
    map[name] = map[name] ? map[name] + 1 : 1
  }
  return map
}

// assign a unique name to the geofence to be imported
// by adding a suffix with the number of that name occur in the regions
function computeUniqueName(geofences: uui.domain.client.gps.wwgps.Geofence[], idealName: string) {
  const namesMap = createMapOfNames(geofences)

  if (namesMap[idealName]) {
    return `${idealName} (${namesMap[idealName]})`
  }

  return idealName
}
