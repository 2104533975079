import { CardCosmetic, SelectionAsRecord } from '@/components/Calendar/typings'

interface Params {
  isToday: boolean
  ghosted: boolean
  disabled: boolean
  selected: boolean
  dateAsString: string
  isOnTheEdge: boolean
  highlightToday?: boolean
  selectableInterval?: SelectionAsRecord
}

export function getCosmetic(params: Params) {
  const {
    isToday,
    disabled,
    selected,
    isOnTheEdge,
    dateAsString,
    highlightToday,
    selectableInterval,
    ghosted,
  } = params

  const grayedOut = (!!selectableInterval && !selectableInterval[dateAsString]) || disabled

  let cosmetic: CardCosmetic = grayedOut
    ? 'grayed-out'
    : isOnTheEdge
      ? 'edge-of-selection'
      : ghosted
        ? 'ghosted'
        : selected
          ? 'selected'
          : 'normal'

  if (isToday && highlightToday && cosmetic !== 'selected' && cosmetic !== 'edge-of-selection') {
    cosmetic = 'hovered'
  }

  return cosmetic
}
