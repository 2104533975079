import type {
  FormField,
  FormSingleField,
  UseFormFieldOptions,
  NarrowFieldsByValueType,
} from '@workwave-tidal/tidal/form-fairy'
import type { BulkLoads as BulkLoadsType } from '../../../types'
import type { BulkLoadsError } from './types'

import { useMemo } from 'react'
import { Dialog, IconButton, FormHelperText, Stack, Tooltip, Box } from '@mui/material'
import { Add } from '@mui/icons-material'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'

import { FieldHeader } from '../../FieldHeader'

import { Menu } from './components/Menu'
import { ExactList } from './components/ExactList'
import { PartialList } from './components/PartialList'
import { AddLoad } from './components/AddLoad'
import { useSplitAndSortLoadsByType } from './hooks/useSplitAndSortLoadsByType'
import { useDialogState } from './hooks/useDialogState'
import { useAddLoadsOptions } from './hooks/useAddLoadsOptions'
import { useTexts } from './hooks/useTexts'
import { useReachedMaxLoads } from './hooks/useReachedMaxLoads'
import { useComputeAddablePartialLoads } from './hooks/useComputeAddablePartialLoads'
import { useActions } from './hooks/useActions'

type RequiredFormField = BulkLoadsType

type Props<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends BulkLoadsError = BulkLoadsError,
> = {
  label: string
  addLoadLabel: string
  name: FIELD_NAME
  helperText?: string
  testId?: string
  disabled?: boolean
  maxLoadsForOrder: number
} & UseFormFieldOptions<FIELDS, ERROR>

export function BulkLoads<
  FIELD_NAME extends NarrowFieldsByValueType<RequiredFormField, FIELDS>,
  FIELDS extends Record<string, FormField>,
  ERROR extends BulkLoadsError = BulkLoadsError,
>(props: Props<FIELD_NAME, FIELDS, ERROR>) {
  const {
    helperText,
    label,
    name,
    addLoadLabel,
    maxLoadsForOrder,
    testId,
    disabled: fieldDisabled,
    ...options
  } = props

  // internal type used to resolve the connected Form Field to a `BulkFieldValue<string>` instead of a dynamically derived type,
  // not resolved inside the reusable component
  type PartialForm = Record<string, FormSingleField<RequiredFormField>>

  const { field, errors } = useFormField<FIELD_NAME, PartialForm, BulkLoadsError>(
    name,
    options as UseFormFieldOptions<PartialForm, BulkLoadsError>,
  )

  const invalidLoads = errors.find(e => e.id === 'invalidLoadsError')?.invalidLoads

  const { visible, value: fieldValue, status } = field

  const texts = useTexts()
  const { exact, partial } = useSplitAndSortLoadsByType(fieldValue)
  const { open: dialogOpen, onClose: onCloseDialog, onOpen: onOpenDialog } = useDialogState()

  // Resolve the field meta states to improve code readability
  const fieldInvalid = status === 'invalid'
  const fieldIndeterminate = status === 'indeterminate'
  const fieldHasError = fieldInvalid || (fieldIndeterminate && errors.length > 0)

  const errorText = fieldHasError ? (errors[0]?.message ?? 'Unknown Error') : undefined

  const loadsOptions = useAddLoadsOptions(fieldValue)
  const reachedMaxLoads = useReachedMaxLoads(fieldValue, maxLoadsForOrder)
  const addablePartialLoads = useComputeAddablePartialLoads(
    partial,
    fieldValue.loadsDistribution,
    maxLoadsForOrder,
  )

  const deleteAllLoadsDisabled = useMemo(() => {
    return Object.values(fieldValue.loads).filter(t => t.status !== 'deleted').length === 0
  }, [fieldValue.loads])

  const actions = useActions<FIELD_NAME, FIELDS>(name, onCloseDialog)
  const disableTooltipReason = reachedMaxLoads ? 'limitReached' : undefined

  if (!visible) return null

  return (
    <Stack spacing={2} data-testid={testId ?? 'bulk-loads_root'}>
      <FieldHeader
        label={label}
        action1={
          <Tooltip
            title={
              fieldDisabled
                ? texts.fieldDisabled
                : texts.addLoadTooltip(disableTooltipReason, maxLoadsForOrder)
            }
          >
            {/* A disabled element does not fire events. */}
            {/* Tooltip needs to listen to the child element's events to display the title. */}
            <span>
              <IconButton
                onClick={onOpenDialog}
                disabled={reachedMaxLoads || fieldDisabled}
                data-testid="bulk-loads_add-btn"
                data-trackid="bulk-loads_add-btn"
              >
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        }
        action2={
          <Menu
            onDeleteAllLoads={actions.onDeleteAllLoads}
            deleteAllLoadsDisabled={deleteAllLoadsDisabled}
            disabled={fieldDisabled}
          />
        }
      />
      <ExactList
        loads={exact}
        onDeleteLoad={actions.onDeleteLoad}
        onUpdateLoad={actions.onUpdateLoad}
        invalidLoads={invalidLoads}
        ordersCount={fieldValue.affectedOrdersCount}
      />
      <PartialList
        loads={partial}
        onDeleteLoad={actions.onDeleteLoad}
        onAddLoad={actions.onAddLoad}
        ordersCount={fieldValue.affectedOrdersCount}
        addableLoads={addablePartialLoads}
        maxLoadsForOrder={maxLoadsForOrder}
        disabled={fieldDisabled}
      />
      <Box hidden={fieldHasError ? !errorText : !helperText} paddingBottom={2}>
        <FormHelperText error={fieldHasError}>
          {fieldHasError ? errorText : helperText}
        </FormHelperText>
      </Box>
      <Dialog open={dialogOpen} onClose={onCloseDialog} transitionDuration={{ exit: 100 }}>
        <AddLoad
          title={addLoadLabel}
          options={loadsOptions}
          onAddTag={actions.onAddLoadAndCloseDialog}
          onClose={onCloseDialog}
          ordersCount={fieldValue.affectedOrdersCount}
        />
      </Dialog>
    </Stack>
  )
}
