import { useState } from 'react'

import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    add: translate({ id: 'global.add' }),
    orders: translate({ id: 'orders.list.yourOrders' }),
    addOrder: translate({ id: 'orders.list.createNewOrder' }),
    clearAll: translate({ id: 'orders.list.filter.clearAll' }),
    orderType: translate({ id: 'orders.list.filter.orderType' }),
    orderStates: translate({ id: 'orders.list.filter.orderStates' }),
    importOrders: translate({ id: 'orders.list.importOrders' }),
    filterTypeAny: translate({ id: 'orders.list.filter.filterType.any' }),
    filterExpiring: translate({ id: 'orders.list.filter.expiring' }),
    filterViolated: translate({ id: 'orders.list.filter.violated' }),
    filterPod: translate({ id: 'orders.list.filter.pod' }),
    filterPlaceholder: translate({ id: 'orders.form.filterPlaceholder' }),
    filterTypeAssigned: translate({ id: 'orders.list.filter.filterType.assigned' }),
    filterTypeUnassigned: translate({ id: 'orders.list.filter.filterType.unassigned' }),
    filterStateAll: translate({ id: 'orders.list.filter.filterState.all' }),
    filterStateToDo: translate({ id: 'orders.list.filter.filterState.toDo' }),
    filterStateCompleted: translate({ id: 'orders.list.filter.filterState.completed' }),
    filterStateUndeclared: translate({ id: 'orders.list.filter.filterState.undeclared' }),
    filterStateNotCompleted: translate({ id: 'orders.list.filter.filterState.notCompleted' }),
    sortBy: (field: string) => translate({ id: 'orders.list.sorter.sortBy', values: { field } }),
    sortByEta: translate({ id: 'orders.list.sorter.sortBy.eta' }),
    sortByName: translate({ id: 'orders.list.sorter.sortBy.name' }),
    sortOrderAscending: translate({ id: 'orders.list.sorter.sortOrder.ascending' }),
    sortOrderDescending: translate({ id: 'orders.list.sorter.sortOrder.descending' }),
    groupBy: translate({ id: 'orders.list.sorter.groupBy' }),
    groupByNone: translate({ id: 'orders.list.sorter.groupBy.none' }),
    groupByRoute: translate({ id: 'orders.list.sorter.groupBy.route' }),

    disabledCreateTooltipSubtitle: (isSimulation: boolean, count: number): string => {
      const id = isSimulation
        ? 'orders.list.createNewOrder.disabled.simulations.subtitle'
        : 'orders.list.createNewOrder.disabled.operations.subtitle'

      return translate({ id, values: { count } })
    },

    disabledCreateTooltipTitle: (isSimulation: boolean): string => {
      const id = isSimulation
        ? 'orders.list.createNewOrder.disabled.simulations.title'
        : 'orders.list.createNewOrder.disabled.operations.title'

      return translate({ id })
    },
  }))

  return api
}
