// valtio atom to store scheduler columns data
// this atom will replace the recoil one to support the sorting for the columns

import type { SchedulerColumnId } from './types'

import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { proxy, snapshot, subscribe } from 'valtio'

import { selectRoutesLoads } from '@/features/domain/scheduler'

const dynamicLoadPrefix = 'dynamic-load-'

export const LATEST_VERSION = 4
export const DEFAULT_COLUMN_SECTION_WIDTH = 180

export interface SchedulerColumn {
  id: SchedulerColumnId
  width: number | string
  active: boolean
  static: boolean
  hidden: boolean
}

export type SchedulerColumns = SchedulerColumn[]

interface SchedulerColumnAtom {
  lastWidthBeforeCollapseOrExpand: number
  columnsSectionWidth?: number
  columns: SchedulerColumns
  version: number
}

// ------------------------------------
// Default values
// ------------------------------------

export const defaultColumnWidth: Record<SchedulerColumnId, number> = {
  vehicleAvatar: 42,
  routeLock: 24,
  routeVisible: 24,
  dateAsString: 100,
  vehicleName: 100,
  driverName: 100,
  warnings: 60,
  orders: 60,
  stopsCount: 60,
  approvedTime: 90,
  totalTime: 90,
  approvedMileage: 90,
  distanceLeft: 90,
  timeLeft: 90,
  liveEta: 80,
  ordersExecuted: 80,
  ordersExecutedDone: 80,
  ordersExecutedReschedule: 80,
  ordersExecutedUndeclared: 80,
  ordersToDo: 80,
  routeCost: 80,
  routeStart: 60,
  routeEnd: 60,

  [dynamicLoadPrefix]: 60,
}

// Use record to ensure every SchedulerColumnId is set
// Array will not provide this feature
export const defaultColumnsSorting: Record<SchedulerColumnId, 1> = {
  vehicleAvatar: 1,
  routeLock: 1,
  routeVisible: 1,
  vehicleName: 1,
  dateAsString: 1,
  driverName: 1,
  warnings: 1,
  orders: 1,
  stopsCount: 1,
  approvedTime: 1,
  totalTime: 1,
  approvedMileage: 1,
  distanceLeft: 1,
  timeLeft: 1,
  liveEta: 1,
  ordersExecuted: 1,
  ordersExecutedDone: 1,
  ordersExecutedReschedule: 1,
  ordersExecutedUndeclared: 1,
  ordersToDo: 1,
  routeCost: 1,
  routeStart: 1,
  routeEnd: 1,
}

const staticColumnIds: SchedulerColumnId[] = ['vehicleAvatar', 'routeLock', 'routeVisible']
const hiddenColumnIds: SchedulerColumnId[] = []
const defaultActiveColumnIds: SchedulerColumnId[] = [
  'vehicleAvatar',
  'routeLock',
  'routeVisible',

  // big
  'vehicleName',
  'driverName',

  // small
  'warnings',
]

export function getInitialSchedulerColumn() {
  const columns = Object.keys(defaultColumnsSorting).map<SchedulerColumn>(id => {
    const columnId = id as SchedulerColumnId

    return {
      id: columnId,
      width: defaultColumnWidth[id],
      active: defaultActiveColumnIds.includes(columnId),
      static: staticColumnIds.includes(columnId),
      hidden: hiddenColumnIds.includes(columnId),
    }
  })

  storeData({
    columns: columns,
    version: LATEST_VERSION,
    columnsSectionWidth: DEFAULT_COLUMN_SECTION_WIDTH,
    lastWidthBeforeCollapseOrExpand: DEFAULT_COLUMN_SECTION_WIDTH,
  })

  return columns
}

// ------------------------------------
// Restore from Local Storage
// ------------------------------------

const localStorageId = 'routemanager/v3/atoms/scheduler/columns'
const v2LocalStorageId = 'routemanager/v1/atoms/scheduler/columns'
const recoilActiveColumnStorageId = 'routemanager/v1/atoms/local/scheduler/activeColumns'

/**
 * Recursive function that call itself to upgrade the stored data to the latest version
 * @param atom stored data
 * @returns latest version of the data
 */
function upgradeToLatestVersion(atom: SchedulerColumnAtom): SchedulerColumnAtom {
  const { version, columns, columnsSectionWidth = DEFAULT_COLUMN_SECTION_WIDTH } = atom

  // version was added later on, so there could be the case version === undefined
  if (!version) {
    const addedColumnsIds = ['routeCost'] as SchedulerColumnId[]
    const newColumns = addedColumnsIds.map<SchedulerColumn>(id => {
      const columnId = id as SchedulerColumnId

      return {
        id: columnId,
        width: defaultColumnWidth[id],
        active: defaultActiveColumnIds.includes(columnId),
        static: staticColumnIds.includes(columnId),
        hidden: hiddenColumnIds.includes(columnId),
      }
    })

    return upgradeToLatestVersion({
      columns: [...columns, ...newColumns],
      version: 1,
      columnsSectionWidth,
      lastWidthBeforeCollapseOrExpand: columnsSectionWidth,
    })
  }

  if (version === 1) {
    const removeColumnsIds = ['loads']
    const newColumns = columns.filter(column => !removeColumnsIds.includes(column.id))

    return upgradeToLatestVersion({
      columns: newColumns,
      version: 2,
      columnsSectionWidth,
      lastWidthBeforeCollapseOrExpand: columnsSectionWidth,
    })
  }

  if (version === 2) {
    const addedColumnsIds = ['routeStart', 'routeEnd'] as SchedulerColumnId[]
    const newColumns = addedColumnsIds.map<SchedulerColumn>(id => {
      const columnId = id as SchedulerColumnId

      return {
        id: columnId,
        width: defaultColumnWidth[id],
        active: defaultActiveColumnIds.includes(columnId),
        static: staticColumnIds.includes(columnId),
        hidden: hiddenColumnIds.includes(columnId),
      }
    })

    return upgradeToLatestVersion({
      columns: [...columns, ...newColumns],
      version: 3,
      columnsSectionWidth,
      lastWidthBeforeCollapseOrExpand: columnsSectionWidth,
    })
  }

  if (version === 3) {
    return upgradeToLatestVersion({
      columns,
      version: 4,
      columnsSectionWidth,
      lastWidthBeforeCollapseOrExpand: columnsSectionWidth,
    })
  }

  if (version === LATEST_VERSION) {
    // at last version update the localstorage
    storeData(atom)
    return atom
  }

  throw new Error(`upgradeToLatestVersion - unexpected version: ${version}`)
}

function tryToMigrationSchedulerColumns() {
  const v2Columns = JSON.parse(localStorage.getItem(v2LocalStorageId) ?? 'null') as {
    columns: { id: string; width: number; active: boolean; static: boolean; hidden: boolean }[]
  } | null

  const v3Columns = JSON.parse(localStorage.getItem(localStorageId) ?? 'null')

  if (v3Columns || !v2Columns) return

  const columns = v2Columns.columns.map<SchedulerColumn>(column => {
    const columnId = column.id as SchedulerColumnId

    return {
      id: columnId,
      width: column.width,
      active: column.active,
      static: column.static,
      hidden: column.hidden,
    }
  })

  storeData({
    columns: columns,
    version: LATEST_VERSION,
    columnsSectionWidth: undefined,
    lastWidthBeforeCollapseOrExpand: -1,
  })

  return columns
}

function getInitialColumns(): SchedulerColumns {
  const migratedColumns = tryToMigrationSchedulerColumns()
  if (migratedColumns) {
    return migratedColumns
  }

  const storedJson = localStorage.getItem(localStorageId)
  if (storedJson) {
    const parsedAtom = upgradeToLatestVersion(JSON.parse(storedJson) as SchedulerColumnAtom)
    return parsedAtom.columns
  }

  const oldRecoilData = localStorage.getItem(recoilActiveColumnStorageId)
  if (!!oldRecoilData) {
    localStorage.removeItem(recoilActiveColumnStorageId)
    return getColumnsFromRecoilOldData(JSON.parse(oldRecoilData) as SchedulerColumnId[])
  }

  return getInitialSchedulerColumn()
}

function getInitialColumnsWidth() {
  const v2Columns = JSON.parse(localStorage.getItem(v2LocalStorageId) ?? 'null')
  const v3Columns = JSON.parse(localStorage.getItem(localStorageId) ?? 'null')

  if (!v3Columns && v2Columns) return

  const storedJson = localStorage.getItem(localStorageId)
  if (storedJson) {
    const parsedAtom = JSON.parse(storedJson) as SchedulerColumnAtom
    return parsedAtom.columnsSectionWidth
  }

  return DEFAULT_COLUMN_SECTION_WIDTH
}

function getColumnsFromRecoilOldData(oldActiveColumnIds: SchedulerColumnId[]) {
  const columns = Object.keys(defaultColumnsSorting).map<SchedulerColumn>(id => {
    const columnId = id as SchedulerColumnId

    const storageId = `routemanager/v1/atoms/local/scheduler/columnsWidthFamily@${columnId}`
    const storedValue = localStorage.getItem(storageId)
    const parsedWidth = !!storedValue ? (JSON.parse(storedValue) as number) : -1
    const width = parsedWidth > 0 ? parsedWidth : defaultColumnWidth[id]

    localStorage.removeItem(storageId)

    return {
      id: columnId,
      width,
      active: oldActiveColumnIds.includes(columnId),
      static: staticColumnIds.includes(columnId),
      hidden: hiddenColumnIds.includes(columnId),
    }
  })

  storeData({
    columns,
    version: LATEST_VERSION,
    columnsSectionWidth: DEFAULT_COLUMN_SECTION_WIDTH,
    lastWidthBeforeCollapseOrExpand: DEFAULT_COLUMN_SECTION_WIDTH,
  })
  return columns
}

// ------------------------------------
// SchedulerColumn settings atom
// ------------------------------------

const createDefaultSchedulerColumn = (): SchedulerColumnAtom => {
  const initialColumnsWidth = getInitialColumnsWidth()
  return {
    lastWidthBeforeCollapseOrExpand: initialColumnsWidth ?? -1,
    columnsSectionWidth: initialColumnsWidth,
    columns: getInitialColumns(),
    version: LATEST_VERSION,
  }
}

export const schedulerColumnAtom = proxy<SchedulerColumnAtom>(createDefaultSchedulerColumn())

// ------------------------------------
// Write to Local Storage
// ------------------------------------

subscribe(schedulerColumnAtom, () => {
  storeData(schedulerColumnAtom)
})

// ------------------------------------
// Write functions
// ------------------------------------

type SetSchedulerColumn = (prev: SchedulerColumns) => SchedulerColumns
type SetSchedulerColumnParam = SetSchedulerColumn | SchedulerColumns | 'reset'

export function resetSchedulerColumn() {
  schedulerColumnAtom.columns = getInitialSchedulerColumn()
}

export function setSchedulerColumns(valueOrFunc: SetSchedulerColumnParam) {
  if (valueOrFunc === 'reset') {
    resetSchedulerColumn()
    return schedulerColumnAtom
  }

  // callback with prev value
  if (typeof valueOrFunc === 'function') {
    Object.assign(schedulerColumnAtom.columns, valueOrFunc(schedulerColumnAtom.columns))
  } else {
    schedulerColumnAtom.columns = valueOrFunc
  }

  return schedulerColumnAtom
}

export type UpdateColumnWidthPayload = {
  id: SchedulerColumnId
  width: number
}

export function useUpdateColumnWidth() {
  return useCallback((payload: UpdateColumnWidthPayload) => {
    setSchedulerColumns(prevColumns => {
      return prevColumns.map(col => {
        if (col.id === payload.id) {
          return {
            ...col,
            width: payload.width,
          }
        }
        return col
      })
    })
  }, [])
}

function storeData(atom: SchedulerColumnAtom) {
  localStorage.setItem(localStorageId, JSON.stringify(atom))
}

export function useSchedulerColumns() {
  const calendarizedLoads = useSelector(selectRoutesLoads)
  const storedColumns = snapshot(schedulerColumnAtom).columns

  return useMemo(() => {
    const calendarizedLoadsSet = new Set(calendarizedLoads)
    // filter all the dynamic loads column that are not active and not used in the current routes
    const columns = storedColumns.filter(col => {
      if (!col.id.startsWith('dynamic-load-') || col.active) return true

      const loadName = col.id.replace('dynamic-load-', '')
      return calendarizedLoadsSet.has(loadName)
    })

    const columnIds = new Set([...columns.map(col => col.id)])

    for (const load of calendarizedLoads) {
      const loadColumn = createLoadColumn(load)
      // skip if column is already in the atom
      if (columnIds.has(loadColumn.id)) continue

      columns.push(loadColumn)
    }

    return columns
  }, [calendarizedLoads, storedColumns])
}

export function updateSchedulerColumnsSectionWidth(width: number) {
  schedulerColumnAtom.columnsSectionWidth = width
}

function createLoadColumn(loadName: string): SchedulerColumn {
  return {
    id: `dynamic-load-${loadName}`,
    width: defaultColumnWidth[dynamicLoadPrefix],
    active: false,
    static: false,
    hidden: false,
  }
}

export function getDynamicLoadsPrefix() {
  return 'dynamic-load-'
}

export const getSchedulerColumnAtom = (immutable: boolean = false) => {
  return immutable ? snapshot(schedulerColumnAtom) : schedulerColumnAtom
}
