import { type SchedulerProConfig } from '@bryntum/schedulerpro'

import { applicationModeAtom, setMainSelection, loadingStateAtom } from '@/atoms'
import { ControlledNavigateFunction } from '@/routing'
import { selectUserConfiguration } from '@/features/domain/user'
import { computeReadonlyStatus } from '@/hooks'
import { store } from '@/store'

import { toggleRouteVisibility } from './utils/toggleRouteVisibility'
import { editVehicleException } from './utils/editVehicleException'
import { editDriverAssignment } from './utils/editDriverAssignment'
import { toggleRouteLock } from './toggleRouteLock'

export function createOnCellClick(
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
): SchedulerProConfig['onCellClick'] {
  return clickEvent => {
    const { column, record } = clickEvent

    if (record.isGroupHeader) return

    const state = store.getState()

    const { planType, userType } = selectUserConfiguration(state)

    const readonlyStatus = computeReadonlyStatus(
      applicationModeAtom.isOffline,
      applicationModeAtom.mode,
      planType,
      userType,
      applicationModeAtom.transactionMode === 'external',
    )

    const reschedulingInProgress = loadingStateAtom.isRescheduling

    switch (column.field) {
      case 'liveEta':
      case 'vehicleName':
      case 'dateAsString': {
        if (reschedulingInProgress) return

        const unifiedVehicleId = record.getData(
          'unifiedVehicleId',
        ) as uui.domain.client.rm.SchedulerResource['unifiedVehicleId']

        const dateAsString = record.getData(
          'dateAsString',
        ) as uui.domain.client.rm.SchedulerResource['dateAsString']

        editVehicleException(unifiedVehicleId, dateAsString, readonlyStatus, navigate)
        break
      }

      case 'driverName': {
        if (reschedulingInProgress) return

        const dateAsString = record.getData(
          'dateAsString',
        ) as uui.domain.client.rm.SchedulerResource['dateAsString']

        editDriverAssignment(dateAsString, readonlyStatus, navigate)
        break
      }

      case 'routeLock': {
        if (reschedulingInProgress) return

        const vehicleId = record.getData(
          'vehicleId',
        ) as uui.domain.client.rm.SchedulerResource['vehicleId']

        const dateAsString = record.getData(
          'dateAsString',
        ) as uui.domain.client.rm.SchedulerResource['dateAsString']

        toggleRouteLock(dateAsString, vehicleId)
        break
      }

      case 'routeVisible': {
        if (reschedulingInProgress) return

        const id = record.getData('id') as uui.domain.client.rm.SchedulerResource['id']

        const routeVisible = record.getData(
          'routeVisible',
        ) as uui.domain.client.rm.SchedulerResource['routeVisible']

        // If the route is empty, we don't want to toggle the visibility
        if (routeVisible === 'empty') return

        toggleRouteVisibility(id, routeVisible)
        break
      }

      case 'ordersExecuted': {
        const ordersExecuted = record.getData(
          'ordersExecuted',
        ) as uui.domain.client.rm.SchedulerResource['ordersExecuted']

        // If the number of orders is 0, we don't want to select anything
        if (ordersExecuted.value.length === 0) return

        setMainSelection('orderSteps', ordersExecuted.value)
        break
      }

      case 'ordersExecutedDone': {
        const ordersExecutedDone = record.getData(
          'ordersExecutedDone',
        ) as uui.domain.client.rm.SchedulerResource['ordersExecutedDone']

        // If the number of orders is 0, we don't want to select anything
        if (ordersExecutedDone.value.length === 0) return

        setMainSelection('orderSteps', ordersExecutedDone.value)
        break
      }

      case 'ordersExecutedReschedule': {
        const ordersExecutedReschedule = record.getData(
          'ordersExecutedReschedule',
        ) as uui.domain.client.rm.SchedulerResource['ordersExecutedReschedule']

        // If the number of orders is 0, we don't want to select anything
        if (ordersExecutedReschedule.value.length === 0) return

        setMainSelection('orderSteps', ordersExecutedReschedule.value)
        break
      }

      case 'ordersToDo': {
        const ordersToDo = record.getData(
          'ordersToDo',
        ) as uui.domain.client.rm.SchedulerResource['ordersToDo']

        // If the number of orders is 0, we don't want to select anything
        if (ordersToDo.value.length === 0) return

        setMainSelection('orderSteps', ordersToDo.value)
        break
      }

      case 'ordersExecutedUndeclared': {
        const ordersExecutedUndeclared = record.getData(
          'ordersExecutedUndeclared',
        ) as uui.domain.client.rm.SchedulerResource['ordersExecutedUndeclared']

        // If the number of orders is 0, we don't want to select anything
        if (ordersExecutedUndeclared.value.length === 0) return

        setMainSelection('orderSteps', ordersExecutedUndeclared.value)
        break
      }
    }
  }
}
