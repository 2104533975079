import { format, setDay, setDate, setMonth, getDay, getDate, getMonth } from 'date-fns/esm'

import { isValidStringDate } from './time'
import { parseDateString } from './timezone'

const WEEK_DAY_TO_INT = {
  sun: 0,
  mon: 1,
  tue: 2,
  wed: 3,
  thu: 4,
  fri: 5,
  sat: 6,
}
const WEEK_INT_TO_DAY = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat',
}

const recurrenceTypeByIdLength = {
  9: 'BEFORE',
  8: 'DATE',
  4: 'ANNUAL',
  3: 'WEEKLY',
  2: 'MONTHLY',
}

export const RECURRENCE_TYPES_PRIORITY = {
  DATE: 1,
  ANNUAL: 2,
  MONTHLY: 3,
  WEEKLY: 4,
  BEFORE: 5,
  any: 6,
}

const EPOCH: Date = new Date(1970, 0, 1)

export const recurrenceToString =
  (translate: uui.domain.intl.Translate) =>
  ({ type, value }: uui.domain.rm.Recurrence): string =>
    getRecurrenceLabel(type, value, translate)

export const getRecurrenceLabel = (
  type: uui.domain.rm.RecurrenceTypes,
  date: Date | null,
  translate: uui.domain.intl.Translate,
): string => {
  if (type === 'any') {
    return translate({ id: 'global.recurrence.format.any' })
  }

  if (!date) {
    throw new Error('`getRecurrenceLabel` expect a valid date for every type but ANY')
  }

  switch (type) {
    case 'DATE':
      return translate({
        id: 'global.recurrence.format.date',
        values: { recurrence: `${format(date, 'MMMM dd, yyyy')}` },
      })
    case 'WEEKLY':
      return translate({
        id: 'global.recurrence.format.weekly',
        values: { recurrence: `${format(date, 'EEEE')}` },
      })
    case 'MONTHLY':
      return translate({
        id: 'global.recurrence.format.monthly',
        values: { recurrence: `${format(date, 'do')}` },
      })
    case 'ANNUAL':
      return translate({
        id: 'global.recurrence.format.annually',
        values: { recurrence: `${format(date, 'MMMM dd')}` },
      })
    case 'BEFORE':
      return translate({
        id: 'global.recurrence.format.before',
        values: { recurrence: `${format(date, 'MMMM dd, yyyy')}` },
      })
    default:
      return translate({ id: 'global.recurrence.format.unknown' })
  }
}

type RecurrenceParser = (str: string) => Date | null
const parseRecurrenceByType: Record<uui.domain.rm.RecurrenceTypes, RecurrenceParser> = {
  any: (str: string): any => str,

  BEFORE: (str: string): Date | null => {
    const date = str.slice(1)
    return str.charAt(0) === 'b' && isValidStringDate(date) ? (parseDateString(date) ?? null) : null
  },

  DATE: (str: string): Date | null =>
    isValidStringDate(str) ? (parseDateString(str) ?? null) : null,

  ANNUAL: (str: string): Date => {
    const mm = parseInt(str.substr(0, 2))
    const dd = parseInt(str.substr(2))
    return setMonth(setDate(EPOCH, dd), mm - 1)
  },

  WEEKLY: (str: string): Date | null => {
    const date = setDay(EPOCH, WEEK_DAY_TO_INT[str])
    return WEEK_DAY_TO_INT.hasOwnProperty(str) ? date : null
  },

  MONTHLY: (str: string): Date | null => {
    const d = parseInt(str)
    if (isFinite(d) && d <= 31) {
      return setDate(EPOCH, d)
    }
    return null
  },

  TODAY: (_str: string) => null,
}
const parseRecurrenceCache = new Map<string, uui.domain.rm.Recurrence | null>()
export const parseRecurrence = (recurrence: string): uui.domain.rm.Recurrence | null => {
  if (typeof recurrence !== 'string') return null

  if (parseRecurrenceCache.has(recurrence)) {
    return parseRecurrenceCache.get(recurrence) ?? null
  }

  const recurrenceType: uui.domain.rm.RecurrenceTypes | undefined =
    recurrenceTypeByIdLength[recurrence.length]

  if (recurrence.toLowerCase() === 'any') {
    const toReturn = {
      type: 'any' as uui.domain.rm.RecurrenceTypes,
      source: 'any',
      value: null,
      priority: RECURRENCE_TYPES_PRIORITY['any'],
    }
    parseRecurrenceCache.set(recurrence, toReturn)
    return toReturn
  }

  if (!recurrenceType) {
    console.error(`Failed to parse recurrence, no matching type found ${recurrence}`)
    parseRecurrenceCache.set(recurrence, null)
    return null
  }

  const priority: number =
    typeof RECURRENCE_TYPES_PRIORITY[recurrenceType] === 'number'
      ? RECURRENCE_TYPES_PRIORITY[recurrenceType]
      : Number.MAX_SAFE_INTEGER

  const parser = parseRecurrenceByType[recurrenceType]
  const value = parser(recurrence)

  if (!value) {
    console.error(
      `Failed to parse recurrence, string (${recurrence}) ` +
        `is invalid for the matching type (${recurrenceType})`,
    )
    parseRecurrenceCache.set(recurrence, null)
    return null
  }

  const toReturn = { type: recurrenceType, value, source: recurrence, priority }
  parseRecurrenceCache.set(recurrence, toReturn)
  return toReturn
}

const dateToString = (value: Date) => {
  const date = getDate(value)
  return date < 10 ? `0${date}` : `${date}`
}

const monthToString = (value: Date) => {
  const month = getMonth(value) + 1
  return month < 10 ? `0${month}` : `${month}`
}

export const composeRecurrenceId = (
  type: uui.domain.rm.RecurrenceTypes,
  value: Date | null,
): string => {
  switch (type) {
    case 'DATE':
      return value ? format(value, 'yyyyMMdd') : ''

    case 'ANNUAL':
      return value ? `${monthToString(value)}${dateToString(value)}` : ''

    case 'MONTHLY':
      return value ? dateToString(value) : ''

    case 'WEEKLY':
      return value ? WEEK_INT_TO_DAY[getDay(value)] : ''

    case 'BEFORE':
      return value ? `b${format(value, 'yyyyMMdd')}` : ''

    default:
    case 'any':
      return 'any'
  }
}

// TODO: missing flow typing annotation in this file

export const composeRecurrencesNotEnabled = (
  recurrences: Partial<Record<uui.domain.rm.RecurrenceTypes, unknown>>,
): uui.domain.rm.RecurrencesNotEnabledType => {
  const keys = Object.keys(recurrences) as uui.domain.rm.RecurrenceTypes[]
  return keys.reduce<uui.domain.rm.RecurrencesNotEnabledType>(
    (recurrenceAcc, key) => {
      const parsedRecurrence: uui.domain.rm.Recurrence | null = parseRecurrence(key)

      if (parsedRecurrence) {
        const { type, value } = parsedRecurrence

        if (value) {
          const day = getDay(value)
          const month = getMonth(value)
          const date = getDate(value)

          switch (type) {
            case 'WEEKLY':
              recurrenceAcc.weekdaysDisabled.push(day - 1)
              break

            case 'MONTHLY':
              recurrenceAcc.daysDisabled.push(date)
              break

            case 'DATE':
              recurrenceAcc.datesDisabled.push(value)
              break

            case 'ANNUAL':
              const disabledBymonth = recurrenceAcc.monthsDaysDisabled[month]
              recurrenceAcc.monthsDaysDisabled[month] = disabledBymonth
                ? [...disabledBymonth, date]
                : [date]
              break
          }
        }
      }

      return recurrenceAcc
    },
    {
      monthsDaysDisabled: [],
      daysDisabled: [],
      datesDisabled: [],
      weekdaysDisabled: [],
    },
  )
}

export const getRecurrencesSorted = (
  inputDates: string[],
  ascendent: boolean = true,
  normalDayFirst: boolean = false,
): uui.domain.rm.Recurrences => {
  const directionModifier = ascendent ? 1 : -1
  const dates = [...inputDates]

  let normalDay: uui.domain.rm.Recurrence | null = null

  if (normalDayFirst) {
    const index = dates.findIndex(date => date === 'any')
    if (index > -1) {
      normalDay = parseRecurrence(dates[index])
      dates.splice(index, 1)
    }
  }

  const result = dates
    .reduce<uui.domain.rm.Recurrence[]>((list, date) => {
      const rec = parseRecurrence(date)
      if (rec) list.push(rec)

      return list
    }, [])
    .sort((a, b) => {
      if (a.type === b.type) {
        if (a.value === b.value) return 0
        if (a.value && b.value) {
          return (a.value < b.value ? -1 : 1) * directionModifier
        }
        if (a.value && !b.value) return -1
        if (!a.value && b.value) return 1
      } else {
        return (a.priority > b.priority ? 1 : -1) * directionModifier
      }

      return 0
    })

  if (normalDay) {
    result.unshift(normalDay)
  }

  return result
}

export const recurrenceMatchesDate = (
  recurrence: uui.domain.rm.Recurrence,
  rawDate: Date | string,
): boolean => {
  if (!recurrence || !rawDate || (typeof rawDate === 'string' && !rawDate.length)) {
    return false
  }

  const { type, value } = recurrence

  // TODO: need to validate the date string
  const date = typeof rawDate === 'string' ? parseDateString(rawDate) : rawDate

  if (!date) return false
  //if (!isValid(date)) return false

  switch (type) {
    case 'DATE':
      return (
        !!value &&
        date.getDate() === value.getDate() &&
        date.getMonth() === value.getMonth() &&
        date.getFullYear() === value.getFullYear()
      )
    case 'ANNUAL':
      return value ? format(date, 'MMdd') === format(value, 'MMdd') : false
    case 'MONTHLY':
      return !!value && getDate(date) === getDate(value)
    case 'WEEKLY':
      return !!value && getDay(date) === getDay(value)
    case 'BEFORE':
      return !!value && format(value, 'yyyyMMdd') > format(date, 'yyyyMMdd')
    case 'any':
      return true

    default:
      return false
  }
}

export const getMatchingRecurrenceByDate = (dateAsString: string, dates: string[]): string => {
  const filtered = getRecurrencesSorted(dates).filter(r => recurrenceMatchesDate(r, dateAsString))

  return filtered.length > 0 ? filtered[0].source : 'any'
}

export const getAllMatchingRecurrencesByDate = (
  dateAsString: string,
  dates: string[],
): uui.domain.rm.Recurrences => {
  const filtered = getRecurrencesSorted(dates).filter(r => recurrenceMatchesDate(r, dateAsString))

  return filtered
}

export const getRecurrenceDescriptionMap =
  (translate: uui.domain.intl.Translate) => (): Record<uui.domain.rm.RecurrenceTypes, string> => {
    return {
      any: translate({ id: 'global.recurrence.description.any' }),
      DATE: translate({
        id: 'global.recurrence.description.date',
      }),
      ANNUAL: translate({
        id: 'global.recurrence.description.annual',
      }),
      MONTHLY: translate({
        id: 'global.recurrence.description.monthly',
      }),
      WEEKLY: translate({
        id: 'global.recurrence.description.weekly',
      }),
      BEFORE: translate({
        id: 'global.recurrence.description.before',
      }),
      TODAY: translate({
        id: 'global.recurrence.description.today',
      }),
    }
  }
