import type { MainSelection } from '../typings'

import { useEffect, useState, useRef } from 'react'

import { useAllMainSelection } from '@/atoms'
import { resetAllMainSelection } from '../write/resetAllMainSelection'

type NavigoSelection = Partial<Record<keyof MainSelection, string[]>>

const emptyObj = {}
const emptyArray = []
export const useNavigoSelection = () => {
  const [{ depots, places, routes, devices, drivers, orderSteps, breadcrumbs, unifiedVehicles }] =
    useAllMainSelection()

  const [selection, setSelection] = useState<NavigoSelection>(emptyObj)
  const [selectedCategories, setSelectedCategories] = useState<(keyof MainSelection)[]>(emptyArray)

  const selectedCategoriesRef = useRef(selectedCategories)

  useEffect(() => {
    selectedCategoriesRef.current = selectedCategories
  }, [selectedCategories])

  useEffect(() => {
    const next: NavigoSelection = {}

    if (breadcrumbs.length > 0) next.breadcrumbs = Array.from(breadcrumbs)
    if (depots.length > 0) next.depots = Array.from(depots)
    if (devices.length > 0) next.devices = Array.from(devices)
    if (drivers.length > 0) next.drivers = Array.from(drivers)
    if (orderSteps.length > 0) next.orderSteps = Array.from(orderSteps)
    if (places.length > 0) next.places = Array.from(places)
    if (routes.length > 0) next.routes = Array.from(routes)
    if (unifiedVehicles.length > 0) next.unifiedVehicles = Array.from(unifiedVehicles)

    const nextSelectedCategories = Object.keys(next).sort()

    setSelection(nextSelectedCategories.length > 0 ? next : emptyObj)
    setSelectedCategories(
      nextSelectedCategories.toString() === selectedCategoriesRef.current.toString()
        ? selectedCategoriesRef.current
        : nextSelectedCategories.length > 0
          ? (nextSelectedCategories as (keyof MainSelection)[])
          : emptyArray,
    )
  }, [unifiedVehicles, breadcrumbs, orderSteps, devices, drivers, depots, places, routes])

  return {
    selection,
    selectedCategories,
    resetSelection: resetAllMainSelection,
  }
}
