import { useMemo } from 'react'

import { useTexts } from './useTexts'

export function useBulkInfos(roadSegments: uui.domain.client.rm.SegmentException[]) {
  const texts = useTexts()

  return useMemo(() => {
    let malus = 0
    let malusPristine = true

    let additionalDrivingTimeSec = 0
    let additionalDrivingTimeSecPristine = true

    let disabledCount = 0

    let allMalus = true
    let allAdditionalDrivingTimeSec = true
    let allDisabled = true

    for (const segment of roadSegments) {
      if (malusPristine) {
        malusPristine = false
        malus = segment.malus
      } else {
        if (!Number.isNaN(malus) && malus !== segment.malus) {
          malus = NaN
        }
      }
      if (segment.malus > 0) {
        allAdditionalDrivingTimeSec = false
        allDisabled = false
      }

      if (additionalDrivingTimeSecPristine) {
        additionalDrivingTimeSecPristine = false
        additionalDrivingTimeSec = segment.additionalDrivingTimeSec
      } else {
        if (
          !Number.isNaN(additionalDrivingTimeSec) &&
          additionalDrivingTimeSec !== segment.additionalDrivingTimeSec
        ) {
          additionalDrivingTimeSec = NaN
        }
      }
      if (segment.additionalDrivingTimeSec > 0) {
        allMalus = false
        allDisabled = false
      }

      if (segment.malus === 0 && segment.additionalDrivingTimeSec === 0) {
        disabledCount += 1
        allMalus = false
        allAdditionalDrivingTimeSec = false
      }
    }

    const mixedMalus = Number.isNaN(malus)
    const mixedAdditional = Number.isNaN(additionalDrivingTimeSec)
    const mixedDisabled = disabledCount > 0 && disabledCount !== roadSegments.length

    const mixed = mixedMalus || mixedAdditional || mixedDisabled

    const avatarColor = allMalus
      ? '#fa603f'
      : allAdditionalDrivingTimeSec
        ? '#ff9500'
        : allDisabled
          ? '#c3c3c3'
          : undefined

    return {
      mixed,
      title: `${roadSegments.length} ${texts.segments}`,
      avatarColor,
      malus,
      additionalDrivingTimeSec,
    }
  }, [roadSegments, texts])
}
