import type { Props, ScrolltoIndex } from './typings'
import { Component, KeyboardEvent } from 'react'

import { clsx } from '@/utils'
import { KeyboardDirections, KeyboardEventKeys, ModifierKeys } from '../keyboardEnums'

import ListWrapper from './ListWrapper'

export interface State {
  highlightedIndex: number
}

const setHighlightedIndex =
  (highlightedIndex: number = -1) =>
  () => ({
    highlightedIndex,
  })

type NullableHTMLDivElement = HTMLDivElement | null | undefined

export default class List<T, S, E = unknown> extends Component<Props<T, S, E>, State> {
  state: State = {
    highlightedIndex: -1,
  }

  private listRef: NullableHTMLDivElement

  componentDidUpdate(prevProps: Props<T, S, E>) {
    const { visibleIndex: prevVisibleIndexes, values: prevValues } = prevProps
    const { visibleIndex, values } = this.props

    if (values !== prevValues) {
      this.setState(setHighlightedIndex(-1))
    }

    if (visibleIndex && visibleIndex !== prevVisibleIndexes) {
      this.setState(setHighlightedIndex(visibleIndex.index))
      this.scrollToIndex(visibleIndex)
    }
  }

  private clearHighlightedRow() {
    this.setState(setHighlightedIndex(-1))
  }

  private moveHighlightedRow(direction: KeyboardDirections) {
    const { values } = this.props
    const { highlightedIndex } = this.state

    if (highlightedIndex < 1 && direction === KeyboardDirections.UP) return
    if (highlightedIndex >= values.length - 1 && direction === KeyboardDirections.DOWN) {
      return
    }

    const step: number = direction === KeyboardDirections.UP ? -1 : 1
    const newIndex: number = highlightedIndex + step

    this.setState(setHighlightedIndex(newIndex))
    this.scrollToIndex({
      index: newIndex,
      direction: newIndex > highlightedIndex ? 'down' : 'up',
    })
  }

  private selectHighlightedRow() {
    const { onChanged, values } = this.props
    const { highlightedIndex } = this.state

    if (highlightedIndex < 0 || highlightedIndex >= values.length) return

    const value: T = values[highlightedIndex]
    onChanged([value])
  }

  private handleItemSelected = (item: T[], modifiers?: ModifierKeys) => {
    const { onChanged, values } = this.props

    const index: number = values.findIndex(value => item[0] === value)
    this.setState(setHighlightedIndex(index))

    onChanged(item, modifiers)
  }

  private handleItemHovered = (item?: T) => {
    const { values } = this.props
    const index: number = values.findIndex(value => item === value)
    this.setState(setHighlightedIndex(index))
  }

  private handleKeyboard = (event: KeyboardEvent<HTMLElement>) => {
    const { key, defaultPrevented } = event

    if (defaultPrevented) return
    event.preventDefault()

    switch (key) {
      case KeyboardEventKeys.ArrowDown:
        this.moveHighlightedRow(KeyboardDirections.DOWN)
        break

      case KeyboardEventKeys.ArrowUp:
        this.moveHighlightedRow(KeyboardDirections.UP)
        break

      case KeyboardEventKeys.Escape:
        this.clearHighlightedRow()
        break

      case KeyboardEventKeys.Enter:
      case KeyboardEventKeys.SpaceBar:
        this.selectHighlightedRow()
        break
    }
  }

  private setListRef = (ref: NullableHTMLDivElement): void => {
    this.listRef = ref
  }

  private getListRef = (): NullableHTMLDivElement => this.listRef

  private scrollToIndex = ({ index, direction = 'up' }: ScrolltoIndex): void => {
    const { values, rowHeight: getRowHeight } = this.props
    const list: NullableHTMLDivElement = this.getListRef()

    if (index < 0 || index > values.length - 1 || !list) {
      return
    }

    let itemTop: number
    let itemBottom: number
    if (typeof getRowHeight === 'function') {
      itemTop = values
        .slice(0, index)
        .reduce(
          (height: number, value: T, idx: number): number =>
            height + getRowHeight(value, idx, values),
          0,
        )
      itemBottom = itemTop + getRowHeight(values[index], index, values)
    } else {
      itemTop = getRowHeight * index
      itemBottom = itemTop + getRowHeight
    }

    const { scrollTop: listScrollTop, clientHeight: listHeight } = list

    const isBelowViewTop: boolean = itemTop >= listScrollTop
    const isAboveViewBottom: boolean = itemBottom <= listScrollTop + listHeight

    if (isBelowViewTop && isAboveViewBottom) return

    const deltaBottom: number = listHeight - itemBottom

    const newScrollTop: number | null | undefined =
      direction === 'up'
        ? isBelowViewTop
          ? Math.abs(deltaBottom)
          : itemTop
        : isAboveViewBottom
          ? itemTop
          : Math.abs(deltaBottom)

    if (Number.isFinite(newScrollTop)) list.scrollTop = newScrollTop
  }

  render() {
    const {
      id,
      className = '',
      style,
      values,
      selectedIds,
      disabled,
      keyboardEventDisabled,
      error,
      height,
      disableComputedHeight,
      listWrapperStyle,
      extra,
    } = this.props
    const { highlightedIndex } = this.state

    const rootClassName = clsx({
      [className]: true,
      'is-disabled': !!disabled,
    })

    return (
      <ListWrapper
        height={height}
        disableComputedHeight={disableComputedHeight}
        error={error}
        id={id}
        className={rootClassName}
        style={style}
        tabIndex={0}
        onKeyUp={disabled || keyboardEventDisabled ? undefined : this.handleKeyboard}
      >
        <div className="o-list__wrapper" style={listWrapperStyle} ref={this.setListRef}>
          {this.props.mode === 'renderProps' ? (
            this.props.render(
              values,
              selectedIds,
              highlightedIndex,
              this.handleItemSelected,
              this.handleItemHovered,
              extra,
            )
          ) : (
            <this.props.Items
              values={values}
              selectedIds={selectedIds}
              highlightedIndex={highlightedIndex}
              onChange={this.handleItemSelected}
              onHover={this.handleItemHovered}
              extra={extra}
            />
          )}
        </div>
      </ListWrapper>
    )
  }
}
