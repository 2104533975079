import type { Grid, SubGrid } from '@bryntum/schedulerpro'
import { schedulerColumnAtom } from '../../../atoms/columns'
import { schedulerInstance } from '../../../atoms/project/project'

import { MIN_AVAILABLE_SPACE_FOR_FIXED_REGIONS } from '../constants'

export function onSplitterCollapseClick(event: { source: Grid; subGrid: SubGrid; domEvent: Event }):
  | boolean
  | void {
  if (!schedulerInstance.instance) return
  if (typeof event.source.width !== 'number') return

  const { cols } = event.source.subGrids

  // If the columns are expanded, bring them back to the last valid (not collapsed/expanded) width
  const schedulerWidth =
    schedulerInstance.instance.element.querySelector('.b-grid-body-container')?.clientWidth ??
    (schedulerInstance.instance.width as number)

  if (schedulerWidth - cols.width <= MIN_AVAILABLE_SPACE_FOR_FIXED_REGIONS) {
    if (
      schedulerColumnAtom.lastWidthBeforeCollapseOrExpand === -1 ||
      cols.width === schedulerColumnAtom.lastWidthBeforeCollapseOrExpand
    ) {
      schedulerColumnAtom.lastWidthBeforeCollapseOrExpand = cols.width * 0.7
    }
    cols.width = schedulerColumnAtom.lastWidthBeforeCollapseOrExpand

    return false
  }
  // If the columns are not expanded, store the current width as the last valid width
  else {
    schedulerColumnAtom.lastWidthBeforeCollapseOrExpand = cols.width
  }

  if (!cols.collapsed) {
    cols.width = 0
  }

  return false
}
