import { useCallback, useEffect, useMemo, useState } from 'react'
import { ArrowForward, Clear } from '@mui/icons-material'
import { Button, Stack } from '@mui/material'
import { applyPatches } from 'immer'
import { Editor } from '@monaco-editor/react'

import { enableRequestLogNotifications, clearRequestLog } from '@/features/domain/demoTools'
import { domainProxy, useAppDispatch } from '@/store'

import { useTexts } from '../../../../useTexts'
import { List } from './components/List'

interface Props {
  setRequestMakerRequest: (request: string | undefined) => void
  setRequestMakerMethod: (method: string | undefined) => void
  setTab: (tab: string) => void
}
export function RequestLog(props: Props) {
  const { setRequestMakerRequest, setRequestMakerMethod, setTab } = props

  const [selectedEntryId, setSelectedEntryId] = useState<string | undefined>()
  const [entries, setEntries] = useState<uui.domain.SocketLogEntry[]>([])
  const dispatch = useAppDispatch()
  const texts = useTexts()

  const sendEnableRequestLogNotifications = useCallback(
    async (enabledStatus: boolean) => {
      const response = await dispatch(enableRequestLogNotifications(enabledStatus))

      if (enableRequestLogNotifications.rejected.match(response)) {
        throw new Error(response.payload?.message ?? 'Internal error')
      }

      if (response.payload) {
        setEntries(response.payload)
      }

      if (!selectedEntryId && response.payload && response.payload.length > 0) {
        setSelectedEntryId(response.payload[0].internalId)
      }
    },
    [dispatch, selectedEntryId],
  )

  const clearLogs = useCallback(async () => {
    const response = await dispatch(clearRequestLog())
    if (clearRequestLog.rejected.match(response)) {
      throw new Error(response.payload?.message ?? 'Internal error')
    }
    setSelectedEntryId(undefined)
  }, [dispatch])

  const openInRequestMaker = useCallback(() => {
    if (!selectedEntryId) return ''
    const selectedEntry = entries.find(entry => entry.internalId === selectedEntryId)

    if (!selectedEntry || selectedEntry.type !== 'request') return

    const requestObject = JSON.parse(selectedEntry.request)

    setRequestMakerRequest(
      requestObject.params ? JSON.stringify(requestObject.params, null, 2) : '',
    )
    setRequestMakerMethod(selectedEntry.method)
    setTab('request-maker')
  }, [setTab, selectedEntryId, entries, setRequestMakerRequest, setRequestMakerMethod])

  const formattedSelectedEntry = useMemo(() => {
    if (!selectedEntryId) return ''
    const selectedEntry = entries.find(entry => entry.internalId === selectedEntryId)

    if (!selectedEntry) return ''

    switch (selectedEntry.type) {
      case 'request':
        return {
          request: JSON.parse(selectedEntry.request),
          response: selectedEntry.response ? JSON.parse(selectedEntry.response) : null,
        }
      case 'notification':
        return JSON.parse(selectedEntry.payload)
    }
  }, [entries, selectedEntryId])

  useEffect(() => {
    // Enable request log notifications
    sendEnableRequestLogNotifications(true)

    // Subscribe to request log updates
    const unsubscribe = domainProxy.subscribeToNotifications(
      notification => {
        if (notification.notificationType !== 'requestLogUpdate') return

        setEntries(entries => {
          const newEntries = applyPatches(entries, notification.patches)

          return newEntries
        })
      },
      ['requestLogUpdate'],
    )
    return () => {
      // Disable request log notifications
      sendEnableRequestLogNotifications(false)
      unsubscribe()
    }
  }, [sendEnableRequestLogNotifications])

  return (
    <Stack spacing={3}>
      <Stack direction="row" spacing={1}>
        <Button
          variant="contained"
          startIcon={<ArrowForward />}
          onClick={openInRequestMaker}
          disabled={!selectedEntryId}
        >
          {texts.requestLog.openInRequestMaker}
        </Button>

        <Button variant="contained" color="error" startIcon={<Clear />} onClick={clearLogs}>
          {texts.requestLog.clear}
        </Button>
      </Stack>
      <Stack direction="row" maxHeight={500}>
        <List
          entries={entries.map((_item, idx) => entries[entries.length - 1 - idx])}
          selectedEntryId={selectedEntryId}
          setSelectedEntryId={setSelectedEntryId}
        />

        <Editor
          value={selectedEntryId ? JSON.stringify(formattedSelectedEntry, null, 2) : ''}
          theme="vs-dark"
          height={500}
          options={{ readOnly: true }}
          language="json"
        />
      </Stack>
    </Stack>
  )
}
