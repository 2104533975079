import { useState } from 'react'

import {
  DropdownMenu,
  DropdownMenuItem,
  DropdownTextItem,
  MenuIconTrigger,
} from '@/components/DropdownMenu'
import { IconButton } from '@/components/primitives/buttons'
import { Text } from '@/local/components'

import { useStyles } from '../../hooks/useStyles'
import { TriggerIcon } from './components/TriggerIcon'

import { useActions } from './hooks/useActions'
import { useShowSubmenu } from './hooks/useShowSubmenu'
import { useTexts } from './useTexts'

export function Lasso() {
  const [open, setOpen] = useState(false)
  const showSubmenu = useShowSubmenu()
  const actions = useActions(setOpen)
  const classes = useStyles()
  const texts = useTexts()

  const testid = 'map-controls-lasso-menu-trigger'

  return showSubmenu ? (
    <DropdownMenu
      open={open}
      setOpen={setOpen}
      trigger={
        <MenuIconTrigger
          className={classes.button}
          menuOpen={open}
          Icon={<TriggerIcon />}
          testid={testid}
        />
      }
    >
      <DropdownTextItem>
        <Text color="$paleSky" size="$h4" uppercase>
          {texts.lasso}
        </Text>
      </DropdownTextItem>

      <DropdownMenuItem onClick={actions.toggleLassoMode}>{texts.newSelection}</DropdownMenuItem>
      <DropdownMenuItem onClick={actions.toggleLassoAdditiveMode}>
        {texts.addToSelection}
      </DropdownMenuItem>
      <DropdownMenuItem onClick={actions.toggleLassoSubtractiveMode}>
        {texts.removeFromSelection}
      </DropdownMenuItem>
    </DropdownMenu>
  ) : (
    <IconButton
      className={classes.button}
      onClick={actions.toggleLassoMode}
      data-testid={testid}
      data-trackid={testid}
    >
      <TriggerIcon />
    </IconButton>
  )
}
