import type { PermissionData } from '../types'

import {
  addDays,
  addSeconds,
  eachDayOfInterval,
  isWithinInterval,
  parse,
  parseISO,
} from 'date-fns/esm'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectOrderSteps } from '@/features/domain/order'
import { selectRoutes } from '@/features/domain/route'
import { selectApprovedPlans, selectRoutePlanTrackingData } from '@/features/domain/routeplan'
import { selectRoutesInfo } from '@/features/domain/scheduler'
import { selectTerritory } from '@/features/domain/territory'
import { selectCalendarRangeInterval } from '@/features/domain/ui'
import { selectUserConfiguration } from '@/features/domain/user'
import { useHasPrivileges, useIsSimulation } from '@/hooks'

import { isRouteApproved } from '../utils/isRouteApproved'

type RoutesPermissionData = Pick<
  PermissionData,
  | 'routesInThePastCount'
  | 'approvedRoutesCount'
  | 'notEmptyRoutesCount'
  | 'gotExecutionEvents'
  | 'gotSomeValidRoutes'
  | 'allRoutesApproved'
  | 'allRoutesLocked'
  | 'someRoutesLocked'
  | 'allRoutesAvailable'
  | 'driversCount'
>
const defaultPermission: RoutesPermissionData = {
  routesInThePastCount: 0,
  approvedRoutesCount: 0,
  notEmptyRoutesCount: 0,
  gotExecutionEvents: false,
  gotSomeValidRoutes: false,
  allRoutesApproved: true,
  allRoutesAvailable: true,
  allRoutesLocked: true,
  someRoutesLocked: false,
  driversCount: 0,
}
/**
 * This hook computes every data required to compose permissions
 */
export function usePermissionsData(
  currentRoutes: uui.domain.client.rm.SchedulerRowRouteOrUnavailable[],
): PermissionData {
  const { startFromDate, startOfToday } = useSelector(selectUserConfiguration)
  const { workingDayStartSec } = useSelector(selectTerritory)
  const routePlanTrackingData = useSelector(selectRoutePlanTrackingData)
  const canExportInSimulation = useHasPrivileges(['export'], true)
  const { start, end } = useSelector(selectCalendarRangeInterval)
  const approvedPlans = useSelector(selectApprovedPlans)
  const extOrderSteps = useSelector(selectOrderSteps)
  const isSimulation = useIsSimulation()
  const isDemoOrDev = useHasPrivileges(['demo', 'dev'], false)
  const routesInfo = useSelector(selectRoutesInfo)
  const extRoutes = useSelector(selectRoutes)
  const isDev = useHasPrivileges(['dev'], true)

  const {
    licensingLimits: { horizonOpt },
  } = useSelector(selectTerritory)

  const routesData = useMemo(() => {
    const data = currentRoutes.reduce<RoutesPermissionData>(
      (acc, route) => {
        const extRoute = extRoutes[route.id]

        const routeInfo = routesInfo[route.id]
        if (!routeInfo) {
          throw new Error(`Impossible to find routeInfo for route: ${route.id}`)
        }

        if (route.locked) acc.someRoutesLocked = true
        if (!route.locked) acc.allRoutesLocked = false
        if (routeInfo.type === 'routeUnavailable') acc.allRoutesAvailable = false
        if (route.driverName !== '-') acc.driversCount++

        if (isRouteApproved(route, approvedPlans)) {
          acc.approvedRoutesCount++
        } else {
          acc.allRoutesApproved = false
        }

        if (addSeconds(route.date, workingDayStartSec) < parseISO(startOfToday)) {
          acc.routesInThePastCount += 1
        }

        // If there's no extendedRoute returns
        if (!extRoute) return acc

        if (extRoute.route) acc.gotSomeValidRoutes = true
        if (extRoute.route.steps.length > 0) acc.notEmptyRoutesCount++
        if (!acc.gotExecutionEvents) {
          if ((extRoute.route.edge?.timeSec ?? -1) > -1) {
            acc.gotExecutionEvents = true
          } else {
            if (
              routePlanTrackingData[extRoute.id]?.routeTrackingData &&
              (routePlanTrackingData[extRoute.id].routeTrackingData?.startData.sec !== -1 ||
                routePlanTrackingData[extRoute.id].routeTrackingData?.preparationTrackingData
                  .timeInSec !== -1 ||
                routePlanTrackingData[extRoute.id].routeTrackingData?.closeOutTrackingData
                  .timeInSec !== -1 ||
                routePlanTrackingData[extRoute.id].routeTrackingData?.endData.sec !== -1)
            ) {
              acc.gotExecutionEvents = true
            } else {
              acc.gotExecutionEvents = extRoute.steps.some(aggregatedStep =>
                aggregatedStep.steps.some(
                  extRouteStep =>
                    extRouteStep.step.type !== 'departure' &&
                    extRouteStep.step.type !== 'arrival' &&
                    extRouteStep.orderStepId &&
                    !extOrderSteps[extRouteStep.orderStepId].orderStep.trackingData.isEmpty,
                ),
              )
            }
          }
        }
        return acc
      },
      { ...defaultPermission },
    )

    return {
      routesCount: currentRoutes.length,
      isDemoOrDev,
      isDev,
      ...data,
    }
  }, [
    isDev,
    extRoutes,
    routesInfo,
    isDemoOrDev,
    startOfToday,
    approvedPlans,
    extOrderSteps,
    currentRoutes,
    workingDayStartSec,
    routePlanTrackingData,
  ])

  const calendarRangeInterval = useMemo(() => eachDayOfInterval({ start, end }), [start, end])

  const rangeOutOfOptimizationHorizon = useMemo(() => {
    // There's no limit of optimization for simulation
    if (isSimulation) return false

    const optIntervalStart = parse(startFromDate, 'yyyyMMdd', new Date())
    const optIntervalEnd = addDays(parseISO(startOfToday), horizonOpt)
    const optInterval = { start: optIntervalStart, end: optIntervalEnd }

    return calendarRangeInterval.some(d => !isWithinInterval(d, optInterval))
  }, [horizonOpt, startFromDate, startOfToday, calendarRangeInterval, isSimulation])

  const rangeBiggerThanOptimizationHorizon = calendarRangeInterval.length > horizonOpt

  return useMemo(
    () => ({
      ...routesData,
      isSimulation,
      rangeOutOfOptimizationHorizon,
      rangeBiggerThanOptimizationHorizon,
      canExportInSimulation,
    }),
    [
      routesData,
      isSimulation,
      rangeOutOfOptimizationHorizon,
      rangeBiggerThanOptimizationHorizon,
      canExportInSimulation,
    ],
  )
}
