import { type Column, type ColumnStore } from '@bryntum/schedulerpro'

import { schedulerPreferencesAtom } from '../../schedulerPreferences'
import { createVehicleColumn } from '../../columns/defs/vehicle'
import { createDateColumn } from '../../columns/defs/date'

import { dateGrouper, vehicleGrouper } from '../groupers'
import { project, schedulerInstance } from '../project'

let lastGroupByValue = schedulerPreferencesAtom.preferences.groupBy

export function syncProjectGrouping() {
  // If the groupBy value hasn't changed, don't do anything
  if (lastGroupByValue === schedulerPreferencesAtom.preferences.groupBy) return

  // Update the groupBy value
  lastGroupByValue = schedulerPreferencesAtom.preferences.groupBy

  // Group the resource store
  const grouper =
    schedulerPreferencesAtom.preferences.groupBy === 'vehicleId' ? vehicleGrouper : dateGrouper

  project.resourceStore.group(grouper.field, grouper.ascending)

  if (!schedulerInstance.instance) return

  const columnStore = schedulerInstance.instance.columns as ColumnStore
  const vehicleColumnInStore = columnStore.find(c => c.field === 'vehicleName') as
    | Column
    | undefined
  const dateColumnInStore = columnStore.find(c => c.field === 'dateAsString') as Column | undefined

  switch (schedulerPreferencesAtom.preferences.groupBy) {
    case 'dateAsString':
      // Verify if needed to add vehicle column and remove date column

      if (!vehicleColumnInStore) {
        columnStore.insert(0, createVehicleColumn(dateColumnInStore?.width ?? 140, false))
      }

      if (dateColumnInStore) {
        columnStore.remove(dateColumnInStore)
      }

      break

    case 'vehicleId':
      // Verify if needed to add vehicle column and remove date column

      if (!dateColumnInStore) {
        columnStore.insert(0, createDateColumn(vehicleColumnInStore?.width ?? 140, false))
      }

      if (vehicleColumnInStore) {
        columnStore.remove(vehicleColumnInStore)
      }

      break
  }
}
