import styled from 'styled-components'

type Props = {
  selected?: boolean
  highlighted?: boolean
  height?: number
}

const ItemRendererContainer = styled.button<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: ${p => p.height || 50}px;
  padding: 10px 16px;
  cursor: pointer;
  background: ${p =>
    p.selected
      ? p.theme.colors.$scienceBlue
      : p.highlighted
        ? p.theme.colors.$alabaster
        : p.theme.colors.$pureWhite};
  border-width: 0;

  &:hover {
    background: ${p => (p.selected ? p.theme.colors.$scienceBlue : p.theme.colors.$alabaster)};
  }
`

ItemRendererContainer.displayName = 'ItemRendererContainer'
export default ItemRendererContainer
