import type { OptionalSelectionModifiers } from '@/atoms'

import { FeatureLike } from 'ol/Feature'
import MapBrowserEvent from 'ol/MapBrowserEvent'
import { Interaction } from 'ol/interaction'
import Layer from 'ol/layer/Layer'
import { Condition } from 'ol/events/condition'

export type MouseInteractionSubscriber = (
  nextFeatures: FeatureLike[],
  layersByFeature: Map<FeatureLike, Layer>,
  selectionModifiers: OptionalSelectionModifiers,
  mapBrowserEvent: MapBrowserEvent<PointerEvent>,
) => void

interface MouseInteractionOptions {
  multi?: boolean
  condition: Condition
  changeCursor?: boolean
  disabledLayers?: Layer[]
  stopPropagation?: boolean
  allowDuplicateEvents?: boolean
  subscribers?: MouseInteractionSubscriber[]
}

const parseMapEventModifiers = (
  mapBrowserEvent: MapBrowserEvent<PointerEvent>,
): OptionalSelectionModifiers => {
  return {
    altKey: !!mapBrowserEvent.originalEvent?.altKey,
    ctrlKey: !!mapBrowserEvent.originalEvent?.ctrlKey,
    metaKey: !!mapBrowserEvent.originalEvent?.metaKey,
    shiftKey: !!mapBrowserEvent.originalEvent?.shiftKey,
  }
}

export class MouseInteraction extends Interaction {
  protected readonly features: Set<FeatureLike> = new Set()
  protected readonly disabledLayers: Set<Layer> = new Set()
  protected readonly subscribers: Set<MouseInteractionSubscriber> = new Set()

  constructor(
    public readonly uid: string,
    protected options: MouseInteractionOptions,
  ) {
    // @ts-expect-error Typings set `handleEvent` as required but it's optional
    super({})

    if (options.disabledLayers) {
      for (const disabledLayer of options.disabledLayers) {
        this.disableLayer(disabledLayer)
      }
    }

    if (options.subscribers) {
      for (const subscriber of options.subscribers) {
        this.subscribers.add(subscriber)
      }
    }
  }

  // ----------------------------------------------------
  // ----------------------------------------------------

  protected notifySubscribers = (
    nextFeatures: Set<FeatureLike>,
    layersByFeature: Map<FeatureLike, Layer>,
    mapBrowserEvent: MapBrowserEvent<PointerEvent>,
  ): void => {
    const modifiers = parseMapEventModifiers(mapBrowserEvent)

    for (const subscriber of this.subscribers) {
      subscriber(Array.from(nextFeatures), layersByFeature, modifiers, mapBrowserEvent)
    }
  }

  protected prepareEventData = (
    nextFeatures: Set<FeatureLike>,
    layersByFeature: Map<FeatureLike, Layer>,
    mapBrowserEvent: MapBrowserEvent<PointerEvent>,
  ): boolean => {
    if (this.features.size === 0 && nextFeatures.size === 0) {
      return false
    }

    // clear selection
    if (nextFeatures.size == 0) {
      this.features.clear()
      this.notifySubscribers(nextFeatures, layersByFeature, mapBrowserEvent)

      return true
    }

    const sizeChanged = this.features.size !== nextFeatures.size

    let contentChanged = false
    if (!sizeChanged) {
      for (const feature of nextFeatures) {
        if (!this.features.has(feature)) {
          contentChanged = true
          break
        }
      }
    }

    const changed = sizeChanged || contentChanged

    if (changed) {
      const newFeatures = new Set(nextFeatures)

      for (const feature of this.features) {
        if (!nextFeatures.has(feature)) {
          this.features.delete(feature)
        } else {
          newFeatures.delete(feature)
        }
      }

      if (newFeatures.size > 0) {
        for (const feature of newFeatures) {
          this.features.add(feature)
        }
      }

      this.notifySubscribers(nextFeatures, layersByFeature, mapBrowserEvent)
    } else if (this.options.allowDuplicateEvents) {
      // allow to emit multiple time the same event
      this.notifySubscribers(nextFeatures, layersByFeature, mapBrowserEvent)
      return true
    }

    return changed
  }

  protected processEvent = (
    mapBrowserEvent: MapBrowserEvent<PointerEvent>,
  ): [Set<FeatureLike>, Map<FeatureLike, Layer>] => {
    const layersByFeature: Map<FeatureLike, Layer> = new Map()
    const features = new Set<FeatureLike>()

    const map = mapBrowserEvent.map
    // mapBrowserEvent.preventDefault()

    map.forEachFeatureAtPixel(
      mapBrowserEvent.pixel,
      (feature: FeatureLike, layer: Layer) => {
        if (this.disabledLayers.has(layer)) {
          // skip features from disabled layers
          return false
        }

        features.add(feature)
        layersByFeature.set(feature, layer)

        // return false to allow multiple matches
        // return true to get only the top-most match
        return !this.options.multi
      },
      { hitTolerance: 0 },
    )

    return [features, layersByFeature]
  }

  protected setCursor(mapBrowserEvent: MapBrowserEvent<PointerEvent>, active: boolean = false) {
    if (!this.options.changeCursor) return

    const view = mapBrowserEvent.map.getViewport()

    // Change the cursor
    if (active) {
      view.classList.add('ol-pointer')
    } else {
      view.classList.remove('ol-pointer')
    }
  }

  public handleEvent(mapBrowserEvent: MapBrowserEvent<PointerEvent>): boolean {
    if (!mapBrowserEvent.originalEvent || !this.options.condition(mapBrowserEvent)) {
      return true
    }

    const [features, layersByFeature] = this.processEvent(mapBrowserEvent)
    const changed = this.prepareEventData(features, layersByFeature, mapBrowserEvent)

    this.setCursor(mapBrowserEvent, this.features.size > 0)

    return changed ? !this.options.stopPropagation : true
  }

  // ----------------------------------------------------
  // ----------------------------------------------------
  // MouseInteraction API

  public disableLayer(layer: Layer): void {
    this.disabledLayers.add(layer)
  }

  public enableLayer(layer: Layer): void {
    this.disabledLayers.delete(layer)
  }

  public subscribe(subscriber: MouseInteractionSubscriber): () => void {
    this.subscribers.add(subscriber)

    return () => {
      this.unsubscribe(subscriber)
    }
  }

  public unsubscribe(subscriber: MouseInteractionSubscriber): void {
    this.subscribers.delete(subscriber)
  }
}
