import { ResourceModel } from '@bryntum/schedulerpro'
import { schedulerPreferencesAtom } from '../../schedulerPreferences'

export function createDynamicLoadSorter(loadName: string) {
  return function (item1: ResourceModel, item2: ResourceModel) {
    const loads1 = item1.getData('loads') as uui.domain.client.rm.SchedulerResource['loads']
    const loads2 = item2.getData('loads') as uui.domain.client.rm.SchedulerResource['loads']

    const value1 = schedulerPreferencesAtom.preferences.showAbsoluteValues
      ? (loads1?.[loadName]?.current?.absolute ?? -1)
      : (loads1?.[loadName]?.current?.percentage ?? -1)
    const value2 = schedulerPreferencesAtom.preferences.showAbsoluteValues
      ? (loads2?.[loadName]?.current?.absolute ?? -1)
      : (loads2?.[loadName]?.current?.percentage ?? -1)

    return value1 - value2
  }
}
