import { Box, Typography } from '@mui/material'
import { useTexts } from './useTexts'

type Props = {
  driverAssistance: uui.domain.server.rm.MobileAppInteraction
}

export function DriverAssistanceType(props: Props) {
  const texts = useTexts()
  const { driverAssistance } = props
  const isInOutSuggestType = driverAssistance.type === 'ASSISTED_TIME_IN_OUT'
  const isOutSuggestType = driverAssistance.type === 'ASSISTED_TIME_OUT'
  const isRequireType = driverAssistance.type === 'DRIVER_REQUIRED_FLOW'
  const type =
    isInOutSuggestType || isOutSuggestType
      ? texts.suggest
      : isRequireType
        ? texts.require
        : texts.off
  const isSuggestType = isInOutSuggestType || isOutSuggestType

  return (
    <Box marginTop={4} data-testid="driver-assistance-view-details">
      <Typography variant="body1">{texts.header}</Typography>
      <Typography variant="caption" color="text.disabled">
        {type}
      </Typography>

      {isSuggestType && (
        <Box marginTop={1}>
          {isInOutSuggestType && (
            <Typography variant="body2" color="grey.700">
              - {texts.checkIn}
            </Typography>
          )}
          <Typography variant="body2" color="grey.700">
            - {texts.checkOut}
          </Typography>
        </Box>
      )}

      {isRequireType && (
        <Box marginTop={1}>
          <Typography variant="body2" color="grey.700">
            - {texts.checkIn} ({texts.ALWAYS_REQUIRED})
          </Typography>
          {Object.entries(driverAssistance.driverRequiredFlow).map(entry =>
            entry[0] !== 'mandatoryDeparture' && entry[1] !== 'DO_NOT_REQUIRE' ? (
              <Typography variant="body2" key={entry[0]} color="grey.700">
                - {texts[entry[0]]} ({texts[entry[1]]})
              </Typography>
            ) : entry[0] === 'mandatoryDeparture' && entry[1] ? (
              <Typography variant="body2" key={entry[0]} color="grey.700">
                - {texts.checkOut} ({texts.ALWAYS_REQUIRED})
              </Typography>
            ) : null,
          )}
          <Typography variant="body2" color="grey.700">
            - {texts.orderStatus} ({texts.ALWAYS_REQUIRED})
          </Typography>
        </Box>
      )}
    </Box>
  )
}
