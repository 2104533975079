import type { RouteDetailEntry } from '../../types'

import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { selectRoutePlanTrackingData } from '@/features/domain/routeplan'
import { selectUserConfiguration } from '@/features/domain/user'
import { selectRoutesInfo } from '@/features/domain/scheduler'
import { selectTerritory } from '@/features/domain/territory'
import { selectDrivers } from '@/features/domain/driver'
import { selectRoutes } from '@/features/domain/route'

import { useExecutedOrderStepsByDriver } from '../useExecutedOrderStepsByDriver'
import { useTexts } from '../useTexts'

import { computeRouteDetailEntry } from './utils/computeRouteDetailEntry'
import { sortEntriesByFirstEvent } from './utils/sortEntriesByFirstEvent'
import { useVehicleSettings } from './hooks/useVehicleSettings'
import { getRouteViolations } from './utils/getRouteViolations'
import { getRouteDriverIds } from './utils/getRouteDriverIds'
import { hasEditPermission } from './utils/hasEditPermission'

export function useRouteDetails(routeId: string) {
  const { mobileAppsTrackingType } = useSelector(selectTerritory)
  const routePlanTrackingData = useSelector(selectRoutePlanTrackingData)
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const userConfig = useSelector(selectUserConfiguration)
  const routeInfos = useSelector(selectRoutesInfo)
  const extRoutes = useSelector(selectRoutes)
  const vehicles = useSelector(selectVehicles)
  const drivers = useSelector(selectDrivers)
  const texts = useTexts()

  const routeInfo = routeInfos[routeId]
  const extRoute = extRoutes[routeId]

  const executedOrderStepsByDriver = useExecutedOrderStepsByDriver(routeInfo?.ordersExecution)
  const settings = useVehicleSettings(routeInfo?.vehicleId, routeInfo?.dateAsString)

  const uv = routeInfo ? vehicles[vehiclesByVehicleId[routeInfo.vehicleId]] : undefined

  return useMemo(() => {
    if (!routeInfo?.approvedVersion || !uv) return

    const departureDepot = settings?.departureDepot ?? '-'
    const arrivalDepot = settings?.arrivalDepot ?? '-'

    // Calculate route violations (TWS and TWE)
    const { startsBeforeWorkingDayStart, endsAfterWorkingDayEnd } = getRouteViolations(routeInfo)

    // Check if some event has been tracked
    const currentRoutePlanTrackingData = routePlanTrackingData[routeInfo.id]
    const routeTrackingData = currentRoutePlanTrackingData?.routeTrackingData
    const driverTrackingData = currentRoutePlanTrackingData?.driverTrackingData

    const editPermission = hasEditPermission(routeTrackingData, driverTrackingData, userConfig)

    // Retrieve driverIds related to the route
    const driverIds = getRouteDriverIds(routeInfo, routeTrackingData, executedOrderStepsByDriver)

    // Retrieve first driver id
    const firstDriverId = driverIds[0]

    // Retrieve last driver id
    const lastDriverId = driverIds[driverIds.length - 1]

    // Compute all the entries by driver
    const unsortedRouteDetailsData: RouteDetailEntry[] = []

    for (const driverId of driverIds) {
      const isUnassignedDriver = driverId === '-'

      const driverName = isUnassignedDriver
        ? texts.unassignedDriver
        : (drivers[driverId]?.driver.name ?? '-')

      unsortedRouteDetailsData.push(
        computeRouteDetailEntry({
          extRoute,
          driverId,
          routeInfo,
          driverName,
          arrivalDepot,
          lastDriverId,
          firstDriverId,
          departureDepot,
          routeTrackingData,
          driverTrackingData,
          mobileAppsTrackingType,
          endsAfterWorkingDayEnd,
          executedOrderStepsByDriver,
          startsBeforeWorkingDayStart,
        }),
      )
    }
    const routeDetailsData = unsortedRouteDetailsData.sort(sortEntriesByFirstEvent)

    // Compute the tracked route start
    const trackedRouteStart =
      routePlanTrackingData[routeInfo.id]?.routeTrackingData?.startData.sec !== -1
        ? routePlanTrackingData[routeInfo.id]?.routeTrackingData?.startData.sec
        : undefined

    // Compute the tracked route end
    const trackedRouteEnd = routePlanTrackingData[routeInfo.id]?.routeTrackingData?.endData.sec

    return {
      uv,
      extRoute,
      routeInfo,
      editPermission,
      trackedRouteEnd,
      routeDetailsData,
      trackedRouteStart,
      endsAfterWorkingDayEnd,
      startsBeforeWorkingDayStart,
    }
  }, [
    executedOrderStepsByDriver,
    mobileAppsTrackingType,
    routePlanTrackingData,
    userConfig,
    routeInfo,
    extRoute,
    settings,
    drivers,
    texts,
    uv,
  ])
}
