import { geo } from '@/server-data'
import { saveKmlFile } from './file'

// async is intentional to be future proof for
// features like run into a requestAnimationFrame
export async function exportAreasToKML(
  areas: uui.domain.client.rm.TrafficRegion[],
  recurrence: string,
) {
  try {
    const placeMarks = areas.map(area => placemarkTemplate(convertToServerEntity(area)))
    const kml = kmlTemplate(placeMarks)

    const prefix = recurrence === 'any' ? 'normal-day' : recurrence
    saveKmlFile(kml, `${prefix}-traffic-regions.kml`)
  } catch (error) {
    console.log('Creation of KML file failed')
  }
}

function convertToServerEntity(
  area: uui.domain.client.rm.TrafficRegion,
): uui.domain.server.rm.TrafficRegion {
  return {
    level: area.level,
    name: area.name,
    poly: area.poly,
    timeWindowLevels: area.timeWindowLevels,
  }
}

function placemarkTemplate(region: uui.domain.server.rm.TrafficRegion) {
  const { name, level, timeWindowLevels, poly } = region

  return `
    <Placemark>
      <name>${name}</name>
      <level>${level}</level>
      <timeWindowLevels>${timeWindowLevels.join(',')}</timeWindowLevels>

      <Style >
        <PolyStyle>
          <color>${hexToKmlColor('#999999')}</color>
            <fill>1</fill>
            <outline>1</outline>
          </PolyStyle>
          <LineStyle>
            <color>${hexToKmlColor('#999999')}</color>
            <width>4</width>
          </LineStyle>
      </Style>

      <Polygon>
        <outerBoundaryIs>
          <LinearRing>
            <coordinates>
              ${poly
                .map(([lat, lng]) => `${geo.decodeFloat(lng)},${geo.decodeFloat(lat)},0`)
                .join(' ')}
            </coordinates>
          </LinearRing>
        </outerBoundaryIs>
      </Polygon>
    </Placemark>
`
}

function kmlTemplate(placemarks: string[]) {
  return `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
    <Document>
      ${placemarks.join('\n')}
    </Document>
  </kml>
`
}

// rrggbbaa -> aabbggrr
function hexToKmlColor(hex: string, alpha?: number) {
  const source = hex.startsWith('#') ? hex.slice(1) : hex
  const r = `${source[0]}${source[1]}`
  const g = `${source[2]}${source[3]}`
  const b = `${source[4]}${source[5]}`
  const a =
    typeof alpha === 'number' && !Number.isNaN(alpha)
      ? alpha.toString(16)
      : hex.length === 8
        ? `${source[6]}${source[7]}`
        : 'ff'

  return `${a}${b}${g}${r}`.toLowerCase()
}

function parseXml(xml: string): Document | null {
  if (window.DOMParser) {
    const parser: DOMParser = new DOMParser()
    return parser.parseFromString(xml, 'text/xml')
  } else if (
    typeof window.ActiveXObject !== 'undefined' &&
    new window.ActiveXObject('Microsoft.XMLDOM')
  ) {
    const xmlDoc = new window.ActiveXObject('Microsoft.XMLDOM')
    xmlDoc.async = 'false'
    xmlDoc.loadXML(xml)
    return xmlDoc
  }

  return null
}

const EMPTY_ARRAY = []

export function KMLToTrafficRegions(kmlAsString: string): uui.domain.server.rm.TrafficRegion[] {
  const kml = parseXml(kmlAsString)
  if (kml) {
    if (kml.querySelectorAll('Document').length > 0) {
      const placemarks = kml.querySelectorAll('Document>Placemark')

      return [...placemarks]
        .map(KMLToTrafficRegion)
        .filter(Boolean) as uui.domain.server.rm.TrafficRegion[]
    }
  }

  return EMPTY_ARRAY
}

const uniqueTrafficRegionName = 'Unnamed traffic region'
let uniqueTrafficRegionNameCounter = 0
function getUniqueTrafficRegionName() {
  return `${uniqueTrafficRegionName} ${uniqueTrafficRegionNameCounter++}`
}

function KMLToTrafficRegion(node: Element) {
  const { tagName } = node

  const nameText = extractPlainName(node) || getUniqueTrafficRegionName()

  const levelElement = node.querySelector(tagName + '>level')
  const levelText = levelElement?.textContent ? levelElement.textContent : '100'
  const level = Number.isNaN(parseInt(levelText)) ? 100 : parseInt(levelText)

  const timeWindowLevelsElement = node.querySelector(tagName + '>timeWindowLevels')
  const timeWindowLevelsText = timeWindowLevelsElement?.textContent
    ? timeWindowLevelsElement.textContent
    : ''
  const timeWindowLevels = timeWindowLevelsText
    .split(',')
    .map(value => parseInt(value))
    .filter(value => !Number.isNaN(value))

  const coordElement = node.querySelector('outerBoundaryIs>LinearRing>coordinates')
  const coordText = coordElement?.textContent ? coordElement.textContent : '0'

  const poly = coordText.split(/\s+/).reduce<[number, number][]>((res, coordTuple) => {
    const [srcLng, srcLat] = coordTuple.split(',')

    if (srcLat && srcLng) {
      const lat = parseFloat(srcLat)
      const lng = parseFloat(srcLng)

      if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
        res.push([geo.encodeFloat(lat), geo.encodeFloat(lng)])
      }
    }

    return res
  }, [])

  if (poly.length > 2) {
    return {
      level,
      name: nameText,
      poly,
      timeWindowLevels,
    }
  }

  return
}

const NAME_SEPARATOR = '/'
const nameSanitizer = (str: string) => str.replace(/[^\w\d\s_]/g, '')

function extractPlainName(sourceNode: Element): string {
  let node: Element | null = sourceNode
  let name = ''

  while (node) {
    const { tagName } = node
    if (tagName === 'Folder' || tagName === 'Placemark') {
      const nameNode: Element | null = node.querySelector(tagName + '>name')

      if (nameNode) {
        const { textContent } = nameNode
        const containerName: string | null =
          typeof textContent === 'string' ? nameSanitizer(textContent) : null

        if (containerName) {
          if (name.length) name += NAME_SEPARATOR
          name += containerName
        }
      }
    }
    node = node.parentElement
  }

  return name
}
