import type { DropDownItemVehicle } from '../typings'

import { useSelector } from 'react-redux'
import { format } from 'date-fns/esm'

import { selectDriverAssignmentsWithId } from '@/features/domain/driverAssignments'
import { selectDrivers } from '@/features/domain/driver'
import { getCalendarizedSettings, getMatchingRecurrenceByDate } from '@/server-data'

import { useTexts } from '../useTexts'
import { isBestOption } from '../typings'
import { useController } from './useController'

export const useVehicleOptions = (): DropDownItemVehicle[] => {
  const {
    data: { vehicles, activeVehicle, activeDate, closedRouteIds },
  } = useController()
  const texts = useTexts()
  const drivers = useSelector(selectDrivers)

  const driverAssignment = useSelector(selectDriverAssignmentsWithId)

  const bestItem: DropDownItemVehicle = {
    id: 'best-vehicle-dropdown-item',
    label: texts.bestVehicle,
    disabled: false,
    selected: isBestOption(activeVehicle),
    vehicle: 'best',
    kind: 'item',
  }

  const options = vehicles.reduce(
    (acc, vehicle) => {
      const { id, lockedDates } = vehicle

      let driverName = ''

      if (isBestOption(activeDate)) {
        acc.push({
          id,
          label: `${vehicle.externalId}`,
          disabled: false,
          selected: !isBestOption(activeVehicle) && activeVehicle.id === id,
          vehicle,
          kind: 'item',
        })
        return acc
      }

      const dateAsString = format(activeDate, 'yyyyMMdd')
      let match = driverAssignment[dateAsString]

      if (!match) {
        const key = getMatchingRecurrenceByDate(dateAsString, Object.keys(driverAssignment))
        match = driverAssignment[key]
      }

      if (match) {
        const driverId = match.assignments[vehicle.id]
        if (driverId) {
          const extDriver = drivers[driverId]
          if (extDriver) {
            driverName = extDriver.driver.name
          }
        }
      }

      const routeId = `${id}-${dateAsString}`
      const settings = getCalendarizedSettings(vehicle, activeDate)
      const available = !!settings ? settings.available : false

      const locked = lockedDates.includes(dateAsString)
      const closed = closedRouteIds.has(routeId)
      const disabled = !available || locked || closed

      const disabledReason = locked
        ? `(${texts.routeLocked})`
        : !available
          ? `(${texts.vehicleUnavailable})`
          : closed
            ? `(${texts.routeClosed})`
            : ''

      const selected = !disabled && !isBestOption(activeVehicle) && activeVehicle.id === id

      acc.push({
        id,
        label: `${vehicle.externalId} ${
          driverName ? '-' : ''
        } ${driverName} ${disabledReason}`.trim(),
        disabled,
        selected,
        vehicle,
        kind: 'item',
      })
      return acc
    },
    [bestItem],
  )

  return options
}
