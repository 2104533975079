import { type ResourceModel } from '@bryntum/schedulerpro'
import { conversionUtils } from '@/server-data'

import { getPercentageColor, getSchedulerColor } from '../../styles/colors'
import { schedulerPreferencesAtom } from '../../atoms/schedulerPreferences'

import { SingleColumnContainer } from '../Scheduler/components/SingleColumnContainer'
import { LinearProgress } from '../Scheduler/components/LinearProgress'
import { Typography } from '../Scheduler/components/Typography'
import { Link } from '../Scheduler/components/Link'

interface Props {
  language: uui.domain.Language
  record: ResourceModel
  field: string
  value: uui.domain.client.rm.SchedulerResource['ordersToDo']
}

export function OrdersUndeclaredCell(props: Props) {
  const { value, language } = props

  const { showAbsoluteValues } = schedulerPreferencesAtom.preferences

  const formatNumber = Intl.NumberFormat(conversionUtils.intlFormat[language]).format

  // If we have to show absolute values and the absolute value is 0, we show a dash
  if (showAbsoluteValues && value.absolute === 0) {
    return (
      <SingleColumnContainer>
        <Typography fontSize={12}>-</Typography>
      </SingleColumnContainer>
    )
  }

  // If we have to show percentage values and the percentage is a NaN we don't show the link
  if (!showAbsoluteValues && isNaN(value.percentage)) {
    return (
      <SingleColumnContainer>
        <LinearProgress color={getPercentageColor(value.percentage)} value={value.percentage} />
        <Typography fontSize={12}>
          {!value.percentage || value.percentage <= 0
            ? '-'
            : `${formatNumber(Math.round(value.percentage * 100) / 100)}%`}
        </Typography>
      </SingleColumnContainer>
    )
  }

  // If we have to show absolute values
  if (showAbsoluteValues) {
    return (
      <SingleColumnContainer>
        <Link>
          <Typography fontSize={12}>
            {value.absolute && value.absolute > 0 ? formatNumber(value.absolute) : '-'}
          </Typography>
        </Link>
      </SingleColumnContainer>
    )
  }

  // If we have to show percentage values
  return (
    <SingleColumnContainer>
      <LinearProgress
        color={getSchedulerColor('--scheduler-selection')}
        value={
          value.percentage > 100
            ? 100
            : value.percentage <= 0 || !value.percentage
              ? 0
              : (value.percentage * 100) / 100
        }
      />
      <Link>
        <Typography fontSize={10}>
          {!value.percentage || value.percentage <= 0
            ? '-'
            : `${formatNumber(Math.round(value.percentage * 100) / 100)}%`}
        </Typography>
      </Link>
    </SingleColumnContainer>
  )
}
