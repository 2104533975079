import { intl } from '@/intl'
import * as OrdersTexts from '../../intl'

const getDefaultTrackingData = (vehicleId: string): uui.domain.client.rm.OrderStepTrackingData => {
  return {
    vehicleId,
    timeInSec: -1,
    timeInLatLng: undefined,
    timeOutSec: -1,
    timeOutLatLng: undefined,
    status: 'missing',
    statusSec: -1,
    statusLatLng: undefined,
    autoTimeInSec: -1,
    autoTimeInLatLng: undefined,
    autoTimeOutSec: -1,
    autoTimeOutLatLng: undefined,
    isEmpty: true,
    statusReason: '',
    latestTrackedLoadLatLng: null,
    latestTrackedLoadSec: -1,
    pods: {
      signatures: undefined,
      pictures: undefined,
      audios: undefined,
      barcodes: [],
      note: {
        podType: 'note',
        uuid: 'EMPTY_NOTE_UUID',
        sec: -1,
        latLng: null,
        text: '',
      },
    },
  }
}

const PRIORITY_MAP_BY_VALUE = {
  [-20]: 'lowest',
  [-10]: 'lower',
  [0]: 'normal',
  [10]: 'higher',
  [20]: 'highest',
}

const PRIORITY_INTL_MAP_ID: Record<string, uui.domain.intl.LocalizationIdentifier> = {
  lowest: 'orders.priority.lowest',
  lower: 'orders.priority.lower',
  normal: 'orders.priority.normal',
  higher: 'orders.priority.higher',
  highest: 'orders.priority.highest',
}

export const getViewDetails = (
  order: uui.domain.client.rm.ExtendedOrderStep,
  vehiclesMap: Record<string, uui.domain.client.rm.Vehicle>,
  companiesMap: Record<string, uui.domain.client.rm.Company>,
  formatEligibility: (eligibility: uui.domain.client.rm.Eligibility) => string,
  archived: boolean,
) => {
  const {
    order: { name, eligibility, priority, forceVehicleId, archivedData },
    orderStep: { serviceTimeSec, trackingData, phone = '', email = '', notes = '' },
    location: { address, geoAddress },
  } = order

  const vehicleId = order.hasVehicle ? order.vehicleId : undefined
  const depot = order.atDepot ? order.depot : undefined

  const td =
    trackingData || (vehicleId !== undefined ? getDefaultTrackingData(vehicleId || '') : undefined)

  const location = depot ? depot.name : address ? address : geoAddress || ''

  let vehicleText = OrdersTexts.getAutomaticAssignmentText()

  if (forceVehicleId) {
    vehicleText = vehiclesMap[forceVehicleId]?.externalId
  }

  const formattedEligibility = formatEligibility(eligibility)

  const companyId = order.order.companyId
  const hasZombieCompany = companyId !== null && !companiesMap[companyId]

  const company = archived
    ? (archivedData?.companyName ?? '-')
    : companyId === null || hasZombieCompany
      ? null
      : (companiesMap[companyId]?.name ?? null)

  return {
    vehicleText,
    trackingData: td,
    pods: td ? td.pods : undefined,
    title: name || OrdersTexts.getUnnamedOrderText(),
    description: location || OrdersTexts.getOrdersText(),
    eligibilityText: OrdersTexts.getEligibilityLabel(eligibility, formattedEligibility),
    priorityText: intl.translate({
      id: PRIORITY_INTL_MAP_ID[PRIORITY_MAP_BY_VALUE[priority]],
    }),
    serviceTime: serviceTimeSec,
    phone,
    email,
    notes,
    company,
  }
}
