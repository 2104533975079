import type { Coordinate } from 'ol/coordinate'
import type VectorSource from 'ol/source/Vector'
import type Geometry from 'ol/geom/Geometry'

import Feature from 'ol/Feature'
import { ObjectEvent } from 'ol/Object'
import Draw, { DrawEvent } from 'ol/interaction/Draw'

import { gis } from '@/server-data'

import { createDrawStyle } from './DrawStyle/createDrawStyle'

import { arePointsEqual } from './utils/arePointsEqual'
import { areSegmentsValid } from './utils/areSegmentsValid'
import { getFeatureCoordinates } from './utils/getFeatureCoordinates'
import { computePositions, computePosition } from './utils/computePositions'
import { drawFeatureMetadata } from './utils/drawFeatureMetadata'

type GeometryStatus =
  // The Area is without any point
  | 'empty'
  // The Area is valid and can be saved/closed
  | 'valid'
  // The Area is invalid and cannot be saved/closed
  | 'invalid'

// Area Created unsubscribe function
type Unsubscriber = () => void

// Area Created subscribe function
type OnAreaCreateSubscriber = (coords: uui.domain.LatLng[], feature: Feature<Geometry>) => void

interface Options {
  color: string
  source: VectorSource
}

/**
 * TODO: Main description
 */
export class DrawEditableAreaInteraction extends Draw {
  constructor({ color, source }: Options) {
    super({
      // support only Polygons
      type: 'Polygon',
      style: createDrawStyle(color),
      source,
      freehand: false,
      minPoints: 3,
    })

    // the parent interaction doesn't expose a pointer to the linked Vector Source
    this.source = source

    // set the drawing layer main color
    this.updateColor(color)

    // listen to change toi the Active state
    this.on('change:active', this.handleChangeActiveState)
  }

  // ---------------------------------------------------------------
  // ADDITIONAL API
  // ---------------------------------------------------------------

  /**
   * Change the color of the Area.
   *
   * @param color Hexadecimal or RGB string of the color the Shape must have.
   */
  public updateColor(color: string) {
    this.getOverlay().setStyle(createDrawStyle(color))
  }

  /**
   * Subscribe to the creation of an Area.
   *
   * @param subscriber The function that will be invoked when an Area is created
   * @returns The function to unsubscribe
   */
  public onAreaCreate(subscriber: OnAreaCreateSubscriber): Unsubscriber {
    this.onAreaCreateSubscribers.add(subscriber)

    return () => {
      this.onAreaCreateSubscribers.delete(subscriber)
    }
  }

  // ---------------------------------------------------------------
  // Internal Properties
  // ---------------------------------------------------------------

  protected source: VectorSource

  /**
   * Reference to the Feature actively used by the Draw Interaction
   */
  protected activeFeature: Feature<Geometry> | undefined

  /**
   * Array of Open Layers Coordinates for the active Feature.
   * This is a convenience helper to avoid accessing the coordinates through the Feature instance API.
   * @see getFeatureCoordinates
   */
  protected activeCoords: Coordinate[] = []

  /**
   * Array of LatLng points matching the active Feature coordinates.
   * @see activeCoords
   */
  protected activeGeometryPoints: uui.domain.Point[] = []

  /**
   * Validation status for the Geometry being drawn
   */
  protected activeGeometryStatus: GeometryStatus = 'empty'

  /**
   * Flag used to track when the current mouse position is snapped to one
   * of the two open ends of the shape being drawn.
   */
  protected showCloseShapeCursor: boolean = false

  /**
   * Collection of subscribers for the `Area Create` Event.
   */
  protected onAreaCreateSubscribers = new Set<OnAreaCreateSubscriber>()

  // ---------------------------------------------------------------
  // INTERNAL EVENT HANDLERS
  // ---------------------------------------------------------------

  /**
   * Event handler reacting to the `active` status of the Interaction.
   */
  protected handleChangeActiveState = () => {
    if (this.getActive()) {
      this.enableKeyboardEvents()

      this.on('drawstart', this.handleDrawStart)
      this.on('drawend', this.handleDrawEnd)
      this.on('drawabort', this.handleDrawAbort)
    } else {
      this.disableKeyboardEvents()

      this.un('drawstart', this.handleDrawStart)
      this.un('drawend', this.handleDrawEnd)
      this.un('drawabort', this.handleDrawAbort)
    }

    this.updateCursor()
  }

  /**
   * Event handler reacting to the creation of a new valid Area.
   * It's responsible to prepare the created Geometry and its target Feature before notifying
   * the subscribers to the creation of a new Area.
   */
  protected handleOnAreaCreate(event: DrawEvent, lonLats: Coordinate[]) {
    // convert Open Layers coordinates into RM LatLng
    const latLongs = lonLats.map(gis.fromCoordinateToLatLng)
    // Open Layers Polygons keep a redundant start/end point.
    // Remove duplicate final point
    latLongs.pop()

    const feature = event.feature
    // Add a custom field to the Feature to easily identify its origin.
    drawFeatureMetadata.setAsEditableArea(feature)
    // remove the `valid` custom property from the Drawn Feature.
    drawFeatureMetadata.removeValid(feature)

    // ATTENTION: the event is delayed to allow OpenLayers to move the feature to the target layer.
    this.source.once('addfeature', () => {
      // notify subscribers the feature is drawn and ready
      this.onAreaCreateSubscribers.forEach(sub => sub(latLongs, feature))
    })
  }

  /**
   * Event handler reacting to the start of a drawing session.
   */
  protected handleDrawStart = (event: DrawEvent) => {
    this.activeGeometryStatus = 'empty'
    this.updateCursor()

    // add a custom property to the Drawn Feature to track it Geometry valid status
    this.activeFeature = event.feature
    drawFeatureMetadata.setValid(this.activeFeature, true)

    // prepare the Interaction for the upcoming creation of an Area.
    this.initGeometry(event.feature)

    // listen for changes in the Geometry (points added or removed)
    this.activeFeature?.on('change', this.handleOnChangeGeometry)
  }

  /**
   * Event handler reacting to the end of a drawing session.
   */
  protected handleDrawEnd = (event: DrawEvent) => {
    // notify subscribers
    this.handleOnAreaCreate(event, this.activeCoords)
    this.resetInteraction()
  }

  /**
   * Event handler reacting to an aborted drawing session.
   */
  protected handleDrawAbort = (_event: DrawEvent) => {
    this.resetInteraction()
  }

  /**
   * Event handler reacting to any change in the Geometry being drawn.
   */
  protected handleOnChangeGeometry = (event: ObjectEvent) => {
    // update the parsed information linked to the Geometry
    this.refreshGeometry(event.target as Feature<Geometry>)

    // validate the Geometry
    this.activeGeometryStatus = this.validateGeometry(this.activeGeometryPoints)
    this.updateCursor()

    // store the validity as a custom field of the Feature being drawn.
    drawFeatureMetadata.setValid(this.activeFeature, this.activeGeometryStatus !== 'invalid')
  }

  // ---------------------------------------------------------------
  // GEOMETRY MAINTENANCE
  // ---------------------------------------------------------------

  /**
   * Reset the internal state of the interaction
   */
  protected resetInteraction = () => {
    this.activeGeometryPoints = []
    this.activeCoords = []
    this.activeFeature = undefined
    this.activeGeometryStatus = 'empty'
    this.showCloseShapeCursor = false

    this.updateCursor()
  }

  /**
   * Accepts a Feature and parse its Geometry to prepare the Interaction for the incoming drawing.
   *
   * @param feature The Feature being drawn.
   */
  protected initGeometry = (feature: Feature<Geometry>) => {
    // Since the Interaction is limited to draw a `Polygon` but the parent Interaction support many
    // Geometry types an helper function is used to hide the boilerplate required to extract the
    // Polygon's coordinates.
    this.activeCoords = getFeatureCoordinates(feature)

    if (this.activeCoords.length === 0) return

    const map = this.getMap()
    if (!map) return

    // transform Open Layers coordinates into RM LatLng points
    this.activeGeometryPoints = computePositions(map, this.activeCoords)
    // validate the initial Geometry
    this.activeGeometryStatus = this.validateGeometry(this.activeGeometryPoints)
    this.updateCursor()
  }

  /**
   * Accepts a Feature and parse its Geometry to update the current drawing and its meta-information.
   *
   * @param feature The Feature being drawn.
   */
  protected refreshGeometry = (feature: Feature<Geometry>) => {
    // access the Feature's coordinates
    const coords = getFeatureCoordinates(feature)

    // if no points exist in the drawn Feature or in the stored meta-information stop here
    if (coords.length === 0 && this.activeCoords.length === 0) return

    const map = this.getMap()
    if (!map) return

    // When the Geometry updates three situations are possible:
    // - a point has been moved
    // - a point has been added
    // - a point has been removed
    const geometryAction =
      coords.length === this.activeCoords.length
        ? 'move'
        : coords.length > this.activeCoords.length
          ? 'add'
          : coords.length < this.activeCoords.length
            ? 'remove'
            : 'none'

    // ATTENTION: The point being moved or added is always the one before the last in the list of coordinates
    const sketchIndex = -2

    switch (geometryAction) {
      case 'move':
        const sketchPoint = coords.at(sketchIndex)

        if (sketchPoint) {
          // parse and update the stored RM point for the Sketch Open Layers coordinate
          this.activeGeometryPoints[this.activeGeometryPoints.length - 2] = computePosition(
            map,
            sketchPoint,
          )
        }
        break

      case 'add':
        const pointAdded = coords.at(sketchIndex)

        if (pointAdded) {
          // parse and add the stored RM point for the Sketch Open Layers coordinate
          this.activeGeometryPoints.splice(-1, 0, computePosition(map, pointAdded))
        }
        break

      case 'remove':
        // remove the stored RM point for the Sketch Open Layers coordinate
        this.activeGeometryPoints.splice(sketchIndex, 1)
        break
    }

    // store the new Geometry coordinates
    this.activeCoords = coords
  }

  /**
   * Validate the provided points.
   *
   * @param points An array of RM LatLng points
   * @returns The validity of the points.
   */
  protected validateGeometry = (points: uui.domain.Point[]): GeometryStatus => {
    // A Geometry with less than 3 points must be considered `empty`
    if (points.length < 3) return 'empty'

    // A Geometry with 3 points must be considered `valid`. Is not possible to have an invalid triangle.
    if (points.length === 3) return 'valid'

    const prevSketchPoint = points.at(-3)!
    const sketchPoint = points.at(-2)!
    const nextSketchPoint = points.at(-1)!

    // when the sketch point overlap one of the sibling point
    // it must be considered a valid target because it'd close the shape
    const snappedToPoint =
      arePointsEqual(prevSketchPoint, sketchPoint) || arePointsEqual(nextSketchPoint, sketchPoint)

    // when the sketch point snap to one of the 2 open ends of the Geometry
    // we change the cursor to show that it's possible to close the shape in creation
    this.showCloseShapeCursor = snappedToPoint

    // a Geometry is valid
    // - if the its sketch point is snapped to one of the 2 open ends of the polygon in creation
    // - if the 2 segments created connecting the sketch point to the start and the end of the line drawn are not intersecting any other segment
    const shapeIsValid =
      snappedToPoint || areSegmentsValid(sketchPoint, prevSketchPoint, nextSketchPoint, points)

    return shapeIsValid ? 'valid' : 'invalid'
  }

  // ---------------------------------------------------------------
  // Active Map Cursor
  // ---------------------------------------------------------------

  /**
   * Add and remove classes to Open Layers Canvas HTML Element to render the correct mouse cursor.
   */
  protected updateCursor() {
    const view = this.getMap()?.getViewport()
    if (!view) return

    // always reset
    view.classList.remove('ol-pointer-draw-valid')
    view.classList.remove('ol-pointer-draw-invalid')
    view.classList.remove('ol-pointer-draw-close-shape')

    if (!this.getActive()) return

    if (this.showCloseShapeCursor) {
      view.classList.add('ol-pointer-draw-close-shape')
      return
    }

    switch (this.activeGeometryStatus) {
      case 'empty':
      case 'valid':
        view.classList.add('ol-pointer-draw-valid')
        break

      case 'invalid':
        view.classList.add('ol-pointer-draw-invalid')
        break
    }
  }

  // ---------------------------------------------------------------
  // KEYBOARD EVENTS
  // ---------------------------------------------------------------

  protected enableKeyboardEvents() {
    window.addEventListener('keydown', this.handleKeydown)
  }

  protected disableKeyboardEvents() {
    window.removeEventListener('keydown', this.handleKeydown)
  }

  /**
   * Keyboard events handler
   */
  protected handleKeydown = (event: KeyboardEvent) => {
    // Abort on pressing ESC
    if (
      event.key === 'Escape' ||
      // IE/Edge specific value
      event.key === 'Esc'
    ) {
      event.stopPropagation()

      this.abortDrawing()
      // the event will not fire unless the user started drawing
      // we want it to emit always
      this.dispatchEvent(new DrawEvent('drawabort', new Feature()))
    }

    // delete last added point
    if (event.key === 'Backspace' || event.key === 'Delete' || event.key === 'Cancel') {
      if (this.activeGeometryPoints.length > 1) {
        event.stopPropagation()

        this.removeLastPoint()
      }
    }

    // confirm and close the shape
    if (event.key === 'Enter') {
      if (this.activeGeometryPoints.length >= 3) {
        event.stopPropagation()

        this.finishDrawing()
      }
    }
  }

  // ---------------------------------------------------------------
  // OVERRIDDEN API
  // ---------------------------------------------------------------

  override handleUpEvent(event) {
    // When the Geometry is invalid we want to prevent the parent Interaction
    // to apply the current Geometry changes
    if (this.activeGeometryStatus === 'invalid') {
      return false
    }

    return super.handleUpEvent(event)
  }

  /**
   * Clear all the internal subscription and pointers when the Interaction is disposed.
   */
  override dispose() {
    this.disableKeyboardEvents()

    const view = this.getMap()?.getViewport()
    view?.classList.remove('ol-pointer-draw-valid')
    view?.classList.remove('ol-pointer-draw-invalid')
    view?.classList.remove('ol-pointer-draw-close-shape')

    this.un('change:active', this.handleChangeActiveState)
    this.un('drawstart', this.handleDrawStart)
    this.un('drawend', this.handleDrawEnd)
    this.un('drawabort', this.handleDrawAbort)

    this.onAreaCreateSubscribers.clear()

    this.resetInteraction()

    super.dispose()
  }
}
