import { kmhToCms, mphToCms } from '@/local/server-data/utils/conversion'

export function patchDriverBehaviors(
  mutableDraft: uui.domain.client.gps.wwgps.DriverBehaviorSettings | undefined,
  formValues: uui.domain.ui.forms.DeviceData,
  metric: boolean,
) {
  if (!mutableDraft) return

  const {
    maxSpeedLimit: { threshold: maxSpeedThreshold },
  } = formValues

  // to disable the speed data, we have to send a specific value
  // different values will not work as intended
  const speedDisableValueForServer = metric ? 324 : 201
  const maxSpeedThresholdNormalized =
    maxSpeedThreshold === -1 ? speedDisableValueForServer : maxSpeedThreshold
  const maxSpeedDisabledValueInCms = 8986

  const speedThreshold =
    maxSpeedThresholdNormalized === speedDisableValueForServer
      ? maxSpeedDisabledValueInCms
      : metric
        ? Math.round(kmhToCms(maxSpeedThresholdNormalized))
        : Math.round(mphToCms(maxSpeedThresholdNormalized))

  // acceleration
  mutableDraft.hardAcceleration.threshold = formValues.acceleration.hard
  mutableDraft.harshAcceleration.threshold = formValues.acceleration.harsh
  mutableDraft.severeAcceleration.threshold = formValues.acceleration.severe

  // braking
  mutableDraft.hardBraking.threshold = formValues.braking.hard
  mutableDraft.harshBraking.threshold = formValues.braking.harsh
  mutableDraft.severeBraking.threshold = formValues.braking.severe

  // cornering
  mutableDraft.hardCornering.threshold = formValues.cornering.hard

  mutableDraft.speedThreshold.threshold = speedThreshold

  return mutableDraft
}
