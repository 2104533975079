import { SchedulerProConfig } from '@bryntum/schedulerpro'
import { differenceInSeconds } from 'date-fns/esm'
import { updateVehicleTimeWindow } from '@/features/domain/vehicle'
import { store } from '@/store'

export const onBeforeEventResizeFinalize: SchedulerProConfig['onBeforeEventResizeFinalize'] =
  event => {
    const { source, endDate, finalize, startDate, eventRecord } = event

    const resourceRecord = source.resourceStore.getById(eventRecord.resourceId)

    if (!resourceRecord) return

    const date = resourceRecord.getData('dateAsString') as string
    const vehicleId = resourceRecord.getData('vehicleId') as string

    // eslint-disable-next-line no-param-reassign
    event.async = true

    const targetLocked = resourceRecord.getData('routeLock')

    if (targetLocked) {
      finalize(false)
      return
    }

    const day = new Date(startDate)
    day.setHours(0, 0, 0)

    const timeWindow = {
      startSec: differenceInSeconds(startDate, day),
      endSec: differenceInSeconds(endDate, day),
    }

    store.dispatch(updateVehicleTimeWindow({ date, vehicleId, timeWindow })).then(result => {
      finalize(updateVehicleTimeWindow.fulfilled.match(result))
    })
  }
