import { useSelector } from 'react-redux'
import { Typography, Checkbox, FormControlLabel } from '@mui/material'

import { useIsSimulation } from '@/hooks'
import {
  selectTelematicsDeviceTransientStatuses,
  selectTelematicsTenantSources,
} from '@/features/domain/account'
import {
  ReadonlyLabel,
  ReadonlyBlock,
  ReadonlyField,
  ReadonlyFieldRow,
  ReadonlyFieldLabel,
} from '@/forms-legacy'

import { getDeviceId } from '../../../../../utils/getDeviceId'
import { useTexts } from '../hooks/useTexts'

interface Props {
  unifiedVehicle: uui.domain.client.UnifiedVehicle
}

export function TelematicsTrackingSection(props: Props) {
  const { unifiedVehicle } = props

  const deviceTransientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const tenantSources = useSelector(selectTelematicsTenantSources)
  const isSimulation = useIsSimulation()
  const texts = useTexts()

  // Retreive the device id
  const deviceId = getDeviceId(unifiedVehicle) ?? ''

  // Check if the vehicle has a device
  const hasDevice = !!deviceId

  // Retreive the physical device
  const device = unifiedVehicle.isPhysicalDevice
    ? (unifiedVehicle.device as uui.domain.client.gps.telematics.DeviceInfo)
    : undefined

  const deviceLabel = device?.deviceLabel ?? device?.deviceId ?? ''

  const tenantSource = device ? tenantSources[device.tenantSourceId] : undefined
  const activationStatus = device
    ? unifiedVehicle.hasRoutingLicense
      ? (deviceTransientStatuses[device.deviceId]?.status ?? undefined)
      : undefined
    : undefined

  const activationStatusMessage =
    activationStatus === 'ERROR'
      ? texts.somethingWentWrongWithTheDevice
      : activationStatus === 'PENDING-ACTIVATION'
        ? texts.deviceActivationPending
        : undefined

  const activationStatusMessageColor =
    activationStatus === 'ERROR'
      ? 'error'
      : activationStatus === 'PENDING-ACTIVATION'
        ? 'primary'
        : undefined

  return (
    <>
      {!isSimulation && (
        <FormControlLabel
          slotProps={{
            typography: {
              fontSize: '0.875rem',
            },
          }}
          label={texts.trackTheVehicle}
          control={<Checkbox size="small" readOnly disabled={!hasDevice} checked={hasDevice} />}
          sx={{ mb: 1, pointerEvents: 'none', cursor: 'pointer' }}
        />
      )}

      {tenantSource ? (
        Object.keys(tenantSources).length === 1 ? (
          <>
            <ReadonlyBlock>
              <ReadonlyLabel>{texts.youAreUsingIntegration(tenantSource.label)}</ReadonlyLabel>
            </ReadonlyBlock>
            {tenantSource.statusCode === 2000 && !tenantSource.active ? (
              <ReadonlyBlock>
                <Typography
                  color="primary"
                  fontWeight={600}
                  variant="caption"
                  data-testid="setup-vehicle__telematics-device-activation-status"
                >
                  {texts.integrationDeleteInProgress}
                </Typography>
              </ReadonlyBlock>
            ) : null}
          </>
        ) : (
          <ReadonlyBlock>
            <ReadonlyLabel>{texts.integration}</ReadonlyLabel>
            <ReadonlyField>
              <ReadonlyFieldRow>
                <ReadonlyFieldLabel>{tenantSource.label}</ReadonlyFieldLabel>
              </ReadonlyFieldRow>
            </ReadonlyField>
          </ReadonlyBlock>
        )
      ) : null}

      {hasDevice && !isSimulation && (
        <ReadonlyBlock>
          <ReadonlyLabel>{texts.assignedGpsDevice}</ReadonlyLabel>
          <ReadonlyField>
            <ReadonlyFieldRow>
              <ReadonlyFieldLabel>{deviceLabel}</ReadonlyFieldLabel>
            </ReadonlyFieldRow>
          </ReadonlyField>
        </ReadonlyBlock>
      )}

      {activationStatus && (
        <Typography
          color={activationStatusMessageColor}
          variant="caption"
          fontWeight={600}
          data-testid="setup-vehicle__telematics-device-activation-status"
        >
          {activationStatusMessage}
        </Typography>
      )}
    </>
  )
}
