import { type SchedulerProConfig, SubGrid } from '@bryntum/schedulerpro'
import { getSchedulerColumnAtom } from '../../atoms/columns'
import { schedulerInstance } from '../../atoms/project/project'
import { MIN_AVAILABLE_SPACE_FOR_FIXED_REGIONS } from './constants'

export function createSubGridConfigs(): SchedulerProConfig['subGridConfigs'] {
  const { columnsSectionWidth } = getSchedulerColumnAtom(true)
  return {
    // set a fixed locked section width if desired
    id: {
      width: 59,
      weight: 0,
      maxWidth: 59,
      minWidth: 59,
      disabled: true,
      resizable: false,
      scrollable: false,
    },

    cols: {
      collapsed: columnsSectionWidth === 0,
      resizable: true,
      weight: 1,
      width: columnsSectionWidth,
      onPaint: ({ source }) => {
        const subGrid = source as SubGrid

        const splitterElement = subGrid.element.nextSibling as HTMLElement
        if (subGrid.collapsed) {
          splitterElement.classList.remove('b-grid-splitter-collapsed')
          subGrid.element.classList.remove('b-grid-subgrid-collapsed')
          subGrid.expand(false)
          subGrid.width = 0
        }
        splitterElement.setAttribute('data-trackid', 'scheduler-splitter')
        splitterElement.setAttribute('data-testid', 'scheduler-splitter')

        const collapseButton = splitterElement.querySelector('.b-grid-splitter-button-collapse')
        const expandButton = splitterElement.querySelector('.b-grid-splitter-button-expand')
        collapseButton?.setAttribute('data-trackid', 'scheduler-collapse-button')
        collapseButton?.setAttribute('data-testid', 'scheduler-collapse-button')
        expandButton?.setAttribute('data-trackid', 'scheduler-expand-button')
        expandButton?.setAttribute('data-testid', 'scheduler-expand-button')
      },

      // Control the visibility of both splitter buttons based on the resize
      onResize: ({ source }) => {
        if (!schedulerInstance.instance) return

        const subGrid = source as SubGrid
        const splitterElement = subGrid.element.nextSibling as HTMLElement
        const splitterButtons = splitterElement.querySelector('[data-reference="splitterButtons"]')

        if (subGrid.width === 0) {
          splitterElement?.classList.add('b-grid-splitter-allow-collapse')
          splitterButtons?.classList.add('expand-only')
          subGrid.element.classList.add('b-grid-subgrid-collapsed')
          return
        }

        splitterElement?.classList.remove(
          'b-grid-splitter-allow-collapse',
          'b-grid-splitter-expanded',
          'b-grid-splitter-allow-expand',
        )
        splitterButtons?.classList.remove('expand-only', 'collapse-only')
        subGrid.element.classList.remove('b-grid-subgrid-collapsed', 'b-grid-subgrid-expanded')

        // Calculate the maximum possible width for the columns and set it
        const schedulerWidth =
          schedulerInstance.instance.element.querySelector('.b-grid-body-container')?.clientWidth ??
          (schedulerInstance.instance.width as number)

        if (schedulerWidth - subGrid.width <= MIN_AVAILABLE_SPACE_FOR_FIXED_REGIONS) {
          splitterButtons?.classList.add('collapse-only')
          splitterElement?.classList.add('b-grid-splitter-expanded', 'b-grid-splitter-allow-expand')
          subGrid.element.classList.add('b-grid-subgrid-expanded')
        }
      },
    },

    final: { resizable: false, weight: 2, width: 66, maxWidth: 66, minWidth: 66, disabled: true },

    normal: { resizable: false, weight: 3 },
  }
}
