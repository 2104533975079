import { type ColumnStore, type SchedulerPro, type SchedulerProConfig } from '@bryntum/schedulerpro'
import { useEffect, useRef } from 'react'

import { MovePairedOrdersPayload } from '@/components/modals/MovePairedOrdersModal'
import { ControlledNavigateFunction } from '@/routing'

import { schedulerInstance } from '../../../atoms/project/project'
import { startSchedulerSync } from '../../../sync'
import { createScheduler } from '../../../utils'

import { persistSettings } from '../utils/persistSettings'
import { setInitialSchedulerZoom } from '../utils/setInitialSchedulerZoom'

export function useInitializeScheduler(
  targetId: string,
  navigate: ControlledNavigateFunction<uui.routing.Routing | uui.routing.Setup>,
  showMovePairedOrdersModal: (payload: MovePairedOrdersPayload) => void,
  onHeaderMenuBeforeShow: SchedulerProConfig['onHeaderMenuBeforeShow'],
) {
  const firstDestroyDone = useRef<boolean>(false)

  useEffect(() => {
    // HACK: We need to prevent a save on first destroy (caused by React 18)
    if (process.env.NODE_ENV === 'development') {
      if (!firstDestroyDone.current) {
        firstDestroyDone.current = true
        return
      }
    }

    // Create scheduler
    schedulerInstance.instance = createScheduler(
      targetId,
      navigate,
      showMovePairedOrdersModal,
      onHeaderMenuBeforeShow,
    )

    // Start data sync
    const stopDataSync = startSchedulerSync(schedulerInstance.instance)

    // Set initial zoom
    setInitialSchedulerZoom(schedulerInstance.instance)

    // Add event listener for splitter double click
    schedulerInstance.instance.element
      .querySelector('[data-region="cols"][data-reference="splitterElement"]')
      ?.addEventListener('dblclick', onSplitterDblClick.bind(null, schedulerInstance.instance))

    return () => {
      // Unsuscribe from data sync
      stopDataSync()

      // Store last visible zoom before destroying the instance
      persistSettings(schedulerInstance.instance)

      // Destroy the instance
      schedulerInstance.instance?.destroy()
      schedulerInstance.instance = undefined
    }
  }, [targetId, navigate, showMovePairedOrdersModal, onHeaderMenuBeforeShow])

  return schedulerInstance.instance
}

function onSplitterDblClick(scheduler: SchedulerPro) {
  const columnStore = scheduler.columns as ColumnStore

  if (!columnStore) return

  // The new width is the sum of all the widths of the visible columns
  const newWidth = columnStore.visibleColumns.reduce<number>((acc, col) => {
    if (col.getData('region') !== 'cols') return acc
    if (typeof col.width !== 'number') return acc

    return acc + col.width
  }, 0)

  const maxPossibleWidth =
    (scheduler.width as number) - scheduler.subGrids.id.width - scheduler.subGrids.final.width

  scheduler.subGrids.cols.width = Math.min(newWidth, maxPossibleWidth)
}
