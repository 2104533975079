import { useIntl } from '@/intl'
import { useState } from 'react'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    headerDescription: translate({
      id: 'vehicles.form.routingVehicleHeader',
    }),
    eventTracking: (hasTracking?: boolean) =>
      translate({
        id: hasTracking
          ? 'vehicles.form.generalSettings.eventTrackingOn'
          : 'vehicles.form.generalSettings.eventTrackingOff',
      }),
    liveEta: (hasETA?: boolean) =>
      translate({
        id: hasETA ? 'vehicles.form.generalSettings.ETAOn' : 'vehicles.form.generalSettings.ETAOff',
      }),
    ssosr: (hasSsosr?: boolean) =>
      translate({
        id: hasSsosr
          ? 'vehicles.form.generalSettings.ssosrOn'
          : 'vehicles.form.generalSettings.ssosrOff',
      }),
    trackingOption: translate({
      id: 'vehicles.form.generalSettings.trackingOption',
    }),
    trackingOptionValue: (hasPhysicalDevice?: boolean) =>
      translate({
        id: hasPhysicalDevice
          ? 'vehicles.form.generalSettings.trackingOption.physical'
          : 'vehicles.form.generalSettings.trackingOption.mobile',
      }),
    assignedGpsDevice: translate({
      id: 'vehicles.form.generalSettings.assignedGpsDevice',
    }),
    expanding: translate({ id: 'vehicles.form.generalSettings.gpsExpandingTitle' }),
    deviceSettings: (deviceId: string) =>
      translate({
        id: 'vehicles.form.generalSettings.deviceSettings',
        values: {
          deviceId,
        },
      }),
    deletePanelTitle: translate({
      id: 'vehicles.form.generalSettings.deletePanel.title',
    }),
    deletePanelDescription: translate({
      id: 'vehicles.form.generalSettings.deletePanel.description',
    }),
    delete: translate({
      id: 'global.delete',
    }),
    clone: translate({
      id: 'global.clone',
    }),
    edit: translate({
      id: 'global.edit',
    }),
    cloneVehicles: (maximum: number) =>
      translate({
        id: 'vehicles.form.generalSettings.clonePanel.title',
        values: { maximum },
      }),

    disabledCloneTooltipTitle: (isSimulation: boolean) => {
      const id = isSimulation
        ? 'vehicles.form.generalSettings.clonePanel.disabled.simulations.title'
        : 'vehicles.form.generalSettings.clonePanel.disabled.operations.title'
      return translate({ id })
    },

    disabledCloneTooltipSubtitle: (isSimulation: boolean, count: number) => {
      const id = isSimulation
        ? 'vehicles.form.generalSettings.clonePanel.disabled.simulations.subtitle'
        : 'vehicles.form.generalSettings.clonePanel.disabled.operations.subtitle'
      return translate({ id, values: { count } })
    },

    trackTheVehicle: translate({ id: 'vehicles.form.generalSettings.trackTheVehicle' }),
    youAreUsingIntegration: (label: string) =>
      translate({
        id: 'vehicles.form.generalSettings.youAreUsingIntegration',
        values: { label },
      }),
    somethingWentWrongWithTheDevice: translate({
      id: 'vehicles.form.generalSettings.somethingWentWrongWithTheDevice',
    }),
    deviceActivationPending: translate({
      id: 'vehicles.form.generalSettings.deviceActivationInProgress',
    }),
    integration: translate({
      id: 'vehicles.form.generalSettings.integration',
    }),
    integrationDeleteInProgress: translate({
      id: 'vehicles.form.generalSettings.integrationDeleteInProgress',
    }),
  }))

  return api
}
