import { Component, MouseEvent } from 'react'

import { clsx } from '@/utils'
import { Props } from './typings'

export default class Button extends Component<Props> {
  static defaultProps: Partial<Props> = {
    disabled: false,
    className: '',
    extraClassName: '',
    usePreventDefault: true,
    useStopPropagation: true,
  }

  private handleClick = (e: MouseEvent<HTMLButtonElement>): void => {
    const { onClick, useStopPropagation, usePreventDefault } = this.props
    if (onClick) {
      if (usePreventDefault) {
        e.preventDefault()
      }
      if (useStopPropagation) {
        e.stopPropagation()
      }
      onClick(e)
    }
  }

  private handleIconClick = (e: MouseEvent<HTMLElement>): void => {
    const { onClickIcon, useStopPropagation, usePreventDefault } = this.props
    if (onClickIcon) {
      if (usePreventDefault) {
        e.preventDefault()
      }
      if (useStopPropagation) {
        e.stopPropagation()
      }
      onClickIcon(e)
    }
  }

  private renderIcon() {
    const { icon } = this.props
    if (!icon) return null
    return (
      <span onClick={this.handleIconClick} className="o-button__ico">
        {icon}
      </span>
    )
  }

  render() {
    const {
      disabled = false,
      className = '',
      extraClassName = '',
      children,
      style,
      type = 'button',
      title = '',
      testid,
    } = this.props

    const rootClassName = clsx({
      [className]: true,
      [extraClassName]: true,
      'o-button': true,
    })

    return (
      <button
        className={rootClassName}
        onClick={this.handleClick}
        disabled={disabled}
        type={type}
        style={style}
        title={title}
        data-testid={testid}
        data-trackid={testid}
      >
        <span className="o-button__inner">
          {this.renderIcon()}
          <span className="o-button__children">{children}</span>
        </span>
      </button>
    )
  }
}
