import type { PartialStoreState } from '../typings'

import { createSelector } from '@reduxjs/toolkit'
import { selectGpsTrackingProvider } from '../user'

export const selectDevicesAggregateInfo = (state: PartialStoreState) =>
  state.domain.publicData.domain.gps.wwgps.devicesAggregateInfo

export const selectTelematicsDevices = (state: PartialStoreState) =>
  state.domain.publicData.domain.gps.telematics.deviceInfos

export const selectTelematicsDevicesAsArray = createSelector(selectTelematicsDevices, devices =>
  Object.values(devices),
)

export const selectTelematicsDevicesAsArrayWithActiveStatus = createSelector(
  selectTelematicsDevices,
  devices => Object.values(devices).filter(d => d.active),
)

const selectWwgpsLivePositions = (state: PartialStoreState) =>
  state.domain.publicData.domain.gps.wwgps.livePositions

const selectTelematicsLivePositions = (state: PartialStoreState) =>
  state.domain.publicData.domain.gps.telematics.livePositions

export const selectLivePositions = createSelector(
  selectGpsTrackingProvider,
  selectWwgpsLivePositions,
  selectTelematicsLivePositions,
  (trackingProvider, wwgpsLivePositions, telematicsLivePositions) => {
    return trackingProvider === 'WWGPS'
      ? wwgpsLivePositions
      : trackingProvider === 'telematics'
        ? telematicsLivePositions
        : {}
  },
)

export const selectDevices = createSelector(
  selectDevicesAggregateInfo,
  selectTelematicsDevices,
  selectGpsTrackingProvider,
  (wwgpsdevicesAggregateInfo, telematicsDevices, trackingProvider) => {
    switch (trackingProvider) {
      case 'WWGPS':
        return Object.values(wwgpsdevicesAggregateInfo).reduce<
          Record<string, uui.domain.client.gps.wwgps.DeviceInfo>
        >(
          (acc, curr) => {
            acc[curr.device.deviceId] = curr.device
            return acc
          },
          {} as Record<string, uui.domain.client.gps.wwgps.DeviceInfo>,
        )

      case 'telematics':
        return telematicsDevices

      case 'none':
      default:
        return {}
    }
  },
)

export const selectPhysicalDevices = createSelector(
  selectDevicesAggregateInfo,
  selectTelematicsDevices,
  selectGpsTrackingProvider,
  (wwgpsdevicesAggregateInfo, telematicsDevices, trackingProvider) => {
    switch (trackingProvider) {
      case 'WWGPS':
        return Object.values(wwgpsdevicesAggregateInfo)
          .filter(d => !d.device.virtual)
          .map(d => d.device)

      case 'telematics':
        return Object.values(telematicsDevices)

      case 'none':
      default:
        return []
    }
  },
)

export const selectHasNoPhysicalDevices = createSelector(selectPhysicalDevices, physicalDevices => {
  return physicalDevices.length === 0
})

export const selectDevicesList = createSelector(
  selectDevicesAggregateInfo,
  selectTelematicsDevices,
  selectGpsTrackingProvider,
  (wwgpsdevicesAggregateInfo, telematicsDevices, trackingProvider) => {
    switch (trackingProvider) {
      case 'WWGPS':
        return Object.values(wwgpsdevicesAggregateInfo).reduce<
          Record<string, uui.domain.client.gps.wwgps.DeviceInfo>
        >((acc, deviceAggregateInfo) => {
          acc[deviceAggregateInfo.device.deviceId] = deviceAggregateInfo.device

          return acc
        }, {})

      case 'telematics':
        return Object.values(telematicsDevices)

      case 'none':
      default:
        return []
    }
  },
)

export const selectPhysicalDevicesList = createSelector(
  selectDevicesAggregateInfo,
  selectTelematicsDevices,
  selectGpsTrackingProvider,
  (wwgpsdevicesAggregateInfo, telematicsDevices, trackingProvider) => {
    switch (trackingProvider) {
      case 'WWGPS':
        return Object.values(wwgpsdevicesAggregateInfo).reduce<
          uui.domain.client.gps.wwgps.DeviceInfo[]
        >((acc, d) => {
          if (!d.device.virtual) {
            acc.push(d.device)
          }
          return acc
        }, [])

      case 'telematics':
        return Object.values(telematicsDevices)

      case 'none':
      default:
        return []
    }
  },
)

export const selectVirtualDevicesList = createSelector(
  selectDevicesAggregateInfo,
  selectGpsTrackingProvider,
  (wwgpsdevicesAggregateInfo, trackingProvider) => {
    switch (trackingProvider) {
      case 'WWGPS':
        return Object.values(wwgpsdevicesAggregateInfo).reduce<
          uui.domain.client.gps.wwgps.DeviceInfo[]
        >((acc, d) => {
          if (d.device.virtual) {
            acc.push(d.device)
          }
          return acc
        }, [])

      case 'telematics':
      case 'none':
      default:
        return []
    }
  },
)

export const selectDevicesNotAssignedToRmVehicles = createSelector(
  selectDevicesAggregateInfo,
  devicesAggregateInfo => {
    return Object.values(devicesAggregateInfo).reduce<
      Record<string, uui.domain.client.gps.wwgps.DeviceInfo>
    >((acc, curr) => {
      if (curr.associatedVehicleId || curr.device.virtual) return acc

      acc[curr.device.deviceId] = curr.device
      return acc
    }, {})
  },
)

export const selectDeviceIdsByTenantSource = (state: PartialStoreState) =>
  state.domain.publicData.domain.gps.telematics.deviceIdsByTenantSource
