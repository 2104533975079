import type { EditingType } from './types'
import { useState } from 'react'
import { useIntl } from '@/intl'

export function useTexts() {
  const { translate } = useIntl()

  const [api] = useState(() => ({
    logout: translate({ id: 'app.header.logout' }),
    settings: translate({ id: 'v2.settings.sidebar.settings' }),
    settingsArea: translate({ id: 'app.header.settingsArea' }),
    editInProgressTitle: (gpsOnly: boolean, editingType: EditingType) => {
      if (editingType === 'none') return ''

      return translate({
        id: gpsOnly
          ? 'app.header.logout.editingInProgressGpsOnly.title'
          : editingType === 'transaction'
            ? 'app.header.logout.editingInProgress.title'
            : 'app.header.logout.editingInProgress.noSession.title',
      })
    },
    editInProgressSubtitle: (gpsOnly: boolean) =>
      translate({
        id: gpsOnly
          ? 'app.header.logout.editingInProgressGpsOnly.subtitle'
          : 'app.header.logout.editingInProgress.subtitle',
      }),

    changelog: translate({ id: 'app.header.debugTools.changelog' }),
    debugTools: translate({ id: 'app.header.debugTools' }),
    requestMaker: translate({ id: 'app.header.debugTools.requestMaker' }),
  }))

  return api
}
