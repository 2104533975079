import styled from 'styled-components'

type Props = {
  disabled?: boolean
  checked?: boolean
}

export const CheckboxIcon = styled.span<Props>`
  display: block;
  width: 12px;
  height: 12px;
  overflow: hidden;
  pointer-events: ${p => (p.disabled ? 'none' : 'all')};
  border-radius: 3px;
  border: solid
    ${p => `${p.checked ? 0 : 1}px ${p.disabled ? 'transparent' : p.theme.colors.$whiteSmoke}`};
  background: ${p =>
    p.disabled
      ? p.theme.colors.$alabaster
      : p.checked
        ? p.theme.colors.$scienceBlue
        : p.theme.colors.$alabaster};
`

CheckboxIcon.displayName = 'CheckboxIcon'
