import type { RouteDetailEntryEnd } from '../../../../../types'
import type { FormErrors, FormFields } from '../formFields'

import { useCallback } from 'react'

import { FormHelperText, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { AccessTime, HistoryToggleOff } from '@mui/icons-material'
import { useSelector } from 'react-redux'

import { useFormField } from '@workwave-tidal/tidal/form-fairy'
import { TimePicker } from '@workwave-tidal/tidal/components'

import { selectTerritory } from '@/features/domain/territory'
import { DriverEnd } from '@/icons'
import { Tooltip } from '@/components/primitives/Tooltip'
import { theme } from '@/styles'

import { useComputedLocation } from '../hooks/useComputedLocation'
import { useEventTimings } from '../hooks/useEventTimings'
import { useTexts } from '../hooks/useTexts'

interface Props {
  entry: RouteDetailEntryEnd
  driverId: string
  routeColor: string
  timeOptions: number[]
  timeFormat: '12' | '24'
}

export function EndEvent(props: Props) {
  const { entry, driverId, timeFormat, routeColor, timeOptions } = props

  const planned = entry.type === 'routeEnd' ? entry.data.planned : undefined
  const tracked = entry.type === 'routeEnd' ? entry.data.tracked : entry.data

  const { mobileAppsTrackingType } = useSelector(selectTerritory)
  const { plannedTime, trackedTime } = useEventTimings({
    planned: planned?.sec,
    tracked: tracked.sec,
  })
  const computedLocation = useComputedLocation(tracked.location ?? planned?.location ?? undefined)
  const { errors } = useFormField<'drivers', FormFields, FormErrors>('drivers')
  const texts = useTexts()

  const {
    field: { value },
    fieldApi,
  } = useFormField<'drivers', FormFields, FormErrors>('drivers')

  const onChange = useCallback(
    (value: number) => {
      const { value: drivers } = fieldApi.getField()
      drivers[driverId].end = value
      fieldApi.change(drivers)
      fieldApi.validate()
    },
    [fieldApi, driverId],
  )

  if (!value[driverId]) return null

  const endEndError = errors.find(error => error.id === `${driverId}_driver_end_end`)
  const endError = errors.find(error => error.id === `${driverId}_driver_end`)

  const commonErrorMessage = endEndError ? endEndError.message : undefined

  const plannedTooltipTitle = texts.routeEndPlannedTooltip
  const trackedTooltipTitle =
    trackedTime === '-'
      ? mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
        ? texts.routeEndWontBeCollectedTooltip
        : texts.routeEndNotCollectedTooltip
      : texts.routeEndCollectedTooltip

  const errorMessage = endError ? endError.message : commonErrorMessage

  const errorLabels = {
    emptyField: texts.formRouteEndEmpty,
    invalidFormat: texts.formRouteEndInvalid,
  }

  const iconColor =
    mobileAppsTrackingType === 'ONLY_ORDERSTEPS'
      ? theme.colors.$pureBlack
      : trackedTime !== '-'
        ? theme.colors.$shadyLady
        : routeColor

  const title = entry.type === 'routeEnd' ? texts.routeEnd : texts.driverEnd

  return (
    <Grid
      maxWidth="100%"
      container
      spacing={1}
      data-testid={entry.type === 'routeEnd' ? 'routeEndEvent' : 'routeDriverEndEvent'}
    >
      <Grid item xs={2} sx={{ color: iconColor }}>
        <DriverEnd size={32} />
      </Grid>

      <Grid item xs={10}>
        <Stack>
          <Typography variant="h6" data-testid="eventTitle">
            {title}
          </Typography>
          {computedLocation ? (
            <Typography
              variant="caption"
              color={computedLocation === '-' ? 'grey.600' : 'text.secondary'}
              data-testid="eventLocation"
            >
              {computedLocation}
            </Typography>
          ) : (
            <Skeleton width={100} height={20} animation="wave" data-testid="eventLocationLoader" />
          )}

          <Stack direction="column" spacing={1} mt={1} alignItems="flex-start">
            {entry.type === 'routeEnd' && (
              <Tooltip placement="top" title={plannedTooltipTitle}>
                <Stack direction="row" spacing={1} marginTop={1}>
                  <HistoryToggleOff
                    fontSize="small"
                    color={plannedTime === '-' ? 'disabled' : 'inherit'}
                  />
                  <Typography
                    fontSize={13}
                    color={plannedTime === '-' ? 'grey.600' : 'text'}
                    data-testid="eventPlannedTime"
                  >
                    {plannedTime}
                  </Typography>
                </Stack>
              </Tooltip>
            )}

            {trackedTime !== '-' ? (
              <>
                <TimePicker
                  name="driverEnd"
                  label={texts.formRouteEnd}
                  value={value[driverId].end}
                  error={errorMessage ? errorMessage : undefined}
                  format={timeFormat}
                  testId="endRouteExecution"
                  onChange={onChange}
                  errorLabels={errorLabels}
                  optionValues={timeOptions}
                  errorVisibility="accent-only"
                  allowUnlistedValues
                  // TODO: This is a temporary fix for the issue with the time picker not being able to be displayed
                  componentsProps={{
                    popper: {
                      sx: {
                        zIndex: 9999,
                      },
                    },
                  }}
                />
                {errorMessage && <FormHelperText error>{errorMessage}</FormHelperText>}
              </>
            ) : (
              <Tooltip placement="top" title={trackedTooltipTitle}>
                <Stack direction="row" spacing={1}>
                  <AccessTime
                    fontSize="small"
                    color={trackedTime === '-' ? 'disabled' : 'inherit'}
                  />
                  <Typography
                    fontSize={13}
                    data-testid="eventTrackedTime"
                    color={trackedTime === '-' ? 'grey.600' : 'text'}
                  >
                    {trackedTime}
                  </Typography>
                </Stack>
              </Tooltip>
            )}
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  )
}
