import styled from 'styled-components'

type Props = {
  half?: boolean
  error?: boolean
  dirty?: boolean
  warning?: boolean
}

const Block = styled.div<Props>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: ${p => (p.half ? '44%' : '100%')};
  margin: 5px 0;

  > * + * {
    margin-top: 5px;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: -12px;
    width: 2px;
    height: 100%;
    background: ${p =>
      p.error
        ? p.theme.colors.$outrageousRed
        : p.warning
          ? p.theme.colors.$darkOrange
          : p.dirty
            ? p.theme.colors.$scienceBlue
            : 'transparent'};
  }
`

Block.displayName = 'Block'

export default Block
