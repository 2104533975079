import { type EventModel, type EventDragConfig } from '@bryntum/schedulerpro'
import { renderToStaticMarkup } from 'react-dom/server'
import { addSeconds } from 'date-fns/esm'
import { type ChipProps, DragTooltipInvalid, DragTooltip } from './components/DragTooltip'

function getChipPropsFromEvent(eventRecord: EventModel | undefined): ChipProps | undefined {
  if (!eventRecord) return

  const type = eventRecord.getData('type') as uui.domain.client.rm.SchedulerEvent['type']
  const color = eventRecord.getData('routeColor') as string
  const reload = eventRecord.getData('atDepot') as boolean

  return {
    name: eventRecord.getData('label') as string,
    color,
    isBreak: type === 'brk',
    isReload: reload,
  }
}

function sortEventsByStartDate(a: EventModel, b: EventModel) {
  // @ts-expect-error
  return a.startDate - b.startDate
}

function isCompatibleStep(event: EventModel) {
  const type = event.getData('type') as uui.domain.client.rm.SchedulerEvent['type']
  const isUnApproved = event.getData('isApproved') === false
  const isCompatible = ['pickup', 'delivery', 'brk'].includes(type)
  return isUnApproved && isCompatible
}

export const tooltipTemplate: EventDragConfig['tooltipTemplate'] = args => {
  const {
    valid,
    // @ts-expect-error
    dragData: { newResource, screenSourceDate, timeDiff },
    eventRecord,
  } = args

  if (!valid) {
    return renderToStaticMarkup(<DragTooltipInvalid />)
  }

  const mouseDate = addSeconds(screenSourceDate, timeDiff / 1000)

  const routeEvents = newResource.events
    .filter(isCompatibleStep)
    .sort(sortEventsByStartDate) as EventModel[]

  const label = eventRecord.getData('serviceTimeLabel')
  if (routeEvents.length === 0) return label

  const nearestIndexAfterMouseDate = routeEvents.findIndex(
    e => e.startDate > mouseDate && e.id !== eventRecord.id,
  )
  const nearestIndexBeforeMouseDate =
    nearestIndexAfterMouseDate >= 0
      ? routeEvents[nearestIndexAfterMouseDate - 1]?.id === eventRecord.id
        ? -1
        : nearestIndexAfterMouseDate - 1
      : routeEvents[routeEvents.length - 1]?.id === eventRecord.id
        ? -1
        : routeEvents.length - 1

  const prevChipEvent =
    nearestIndexBeforeMouseDate === -1 ? undefined : routeEvents[nearestIndexBeforeMouseDate]

  const nextChipEvent =
    nearestIndexAfterMouseDate === -1 ? undefined : routeEvents[nearestIndexAfterMouseDate]

  const prevChip = getChipPropsFromEvent(prevChipEvent)
  const nextChip = getChipPropsFromEvent(nextChipEvent)

  return renderToStaticMarkup(
    <DragTooltip prevChip={prevChip} nextChip={nextChip}>
      {label}
    </DragTooltip>,
  )
}
