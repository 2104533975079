import type { CardCosmetic } from '../typings'

import { Button, styled } from '@mui/material'

import { theme } from '@/styles'

import { applyAlphaToColor } from '@/local/components'

import { cardSize } from '../constants'

type CalenderClassName = 'o-calendar__card'

type Props = {
  size?: number
  cosmetic?: CardCosmetic
  ghostSelectionEnd?: boolean
  ghostSelectionStart?: boolean
  secondaryRangeStartRepresentation?: boolean
  disabled?: boolean
  className: CalenderClassName
}

export const Card = styled(Button, {
  name: 'Card',
  shouldForwardProp: prop =>
    prop !== 'size' &&
    prop !== 'cosmetic' &&
    prop !== 'ghostSelectionEnd' &&
    prop !== 'ghostSelectionStart' &&
    prop !== 'secondaryRangeStartRepresentation',
})<Props>(
  ({ size, cosmetic, ghostSelectionStart, disabled, secondaryRangeStartRepresentation }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    padding: 0,
    outline: 0,
    margin: 0,
    borderWidth: 0,
    borderRadius: '3px',

    background:
      cosmetic === 'edge-of-selection'
        ? applyAlphaToColor(theme.colors.$scienceBlue, secondaryRangeStartRepresentation ? 0.1 : 1)
        : cosmetic === 'hovered'
          ? applyAlphaToColor(theme.colors.$scienceBlue, 0.1)
          : ghostSelectionStart
            ? applyAlphaToColor(theme.colors.$scienceBlue, 0.1)
            : 'transparent',

    color:
      cosmetic === 'edge-of-selection' && !secondaryRangeStartRepresentation
        ? theme.colors.$pureWhite
        : cosmetic === 'hovered'
          ? theme.colors.$scienceBlue
          : theme.colors.$nightRider,

    minWidth: `${size || cardSize}px`,
    maxWidth: `${size || cardSize}px`,
    height: `${size || cardSize}px`,
    textAlign: 'center',
    zIndex: 2,

    cursor: disabled ? 'default' : 'pointer',

    '&:after': {
      content: '""',
      display: cosmetic === 'grayed-out' ? 'block' : 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: applyAlphaToColor(theme.colors.$pureWhite, 0.85),
      zIndex: 5,
    },

    transition: 'background 0.2s ease-out, color 0.25s ease-out',

    '&:hover': {
      color: cosmetic === 'edge-of-selection' ? theme.colors.$pureWhite : theme.colors.$scienceBlue,

      background:
        cosmetic === 'edge-of-selection'
          ? applyAlphaToColor(theme.colors.$scienceBlue, 0.75)
          : cosmetic === 'normal'
            ? applyAlphaToColor(theme.colors.$scienceBlue, 0.1)
            : ghostSelectionStart
              ? applyAlphaToColor(theme.colors.$scienceBlue, 0.1)
              : 'transparent',
    },

    '& > *': {
      pointerEvents: 'none',
    },

    '& .MuiTouchRipple-root': {
      display: 'none',
    },
  }),
)
