import type VectorSource from 'ol/source/Vector'
import type VectorLayer from 'ol/layer/Vector'
import type { SegmentType } from './utils/color'

import Modify, { ModifyEvent } from 'ol/interaction/Modify'

import MapBrowserEvent from 'ol/MapBrowserEvent'

import LineString from 'ol/geom/LineString'
import { Coordinate } from 'ol/coordinate'
import { ObjectEvent } from 'ol/Object'
import Geometry from 'ol/geom/Geometry'
import Feature from 'ol/Feature'

import { gis } from '@/server-data'

import { getFeatureCoordinates } from './utils/getFeatureCoordinates'
import { drawFeatureMetadata } from './utils/drawFeatureMetadata'
import { computePositions } from './utils/computePositions'
import { createStyle } from './Style/createStyle'

import { MAX_LENGTH_IN_METERS } from './constants'

type GeometryStatus =
  // The RoadSegment is valid and can be saved/closed
  | 'valid'
  // The RoadSegment is invalid and cannot be saved/closed
  | 'invalid'

// RoadSegment Change unsubscribe function
type Unsubscriber = () => void

// RoadSegment Change subscribe function
type OnRoadSegmentChangeSubscriber = (
  editableSegment: { start: uui.domain.LatLng; end: uui.domain.LatLng },
  feature: Feature<Geometry>,
  lastChanged: number,
) => void

interface Options {
  type: SegmentType
}

function noop() {
  return false
}

/**
 * TODO: Main description
 */
export class ModifyEditableRoadSegmentInteraction extends Modify {
  constructor(
    protected drawLayer: VectorLayer<VectorSource>,
    { type }: Options,
  ) {
    super({
      // inherit the Vector Source from the provided draw layer
      source: drawLayer.getSource() ?? undefined,
      insertVertexCondition: noop,
      style: [],
    })

    // set the drawing layer main color
    this.updateColor(type)

    // listen to change toi the Active state
    this.on('change:active', this.handleChangeActiveState)
  }

  // ---------------------------------------------------------------
  // ADDITIONAL API
  // ---------------------------------------------------------------

  /**
   * Change the color of the RoadSegment.
   *
   * @param color Hexadecimal or RGB string of the color the Shape must have.
   */
  public updateColor(type: SegmentType) {
    this.drawLayer.setStyle(createStyle(type))
  }

  /**
   * Subscribe to the Changes to an RoadSegment.
   *
   * @param subscriber The function that will be invoked when an RoadSegment is changed
   * @returns The function to unsubscribe
   */
  public onRoadSegmentChange(subscriber: OnRoadSegmentChangeSubscriber): Unsubscriber {
    this.onRoadSegmentChangeSubscribers.add(subscriber)

    return () => {
      this.onRoadSegmentChangeSubscribers.delete(subscriber)
    }
  }

  // ---------------------------------------------------------------
  // API exposed only for internal usage
  // ---------------------------------------------------------------

  /**
   * Receive coordinates of a vertex and reference to a Feature.
   * It allow the Interaction to be aware of the user clicking a vertex or a segment.
   *
   * @param coords Pointer coordinates using the Open Layers coordinate system
   * @param targetFeature Feature under the mouse
   */
  public internal_setActiveVertex(coords?: Coordinate, targetFeature?: Feature<Geometry>) {
    if (
      this.activeSelectedCoords?.at(0) === coords?.at(0) &&
      this.activeSelectedCoords?.at(1) === coords?.at(1)
    ) {
      return
    }

    // store the active coordinates or clear them if no coordinates are provided
    this.setActiveSelectedCoords(coords, undefined)

    if (!targetFeature) return

    if (!coords) {
      // if a Feature is provided without coordinates remove any custom field named 'activeIndex'
      drawFeatureMetadata.removeActiveIndex(targetFeature)
      return
    }

    const geometry = targetFeature.getGeometry()

    // set or unset the selected vertex
    // the addition or removal of points is managed by `refreshGeometry`
    if (geometry?.getType() === 'LineString') {
      const line = geometry as LineString

      // search the coordinates in the LineString
      const coordsIndex =
        line.getCoordinates()?.findIndex(([x, y]) => {
          return x === coords.at(0) && y === coords.at(1)
        }) ?? -1

      if (coordsIndex > -1) {
        // the user is clicking a vertex
        drawFeatureMetadata.setActiveIndex(targetFeature, coordsIndex)
        this.setActiveSelectedCoords(coords, coordsIndex)
      } else {
        // the user is clicking a segment
        drawFeatureMetadata.removeActiveIndex(targetFeature)
      }
    }
  }

  // ---------------------------------------------------------------
  // Internal Properties
  // ---------------------------------------------------------------

  /**
   * Reference to the Feature actively used by the Modify Interaction
   */
  protected activeFeature: Feature<Geometry> | undefined

  /**
   * Last valid Geometry of the modified RoadSegment.
   * It well be used to restore the RoadSegment if the changes produce an invalid Geometry.
   */
  protected activeFeaturePrevGeometry: Geometry | undefined

  /**
   * Array of LatLng points matching the active Feature coordinates.
   */
  protected activeGeometryPoints: uui.domain.Point[] = []

  /**
   * Validation status for the Geometry being drawn
   */
  protected activeGeometryStatus: GeometryStatus = 'valid'

  /**
   * Reference to the active vertex coordinates (both for moved or added points)
   */
  protected activeSelectedCoords: Coordinate | undefined

  /**
   * Reference to the active vertex index (both for moved or added points)
   */
  protected activeSelectedIndex: number | undefined

  /**
   * Flag used to track when the interaction is in REMOVE mode for the active vertex
   */
  protected removeModifier: boolean = false

  /**
   * Collection of subscribers for the `RoadSegment Change` Event.
   */
  protected onRoadSegmentChangeSubscribers = new Set<OnRoadSegmentChangeSubscriber>()

  // ---------------------------------------------------------------
  // INTERNAL EVENT HANDLERS
  // ---------------------------------------------------------------

  /**
   * Event handler reacting to the `active` status of the Interaction.
   */
  protected handleChangeActiveState = () => {
    if (this.getActive()) {
      this.on('modifystart', this.handleModifyStart)
      this.on('modifyend', this.handleModifyEnd)

      this.getMap()?.on('pointermove', this.handlePointerMove)
    } else {
      this.un('modifystart', this.handleModifyStart)
      this.un('modifyend', this.handleModifyEnd)
      this.getMap()?.un('pointermove', this.handlePointerMove)

      drawFeatureMetadata.removeActiveIndex(this.activeFeature)
      drawFeatureMetadata.setInvalid(this.activeFeature, false)
      this.activeFeature?.un('change', this.handleOnChangeGeometry)
      this.activeFeature = undefined
      this.activeGeometryStatus = 'valid'
      this.setActiveSelectedCoords(undefined, undefined)
    }

    this.updateCursor()
  }

  /**
   * Event handler reacting when the target RoadSegment change.
   * It's responsible to prepare the created Geometry and its target Feature before notifying
   * the subscribers to the change to the target RoadSegment.
   */
  protected handleOnRoadSegmentChange(_event: ModifyEvent, feature: Feature<Geometry>) {
    // timestamp linked to the change event
    const lastChanged = Date.now()

    // store the last changed timestamp into the feature as a custom field
    drawFeatureMetadata.setLastChanged(feature, lastChanged)

    const coords = getFeatureCoordinates(feature)

    // convert Open Layers coordinates into RM LatLng
    const segment = {
      start: gis.fromCoordinateToLatLng(coords[0]),
      end: gis.fromCoordinateToLatLng(coords[1]),
    }

    // notify subscribers the feature Geometry changed
    this.onRoadSegmentChangeSubscribers.forEach(sub => sub(segment, feature, lastChanged))
  }

  /**
   * Event handler reacting to the start of an edit session.
   */
  protected handleModifyStart = (event: ModifyEvent) => {
    // disable `pointer move` subscription during an active edit session
    event.mapBrowserEvent.map.un('pointermove', this.handlePointerMove)

    // clear CSS classes not needed during an edit session
    event.mapBrowserEvent.map.getViewport()?.classList.remove('ol-pointer-modify-remove-point')

    // if no features are in the event, just stop
    if (event.features.getLength() === 0) return

    // set the CSS to a MOVE vertex cursor
    event.mapBrowserEvent.map.getViewport()?.classList.add('ol-pointer-modify-move-point')

    // We know the feature will always allow to edit a SINGLE feature at the time
    // fetch the first and oly feature
    const featureLike = event.features.item(0)

    if (featureLike instanceof Feature) {
      // store reference to the Feature
      this.activeFeature = featureLike

      // store a copy of the original Feature's Geometry
      this.activeFeaturePrevGeometry = featureLike.getGeometry()?.clone()

      // subscribe to change event from the active Feature
      this.activeFeature.on('change', this.handleOnChangeGeometry)
    }
  }

  /**
   * Event handler reacting to the end of an edit session.
   */
  protected handleModifyEnd = (event: ModifyEvent) => {
    // clear CSS classes used during an edit session
    event.mapBrowserEvent.map.getViewport()?.classList.remove('ol-pointer-modify-move-point')

    if (this.activeFeature && this.activeFeaturePrevGeometry) {
      if (this.activeGeometryStatus === 'invalid') {
        // If the final Geometry is marked as invalid
        // The interaction rewrite the Feature Geometry using the last valid stored Geometry
        // No event will be emitted
        this.activeFeature.setGeometry(this.activeFeaturePrevGeometry)
      } else {
        // If the final Geometry is marked as valid
        // the interaction will notify the subscribers
        const geometry = this.activeFeature.getGeometry()

        if (geometry instanceof Geometry && geometry.getType() === 'LineString') {
          const line = geometry as LineString
          const length = line.getLength()

          if (length > MAX_LENGTH_IN_METERS) {
            const coords = [
              line.getCoordinateAt(0),
              line.getCoordinateAt(MAX_LENGTH_IN_METERS / length),
            ]

            line.setCoordinates(coords)
          }
        }
        this.handleOnRoadSegmentChange(event, this.activeFeature)
      }
    }

    this.resetInteraction()

    // restore IDLE subscription to pointer move events
    event.mapBrowserEvent.map.on('pointermove', this.handlePointerMove)
  }

  /**
   * Event handler reacting to any change in the Geometry in editing
   */
  protected handleOnChangeGeometry = (event: ObjectEvent) => {
    // process the updated Feature's geometry
    this.refreshGeometry(event.target as Feature<Geometry>)
  }

  /**
   * Event handler reacting to Pointer Move events
   * This event handler is active only when the editing is on IDLE
   */
  protected handlePointerMove = (event: MapBrowserEvent<PointerEvent>) => {
    // it shouldn't be possible but do nothing if the mouse is dragging
    if (event.dragging) return

    // get all the features under the mouse coordinates on the drawing layer
    const features = event.map.getFeaturesAtPixel(event.pixel, {
      hitTolerance: 10,
      // TODO: Should we make the UID dynamic as an option?
      layerFilter: layer => layer.get('uid') === 'draw',
    })

    // search for a feature with the `editable-roadSegment` custom field
    const feature = features.find(feat =>
      feat instanceof Feature ? drawFeatureMetadata.isEditableRoadSegment(feat) : false,
    )

    if (feature) {
      // extract the target feature coordinates
      const coords = getFeatureCoordinates(feature)
      const [eventX, eventY] = event.coordinate

      // loop over the feature coordinates
      // trying to identify if the pointer is over a feature's vertex
      for (const [pX, pY] of coords) {
        const dX = Math.abs(pX - eventX)
        const dY = Math.abs(pY - eventY)

        // tolerance is based on Open Layers internal coordinates system
        const tolerance = 9

        if (dX < tolerance && dY < tolerance) {
          if (!event.map.getViewport()?.classList.contains('ol-pointer-modify-move-point')) {
            event.map.getViewport()?.classList.add('ol-pointer-modify-move-point')
          }
          return
        }
      }

      // if the pointer is not over any vertex or segment clear all the CSS classes changing the mouse cursor
      event.map.getViewport()?.classList.remove('ol-pointer-modify-remove-point')
      event.map.getViewport()?.classList.remove('ol-pointer-modify-move-point')
    }
  }

  // ---------------------------------------------------------------
  // GEOMETRY MAINTENANCE
  // ---------------------------------------------------------------

  /**
   * Reset the internal state of the interaction
   */
  protected resetInteraction = () => {
    // set the 'invalid' custom field to FALSE
    drawFeatureMetadata.setInvalid(this.activeFeature, false)

    // unregister the subscription to the Geometry changes
    this.activeFeature?.un('change', this.handleOnChangeGeometry)
    // empty the pointer to the active feature
    this.activeFeature = undefined
    // set the geometry validity to VALID
    this.activeGeometryStatus = 'valid'

    this.removeModifier = false

    this.updateCursor()
  }

  /**
   * Store the provided coordinates and index for the active vertex
   */
  protected setActiveSelectedCoords = (coords?: Coordinate, index?: number) => {
    this.activeSelectedCoords = coords
    this.activeSelectedIndex = index
  }

  /**
   * During an editing session every time the Feature's geometry change we need to
   * refresh the parsed and stored information and validate the new Geometry.
   */
  protected refreshGeometry = (feature: Feature<Geometry>) => {
    // try to extract the new geometry's coordinates
    const coords = getFeatureCoordinates(feature)
    if (coords.length === 0) return

    const map = this.getMap()
    if (!map) return

    // ------------------------------------
    // Set or unset the selected vertex when a vertex is added or removed
    if (this.activeFeaturePrevGeometry?.getType() === 'LineString') {
      const prevCoordinates =
        (this.activeFeaturePrevGeometry as LineString).getCoordinates().at(0) ?? []

      // ------------------------------------
      // A point has been removed
      if (prevCoordinates.length > coords.length) {
        // remove the stored `activeIndex` custom field
        drawFeatureMetadata.removeActiveIndex(feature)
        // remove the stored vertex coordinates and geometry's index
        this.setActiveSelectedCoords(undefined, undefined)
      }

      // ------------------------------------
      // A point has been added
      if (prevCoordinates.length < coords.length) {
        // search for the vertex coordinates in the geometry
        const coordsIndex =
          coords.findIndex(([x, y], idx) => {
            return x !== prevCoordinates.at(idx) || y !== prevCoordinates.at(idx)
          }) ?? -1

        if (coordsIndex > -1) {
          // if the index is valid store it in the feature as a custom field,
          // it will be used by the Style Functions
          drawFeatureMetadata.setActiveIndex(feature, coordsIndex)
          // store the vertex coordinates and geometry's index
          this.setActiveSelectedCoords(coords.at(coordsIndex), coordsIndex)
        } else {
          // remove the stored `activeIndex` custom field
          drawFeatureMetadata.removeActiveIndex(feature)
          // remove the stored vertex coordinates and geometry's index
          this.setActiveSelectedCoords(undefined, undefined)
        }
      }
    } else {
      // remove the stored `activeIndex` custom field
      drawFeatureMetadata.removeActiveIndex(feature)
      // remove the stored vertex coordinates and geometry's index
      this.setActiveSelectedCoords(undefined, undefined)
    }

    // recompute the geometry's points in the RM LatLng coordinate system
    this.activeGeometryPoints = computePositions(map, coords)

    // validate the new geometry
    this.activeGeometryStatus = this.validateGeometry(this.activeGeometryPoints)

    // store the geometry validity in the feature as custom field.
    // it will be used by the Style Functions
    drawFeatureMetadata.setInvalid(this.activeFeature, this.activeGeometryStatus === 'invalid')

    this.updateCursor()
  }

  protected validateGeometry = (points: uui.domain.Point[]): GeometryStatus => {
    if (points.length !== 2) return 'invalid'

    return 'valid'
  }

  // ---------------------------------------------------------------
  // Active Map Cursor
  // ---------------------------------------------------------------

  protected updateCursor() {
    const view = this.getMap()?.getViewport()
    if (!view) return

    // always reset
    view.classList.remove('ol-pointer-draw-invalid')

    if (!this.getActive()) return

    if (this.activeGeometryStatus === 'invalid') {
      view.classList.add('ol-pointer-draw-invalid')
      return
    }
  }

  // ---------------------------------------------------------------
  // OVERRIDDEN API
  // ---------------------------------------------------------------

  /**
   * Clear all the internal subscription and pointers when the Interaction is disposed.
   */
  override dispose() {
    const view = this.getMap()?.getViewport()
    view?.classList.remove('ol-pointer-draw-valid')
    view?.classList.remove('ol-pointer-draw-invalid')
    view?.classList.remove('ol-pointer-draw-close-shape')

    this.un('change:active', this.handleChangeActiveState)

    this.onRoadSegmentChangeSubscribers.clear()

    this.resetInteraction()

    super.dispose()
  }
}
