import { applyAlphaToColor } from '@/local/components'
import { theme } from '@/styles'
import { styled } from '@mui/material'
import { cardGutter, cardTopSpace } from '../constants'
import type { CardCosmetic } from '../typings'

type Props = {
  cosmetic?: CardCosmetic
  roundedBorder?: 'left' | 'right' | 'none'
  firstOfRow?: boolean
  selectionStart?: boolean
  ghostSelectionStart?: boolean
  ghostSelectionEnd?: boolean
  selectionEnd?: boolean
  forceUnselected?: boolean
}

export const CardContainer = styled('span', { name: 'CardContainer' })<Props>(
  ({
    cosmetic,
    roundedBorder,
    firstOfRow,
    selectionStart,
    selectionEnd,
    ghostSelectionStart,
    forceUnselected,
  }) => ({
    display: 'block',
    position: 'relative',
    padding: 0,
    outline: 0,
    margin: 0,
    borderWidth: 0,
    borderTopLeftRadius: `${roundedBorder === 'left' ? 3 : 0}px`,
    borderTopRightRadius: `${roundedBorder === 'right' ? 3 : 0}px`,
    borderBottomLeftRadius: `${roundedBorder === 'left' ? 3 : 0}px`,
    borderBottomRightRadius: `${roundedBorder === 'right' ? 3 : 0}px`,
    paddingLeft: `${firstOfRow ? 0 : cardGutter}px`,
    marginTop: `${cardTopSpace}px`,
    zIndex: 2,
    background:
      (cosmetic === 'selected' || (selectionEnd && !selectionStart)) && !forceUnselected
        ? applyAlphaToColor(theme.colors.$scienceBlue, 0.1)
        : cosmetic === 'ghosted' && !ghostSelectionStart
          ? applyAlphaToColor(theme.colors.$scienceBlue, 0.1)
          : 'transparent',
  }),
)
