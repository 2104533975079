import { useMemo } from 'react'

import { colorPalette } from '@/server-data'

import { getLabel } from '../../../../utils/getLabel'

export function useRmVehicleData(
  unifiedVehicle: uui.domain.client.UnifiedVehicle,
): uui.domain.ui.forms.RouteManagerVehicleData {
  return useMemo(() => {
    return {
      name: getLabel(unifiedVehicle),
      color: {
        color: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.color : 'fff',
        // remove the readonly
        colors: [...colorPalette],
      },
      deviceId: unifiedVehicle.isPhysicalDevice ? unifiedVehicle.device.deviceId : undefined,
      eventTracking: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.tracked : false,
      liveEta: unifiedVehicle.hasRoutingLicense
        ? unifiedVehicle.vehicle.edgeOverride === 'GPS'
        : false,
      enableSsosr: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.enableSsosr : false,
    }
  }, [unifiedVehicle])
}
