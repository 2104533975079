import { colorPalette } from '@/server-data'
import { getLabel } from '../../../../../../../utils/getLabel'

export function computeRmData(unifiedVehicle: uui.domain.client.UnifiedVehicle) {
  return {
    name: getLabel(unifiedVehicle),
    color: {
      color: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.color : 'fff',
      // remove the readonly
      colors: [...colorPalette],
    },
    deviceId: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.gpsDeviceId : undefined,
    eventTracking: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.tracked : false,
    liveEta: unifiedVehicle.hasRoutingLicense
      ? unifiedVehicle.vehicle.edgeOverride === 'GPS'
      : false,
    enableSsosr: unifiedVehicle.hasRoutingLicense ? unifiedVehicle.vehicle.enableSsosr : false,
  }
}
