import { Avatar, Badge, Box, Typography, useTheme, badgeClasses } from '@mui/material'

import { isDark, isValidColor } from '@/styles'

export type Props = {
  text: string
  secondaryText?: string

  testId?: string

  color: string
  badgeColor?: string // no badge color means no badge at all
  textColor?: string // specific text color
  borderColor?: string // if set draws a border around the avatar

  // if true the color is used only in the top-right ribbon
  executed?: boolean

  // if true renders an orange line below the avatar
  violated?: boolean
}

const badgeAnchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right',
} as const

function createBadgeSx(color: string = 'transparent') {
  return {
    [`& .${badgeClasses.dot}`]: {
      width: 12,
      height: 12,
      borderRadius: '50%',
      border: '2px solid #fff',
      background: color,
    },
  }
}

export function ListItemAvatarSquared(props: Props) {
  const {
    text,
    secondaryText,
    executed,
    color,
    testId = 'ListItemAvatarSquared',
    badgeColor,
    violated,
  } = props

  const theme = useTheme()

  let bgcolor = executed ? '#D9D9D9' : color
  if (!isValidColor(bgcolor)) {
    console.warn(`Invalid color: ${bgcolor}`)
    bgcolor = '#fff'
  }

  const textColor = props.textColor
    ? props.textColor
    : isDark(bgcolor)
      ? theme.palette.primary.contrastText
      : theme.palette.text.primary

  const badgeInvisible = !badgeColor

  return (
    <Badge
      invisible={badgeInvisible}
      variant="dot"
      anchorOrigin={badgeAnchorOrigin}
      sx={createBadgeSx(badgeColor)}
    >
      <Avatar
        data-testid={testId}
        variant="rounded"
        sx={{
          position: 'relative',
          flexDirection: 'column',
          color: textColor,
          bgcolor,
          border: props.borderColor ? `1px solid ${props.borderColor}` : undefined,
        }}
      >
        {/* the 2px of spacing is to visually balance the text */}
        <Typography variant="avatar" sx={{ mt: '2px' }}>
          {text}
        </Typography>
        {!!secondaryText && <Typography variant="avatar">{secondaryText}</Typography>}
        {!!executed && <Ribbon color={color} />}
      </Avatar>
      {!!violated && <Violation />}
    </Badge>
  )
}

type RibbonProps = {
  color: string
}

const ribbonSx = {
  transformOrigin: 'top left',
  transform: 'rotate(-45deg)',
}

function Ribbon(props: RibbonProps) {
  const { color } = props

  return (
    <Box
      position="absolute"
      left="62.5%"
      top="0"
      width="100%"
      height="100%"
      bgcolor={color}
      sx={ribbonSx}
    />
  )
}

function Violation() {
  return (
    <Box
      position="absolute"
      bottom="-8px"
      zIndex="4"
      left="1px"
      right="1px"
      height="4px"
      borderRadius="2px"
      bgcolor={theme => theme.palette.warning.main}
    />
  )
}
