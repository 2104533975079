import { Add } from '@mui/icons-material'

import { ListHeaderActions, ListHeaderPrimaryActionButton } from '@/components/List'
import { ReadOnlyTooltip } from '@/components/smartUtils/plan/ReadOnlyTooltip'
import { useIsSimulation } from '@/hooks'
import { useIsLoading } from '@/atoms'

import { useCanAddNewVehicle } from '../../../../../hooks/useCanAddNewVehicle'
import { useTexts } from '../../../../hooks/useTexts'

import { useActions } from './hooks/useActions'

export function Actions() {
  const { editing, onCreate } = useActions()
  const isSimulation = useIsSimulation()
  const isLoading = useIsLoading('fetchGpsData')
  const texts = useTexts()

  const canAddNewVehicle = useCanAddNewVehicle(isLoading)

  return (
    <ReadOnlyTooltip
      forceState="enabled"
      placement="bottom"
      canEdit={canAddNewVehicle}
      title={
        editing
          ? texts.disabledTooltipTitle(isSimulation)
          : isLoading
            ? texts.disabledForLoadingTooltipTitle
            : texts.createNew
      }
      render={preventEditing => (
        <ListHeaderActions>
          <ListHeaderPrimaryActionButton
            disabled={preventEditing || editing || !canAddNewVehicle}
            onClick={onCreate}
            trackId="pendo-vehicle__create-new-button"
          >
            <Add />
          </ListHeaderPrimaryActionButton>
        </ListHeaderActions>
      )}
    />
  )
}
