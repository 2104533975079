import { FieldMode } from '../../../components/ViewDetails'
import { getViewDetails } from '../../../components/ViewDetails/utils'

interface PartialBulkViewData {
  description: string | FieldMode.mixed
  eligibility: string | FieldMode.mixed
  loads: Record<string, number[]>
  priority: string | FieldMode.mixed
  serviceTime: number | FieldMode.mixed
  tagsIn: string[][]
  tagsOut: string[][]
  timeWindows: uui.domain.rm.TimeWindow[][]
  vehicle: string | FieldMode.mixed
  violations: Set<uui.domain.rm.RouteStepViolationsType>
  phone: string | FieldMode.mixed
  email: string | FieldMode.mixed
  company: string | null | FieldMode.mixed
  notes: string | FieldMode.mixed
}

export function computePartialBulkViewData(
  orders: uui.domain.client.rm.ExtendedOrderStep[],
  vehiclesMap: Record<string, uui.domain.client.rm.Vehicle>,
  companiesMap: Record<string, uui.domain.client.rm.Company>,
  formatEligibility: (eligibility: uui.domain.client.rm.Eligibility) => string,
  archived: boolean,
) {
  return orders.reduce<PartialBulkViewData>(
    (
      mutableData: PartialBulkViewData,
      order: uui.domain.client.rm.ExtendedOrderStep,
      idx: number,
    ) => {
      const {
        vehicleText,
        description: descriptionText,
        eligibilityText,
        priorityText,
        serviceTime,
        email,
        phone,
        notes,
        company,
      } = getViewDetails(order, vehiclesMap, companiesMap, formatEligibility, archived)

      const {
        order: { loads = {} },
        orderStep: { tagsIn = [], tagsOut = [], timeWindows = [] },
      } = order

      const violations = order.isUnassigned ? [] : (order.violations ?? [])

      mutableData.tagsIn.push(tagsIn)
      mutableData.tagsOut.push(tagsOut)
      mutableData.timeWindows.push(timeWindows)

      for (const key in loads) {
        if (loads.hasOwnProperty(key)) {
          if (!mutableData.loads[key]) {
            mutableData.loads[key] = []
          }

          mutableData.loads[key].push(loads[key])
        }
      }

      for (const violation of violations) {
        mutableData.violations.add(violation)
      }

      return {
        ...mutableData,
        description:
          mutableData.description === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.description !== descriptionText
              ? FieldMode.mixed
              : descriptionText,

        eligibility:
          mutableData.eligibility === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.eligibility !== eligibilityText
              ? FieldMode.mixed
              : eligibilityText,

        priority:
          mutableData.priority === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.priority !== priorityText
              ? FieldMode.mixed
              : priorityText,

        serviceTime:
          mutableData.serviceTime === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.serviceTime !== serviceTime
              ? FieldMode.mixed
              : serviceTime,
        vehicle:
          mutableData.vehicle === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.vehicle !== vehicleText
              ? FieldMode.mixed
              : vehicleText,

        phone:
          mutableData.phone === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.phone !== phone
              ? FieldMode.mixed
              : phone,
        email:
          mutableData.email === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.email !== email
              ? FieldMode.mixed
              : email,
        company:
          mutableData.company === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.company !== company
              ? FieldMode.mixed
              : company,
        notes:
          mutableData.notes === FieldMode.mixed
            ? FieldMode.mixed
            : idx > 0 && mutableData.notes !== notes
              ? FieldMode.mixed
              : notes,
      }
    },
    {
      description: '',
      eligibility: '',
      loads: {},
      priority: '',
      serviceTime: -1,
      tagsIn: [],
      tagsOut: [],
      timeWindows: [],
      vehicle: '',
      violations: new Set(),
      phone: '',
      email: '',
      notes: '',
      company: '',
    },
  )
}
