import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { useForm } from 'react-final-form'

import {
  selectTelematicsDeviceTransientStatuses,
  selectTelematicsTenantSourcesAsArray,
  selectRemainingTelematicsLicenses,
  selectTelematicsTenantSources,
  selectTelematicsSources,
} from '@/features/domain/account'
import {
  getOpsDataFiltered,
  selectTerritories,
  selectTerritoryId,
} from '@/features/domain/territory'
import { selectDeviceIdsByTenantSource, selectTelematicsDevices } from '@/features/domain/device'
import { CheckboxField, DropDownField, type DropdownItem } from '@/forms-legacy'
import { selectVehicles, selectVehiclesByVehicleId } from '@/features/domain/vehicle'
import { selectUserConfiguration } from '@/features/domain/user'
import { naturalSort } from '@/server-data'
import { store } from '@/store'

import { useTexts } from '../../../hooks/useTexts'

import { NoRemainingLicensesAlert } from './components/NoRemainingLicensesAlert'
import { NoTenantSourcesAlert } from './components/NoTenantSourcesAlert'

interface Props {
  initialValues: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
  values: uui.domain.ui.forms.Rm360VehicleFormValuesWithTelematics
}

function getUpdatedDeviceOptions(
  tenantSourceId: string,
  deviceIdsByTenantSource: Record<string, string[]>,
  devices: Record<string, uui.domain.client.gps.telematics.DeviceInfo>,
  vehicles: Record<string, uui.domain.client.UnifiedVehicle>,
  territories: Record<string, uui.domain.client.rm.Territory>,
  vehiclesByVehicleId: Record<string, string>,
  currentTerritoryId: string,
  deviceTransientStatuses: Record<
    string,
    {
      status: 'PENDING-ACTIVATION' | 'PENDING-DEACTIVATION' | 'ERROR'
      vehicleId: string
      message: string
    }
  >,
  vehiclesByTerritory?: uui.domain.server.rm.TerritoriesDriversAndVehicles,
): DropdownItem[] {
  const ids = deviceIdsByTenantSource[tenantSourceId] ?? []

  return ids
    .map(id => {
      const device = devices[id]

      const transientStatus = deviceTransientStatuses[id]
      const vehicleId = transientStatus?.vehicleId ?? device.correlationHandle ?? ''
      const vehicle = vehicleId === '' ? undefined : vehicles[vehiclesByVehicleId[vehicleId]]

      // This prevents situation when a device is in a pending activation state
      const deviceWithPendingActivationStatus = transientStatus?.status === 'PENDING-ACTIVATION'
      const deviceWithPendingDectivationStatus = transientStatus?.status === 'PENDING-DEACTIVATION'

      const territoryId = deviceWithPendingActivationStatus
        ? currentTerritoryId
        : device.territoryId
          ? device.territoryId
          : undefined
      const territory = territoryId ? territories[territoryId] : undefined

      // This prevents situation when a devices got a correlationHandle but the vehicle has been updated and got no (or another) gps device
      const stillAssignedToVehicle =
        !vehicle || (vehicle?.hasRoutingLicense && vehicle.vehicle.gpsDeviceId === device.deviceId)

      // Device assigned in another territory
      const deviceAssignedInAnotherTerritory =
        device.active && currentTerritoryId !== device.territoryId

      const vehicleLabel = territoryId
        ? vehiclesByTerritory?.[territoryId]?.vehicles[vehicleId]
        : undefined
      const territoryLabel = territory ? territory.name : undefined

      const info =
        !deviceWithPendingDectivationStatus && vehicleLabel && territoryLabel
          ? `${vehicleLabel} - ${territoryLabel}`
          : undefined

      const disabled =
        deviceAssignedInAnotherTerritory ||
        (device.active && stillAssignedToVehicle) ||
        deviceWithPendingActivationStatus

      return {
        id: device.deviceId,
        info,
        label: device.deviceLabel ?? device.deviceId,
        disabled,
      }
    })
    .sort((a, b) => naturalSort(a.label, b.label))
}

async function fetchVehiclesByTerritory(
  setVehiclesByTerritory: (
    vehiclesByTerritory: uui.domain.server.rm.TerritoriesDriversAndVehicles | undefined,
  ) => void,
) {
  const result = await store.dispatch(getOpsDataFiltered())

  if (getOpsDataFiltered.rejected.match(result)) {
    throw new Error('Failed to fetch vehicles by territory')
  }

  setVehiclesByTerritory(result.payload)
}

export function TelematicsTracking(props: Props) {
  const { values, initialValues } = props

  const deviceTransientStatuses = useSelector(selectTelematicsDeviceTransientStatuses)
  const deviceIdsByTenantSource = useSelector(selectDeviceIdsByTenantSource)
  const vehiclesByVehicleId = useSelector(selectVehiclesByVehicleId)
  const tenantSourcesArray = useSelector(selectTelematicsTenantSourcesAsArray)
  const remainingLicenses = useSelector(selectRemainingTelematicsLicenses)
  const tenantSources = useSelector(selectTelematicsTenantSources)
  const territories = useSelector(selectTerritories)
  const territoryId = useSelector(selectTerritoryId)
  const userConfig = useSelector(selectUserConfiguration)

  const vehicles = useSelector(selectVehicles)
  const devices = useSelector(selectTelematicsDevices)
  const sources = useSelector(selectTelematicsSources)
  const texts = useTexts()
  const form = useForm()

  const [vehiclesByTerritory, setVehiclesByTerritory] = useState<
    uui.domain.server.rm.TerritoriesDriversAndVehicles | undefined
  >()

  useEffect(() => {
    fetchVehiclesByTerritory(setVehiclesByTerritory)
  }, [devices, setVehiclesByTerritory])

  const [deviceOptions, setDeviceOptions] = useState<DropdownItem[]>(
    values.telematics.tenantSourceId
      ? [
          {
            id: '',
            label: texts.selectGpsDevice,
            disabled: true,
          },
          ...getUpdatedDeviceOptions(
            values.telematics.tenantSourceId,
            deviceIdsByTenantSource,
            devices,
            vehicles,
            territories,
            vehiclesByVehicleId,
            territoryId,
            deviceTransientStatuses,
            vehiclesByTerritory,
          ),
        ]
      : [],
  )

  const tenantSourceOptions = useMemo(
    () => [
      {
        id: '',
        label: texts.selectAnIntegration,
        disabled: true,
      },
      ...tenantSourcesArray
        .map(ts => {
          const source = sources[ts.sourceId]

          const info = !ts.active
            ? texts.deleting
            : source.label === ts.label
              ? undefined
              : source.label

          return {
            id: ts.id,
            info,
            label: ts.label,
            disabled: !ts.active,
          }
        })
        .sort((a, b) => naturalSort(a.label, b.label)),
    ],
    [sources, tenantSourcesArray, texts],
  )

  useEffect(() => {
    setDeviceOptions(
      values.telematics.tenantSourceId
        ? [
            {
              id: '',
              label: texts.selectGpsDevice,
              disabled: true,
            },
            ...getUpdatedDeviceOptions(
              values.telematics.tenantSourceId,
              deviceIdsByTenantSource,
              devices,
              vehicles,
              territories,
              vehiclesByVehicleId,
              territoryId,
              deviceTransientStatuses,
              vehiclesByTerritory,
            ),
          ]
        : [],
    )
  }, [
    deviceIdsByTenantSource,
    deviceTransientStatuses,
    vehiclesByVehicleId,
    vehiclesByTerritory,
    setDeviceOptions,
    territories,
    territoryId,
    vehicles,
    devices,
    values,
    texts,
  ])

  useEffect(() => {
    if (remainingLicenses > 0 || initialValues.rm.deviceId) return
    form.change('telematics.gpsTracking', false)
  }, [form, initialValues, remainingLicenses])

  const simulation = userConfig.planType === 'simulation'
  const noTenantSources = tenantSourcesArray.length === 0
  const showTrackingDetails = values.telematics.gpsTracking && !noTenantSources && !simulation
  const showSingleIntegrationText = values.telematics.gpsTracking && tenantSourcesArray.length === 1
  const singleIntegrationInDeletion =
    values.telematics.gpsTracking &&
    tenantSourcesArray.length === 1 &&
    !tenantSourcesArray[0].active

  const tenantSource = tenantSources[values.telematics.tenantSourceId]
  const noRemainingLicenses = remainingLicenses === 0 && !initialValues.rm.deviceId
  const currentIntegrationInDeletion = tenantSource && !tenantSource.active

  return (
    <>
      {!simulation && (
        <CheckboxField
          name="telematics.gpsTracking"
          testid="telematics.gpsTracking"
          wrappedInputProps={{
            disabled: noTenantSources || noRemainingLicenses,
            label: texts.trackTheVehicle,
            id: 'telematics.gpsTracking',
          }}
        />
      )}

      {noRemainingLicenses && <NoRemainingLicensesAlert />}

      {noTenantSources && !simulation && <NoTenantSourcesAlert />}

      {showTrackingDetails && (
        <>
          <DropDownField
            testid="telematics.tenantSourceId"
            values={tenantSourceOptions}
            label={texts.integrations}
            name="telematics.tenantSourceId"
            wrappedInputProps={{
              rowClassName: 'o-dropdown__row--double-label',
            }}
          />
          {currentIntegrationInDeletion && (
            <Typography color="error" fontSize={13} marginY={1}>
              {texts.integrationDeletionInProgressChooseAnother}
            </Typography>
          )}
        </>
      )}

      {singleIntegrationInDeletion ? (
        <Typography color="primary" fontSize={13} marginY={1}>
          {texts.integrationDeletionInProgress(tenantSourcesArray[0].label)}
        </Typography>
      ) : (
        showSingleIntegrationText &&
        values.telematics.tenantSourceId !== '' && (
          <Typography fontSize={13} marginY={1}>
            {texts.onlyAvailableIntegrationInUse(tenantSource.label)}
          </Typography>
        )
      )}

      {deviceOptions.length > 0 ? (
        <DropDownField
          testid="rm.deviceId"
          values={deviceOptions}
          label={texts.gpsDevice}
          name="rm.deviceId"
          wrappedInputProps={{
            disabled: singleIntegrationInDeletion || currentIntegrationInDeletion,
            rowClassName: 'o-dropdown__row--double-label',
            footerSecondaryText: ` • ${texts.remainingLicenses(remainingLicenses)}`,
          }}
        />
      ) : null}
    </>
  )
}
