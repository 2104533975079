import type { Grid, SubGrid } from '@bryntum/schedulerpro'
import { schedulerColumnAtom } from '../../../atoms/columns'
import { schedulerInstance } from '../../../atoms/project/project'
import { MIN_AVAILABLE_SPACE_FOR_FIXED_REGIONS } from '../constants'

export function onSplitterExpandClick(event: { source: Grid; subGrid: SubGrid; domEvent: Event }):
  | boolean
  | void {
  if (!schedulerInstance.instance) return

  const { id, cols, final } = event.source.subGrids

  // If the columns are collapsed, expand them, they will be back to the last valid width automatically
  if (cols.collapsed) {
    return true
  }
  if (cols.width === 0) {
    cols.width = schedulerColumnAtom.lastWidthBeforeCollapseOrExpand
    return false
  }

  if (typeof event.source.width !== 'number') return

  const schedulerWidth =
    schedulerInstance.instance.element.querySelector('.b-grid-body-container')?.clientWidth ??
    (schedulerInstance.instance.width as number)

  // If the columns are already expanded, do not allow expanding them further
  if (schedulerWidth - cols.width <= MIN_AVAILABLE_SPACE_FOR_FIXED_REGIONS) {
    return false
  }

  // If the columns are not collapsed, store the current width as the last valid width
  schedulerColumnAtom.lastWidthBeforeCollapseOrExpand = cols.width

  // Calculate the maximum possible width for the columns and set it
  const maxPossibleWidth = event.source.width - id.width - final.width
  cols.width = maxPossibleWidth
}
